import {FlexCard, FlexCardContent} from 'src/components/global/FlexStack'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import {Divider, Stack, Typography} from '@mui/material'
import Markdown from 'src/components/minimal/markdown'
import UploadHeader from 'src/components/project/upload/UploadHeader'

export default observer(function UploadCard() {
  const {
    projectStore: {selectedProject, docFolders}
  } = useMainStore()

  const onlyLinks = !docFolders?.children?.length

  return (
    <FlexCard sx={onlyLinks ? {width: '100%'} : {}}>
      <FlexCardContent>
        <Stack p={3} direction={'row'} justifyContent={'space-between'}>
          <Stack>
            <Typography variant="h6" sx={{color: 'text.disabled', pb: 1}}>
              Link:
            </Typography>
            <Typography variant="body2" sx={{wordWrap: 'break-word'}}>
              {selectedProject?.siteLink || ''}
            </Typography>
            <Divider sx={{borderStyle: 'dashed', my: 4}} />
            {selectedProject?.siteLinkComment && (
              <>
                <Typography variant="h6" sx={{color: 'text.disabled', pb: 1}}>
                  Comment:
                </Typography>
                <Markdown children={selectedProject.siteLinkComment} />
              </>
            )}
          </Stack>
          {onlyLinks && <UploadHeader />}
        </Stack>
      </FlexCardContent>
    </FlexCard>
  )
})
