import {makeAutoObservable, reaction} from 'mobx'

import config from 'src/config'
import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr'
import SignaRlMaliciousFileMessage from 'src/entities/SignalRMessage'
import LoginStore from './LoginStore'
import ProjectStore from './ProjectStore'

export default class SignalrStore {
  signalrConnection: HubConnection | null = null
  maliciousFile = ''

  constructor(
    readonly loginStore: LoginStore,
    readonly projectStore: ProjectStore
  ) {
    this.maliciousFile = ''

    makeAutoObservable(this)

    reaction(
      () => this.loginStore.isAuth,
      async isAuth => {
        if (isAuth) {
          this.signalrConnect()
        } else {
          this.signalrDisconnect()
        }
      }
    )
  }

  signalrConnect = async () => {
    const connectionInfo = await this.getSignalRConnectionInfo()
    if (connectionInfo?.url) {
      this.signalrConnection = new HubConnectionBuilder()
        .withUrl(connectionInfo.url, {
          accessTokenFactory: () => connectionInfo.accessToken
        })
        .withAutomaticReconnect()
        .build()
      // this.signalrConnection = new HubConnectionBuilder()
      // .withUrl(`${config.apiSignalRUrl}/cp_messagehub`, {
      //   withCredentials: false
      // }).withAutomaticReconnect()
      // .build()

      this.signalrConnection.start().catch()
      this.signalrConnection?.on('maliciousFile', async (result: string) => {
        const message = JSON.parse(result) as SignaRlMaliciousFileMessage
        const directories = message.fileName.split('/')
        const filePath = directories
          .slice(!message.isResponsefile && directories.length > 2 ? 2 : 0)
          .join('/')
        this.projectStore.onGetMaliciousFile(filePath, message.isResponsefile)
        this.setMaliciousFile(filePath)
      })
    }
  }

  signalrDisconnect = async () => {
    this.signalrConnection?.stop().catch()
  }

  getSignalRConnectionInfo = async () => {
    try {
      const response = await this.loginStore.fetchWithUser(
        `${config.apiSignalRUrl}/cp_messagehub/negotiate`
      )
      return response as SignalRConnectionInfo
    } catch {
      return {} as SignalRConnectionInfo
    }
  }

  setMaliciousFile = (value: string) => {
    this.maliciousFile = value
  }
}

interface SignalRConnectionInfo {
  url: string
  accessToken: string
}
