import {Divider, Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import Markdown from 'src/components/minimal/markdown'
import Instruction from 'src/entities/project/Instruction'
import {getFormattedDateTime} from '../../../utils/formatTime'

interface InstructionItemProps {
  item: Instruction
}

export default observer(function InstructionItem({item}: InstructionItemProps) {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={4}
        alignItems="center"
        py={1}
      >
        <Stack maxWidth={'80%'}>
          <Typography variant="subtitle2">{item.subject}</Typography>
          <Markdown
            children={item.content || ''}
            sx={{color: theme => theme.palette.text.secondary}}
          />
        </Stack>
        <Typography variant="caption">
          {getFormattedDateTime(item.createdAt)}
        </Typography>
      </Stack>
      <Divider />
    </>
  )
})
