import {format, getTime, formatDistanceToNow} from 'date-fns'

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy'

  return date ? format(new Date(date), fm) : ''
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p'

  return date ? format(new Date(date), fm) : ''
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : ''
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true
      })
    : ''
}

export function getFormattedDate(date: InputValue) {
  if (!date) return ''
  const d = new Date(date)
  if (isNaN(d.getTime())) return ''

  const formatter = new Intl.DateTimeFormat('us', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  })

  return formatter.format(d)
}

export function getFormattedDateTimeRL(
  date: InputValue,
  includeTimeZone?: boolean
) {
  if (!date) return ''
  const d = new Date(date)
  if (isNaN(d.getTime())) return ''

  const formatter = new Intl.DateTimeFormat('us', {
    day: '2-digit',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZoneName: includeTimeZone ? 'shortGeneric' : undefined
  })

  return formatter.format(d)
}

export function getLocalDateFromUtc(date: Date | string) {
  return typeof date === 'string' && ['Z', 'z'].some(ch => date.endsWith(ch))
    ? new Date(date)
    : new Date(date + 'z')
}

export function getFormattedDateTime(date: InputValue) {
  if (!date) return ''
  const d = new Date(date)
  if (isNaN(d.getTime())) return ''

  const formatter = new Intl.DateTimeFormat('us', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  })

  return formatter.format(d)
}
