import {useCallback, useEffect, useRef, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {IconButton, Stack, Typography, CircularProgress} from '@mui/material'
import {TreeItem} from '@mui/x-tree-view/TreeItem'
import {styled} from '@mui/material/styles'
import FileThumbnail from 'src/components/minimal/file-thumbnail'
import Label from 'src/components/minimal/label/Label'
import {ItemFileNode} from 'src/entities/ItemFileNode'
import {useMainStore} from 'src/contexts/Main'
import {ReactComponent as DownloadIcon} from 'src/images/ic_download.svg'

const StyledTreeItem = styled(TreeItem)(({theme}) => ({
  '&.MuiTreeItem-label': {
    width: 'auto'
  }
}))

interface RenderTreeProps {
  node: ItemFileNode
  parendDisable: boolean
  jumpTo?: number
  setSelected: React.Dispatch<React.SetStateAction<string>>
}

export default observer(function RenderTree({
  node,
  parendDisable,
  jumpTo,
  setSelected
}: RenderTreeProps) {
  const {
    getFileBlob,
    setIsDocLoading,
    setCurrentDocBlob,
    setCurrentJump,
    downloadFile
  } = useMainStore().itemFilesStore
  const [isDownloading, setIsDownloading] = useState(false)

  const isParent = (node.children?.length ?? 0) > 0

  const ref = useRef<HTMLLIElement>(null)

  const jumpToFile = useCallback(async () => {
    ;(async () => {
      if (!node.disabled)
        if (!isParent) {
          setIsDocLoading(true)
          await getFileBlob(node)
          setCurrentJump(undefined)
        } else if (isParent) setCurrentDocBlob('', undefined)
    })()
  }, [
    isParent,
    getFileBlob,
    setCurrentDocBlob,
    node,
    setIsDocLoading,
    setCurrentJump
  ])

  useEffect(() => {
    if (jumpTo === node.id && ref.current) {
      setSelected(node.id.toString())
      jumpToFile()

      setTimeout(() => {
        if (ref.current)
          ref.current.scrollIntoView({block: 'end', behavior: 'smooth'})
      }, 500)
    }
  }, [jumpTo, node.id, setSelected, jumpToFile])

  const onDownload = (path: string) => {
    setIsDownloading(true)
    downloadFile(path).finally(() => setIsDownloading(false))
  }

  return (
    <Stack direction={'row'} alignItems={'center'} gap={1}>
      <StyledTreeItem
        ref={ref}
        onClick={async () => {
          if (!node.disabled && !parendDisable) {
            node.setIsNodeExpanded(!node.isNodeExpanded)
            await jumpToFile()
          }
        }}
        key={node.id}
        disabled={node.disabled}
        nodeId={node.id.toString()}
        label={
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={1}
            justifyContent={'space-between'}
          >
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              {/* <Checkbox
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                node.toggleChecked()
              }}
              disabled={node.disabled}
              checked={
                node.disabled
                  ? false
                  : isParent
                  ? node?.isAllChecked
                  : node.isChecked
              }
              indeterminate={
                isParent && !node.isAllChecked && node.isAnyChecked
              }
            /> */}
              <FileThumbnail
                sx={{width: 16, ml: !isParent ? 1 : 0}}
                file={isParent ? 'folder' : node.name}
              />
              <Typography variant="body2">{node.name}</Typography>
            </Stack>
            {node.disabled && isParent && (
              <Label variant="soft" color="warning">
                Under Review
              </Label>
            )}
          </Stack>
        }
      >
        {Array.isArray(node.children)
          ? node.children
              .filter(x => !x.isDocx(x.name))
              .map((childNode: ItemFileNode) => (
                <RenderTree
                  key={childNode.id}
                  node={childNode}
                  parendDisable={node.disabled}
                  jumpTo={jumpTo}
                  setSelected={setSelected}
                />
              ))
          : null}
      </StyledTreeItem>
      {!isParent && (
        <IconButton
          onClick={() => onDownload(node.path)}
          disabled={parendDisable}
        >
          {isDownloading ? <CircularProgress size={16} /> : <DownloadIcon />}
        </IconButton>
      )}
    </Stack>
  )
})
