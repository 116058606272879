import {action, computed, makeObservable, observable} from 'mobx'
import Attachment from 'src/entities/Attachment'
import BaseModel from 'src/entities/BaseModel'
import Project from 'src/entities/project/Project'

export enum Status {
  openBalance = 0,
  Paid,
  'Open Balance' = openBalance
}

export default class Billing extends BaseModel {
  invoiceNumber?: string

  projectId?: number

  project?: Project

  propertyName?: string

  invoiceDate?: Date

  status: Status

  paidDate?: Date

  amount?: number

  paidAmount?: number

  openBalanceAmount?: number

  attachments: Attachment[]

  isDownloading = false

  isSelected = false

  constructor(billing?: Billing) {
    super(billing)
    this.invoiceNumber = billing?.invoiceNumber
    this.projectId = billing?.projectId
    this.project = new Project(billing?.project)
    this.propertyName = billing?.propertyName ?? this.project?.name ?? ''
    this.invoiceDate = billing?.invoiceDate
      ? new Date(billing.invoiceDate)
      : undefined
    this.status = billing?.status ?? Status.openBalance
    this.paidDate = billing?.paidDate ? new Date(billing.paidDate) : undefined
    this.amount = billing?.amount
    this.paidAmount = billing?.paidAmount
    this.openBalanceAmount = billing?.openBalanceAmount
    this.attachments = billing?.attachments?.map(a => new Attachment(a)) ?? []

    makeObservable(this, {
      isDownloading: observable,
      isSelected: observable,
      setIsDownloading: action,
      setIsSelected: action,
      projectName: computed
    })
  }

  get projectName() {
    return this.project?.name
  }

  setIsDownloading(value: boolean) {
    this.isDownloading = value
  }

  setIsSelected(value: boolean) {
    this.isSelected = value
  }

  get colorByStatus() {
    return this.status === Status.openBalance
      ? 'error'
      : this.status === Status.Paid
      ? 'success'
      : 'default'
  }
}
