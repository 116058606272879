import {observer} from 'mobx-react-lite'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import {TableHeadCustom} from 'src/components/minimal/table'
import Paper from '@mui/material/Paper'
import {Breakpoint, mergeRowsByNote} from 'src/entities/AbstractReport'

interface AbstractBreakpointProps {
  breakpoint: Breakpoint
}

export default observer(function AbstractBreakpoint({
  breakpoint
}: AbstractBreakpointProps) {
  const TABLE_HEAD = [
    {id: 'increaseDate', label: 'Increase Date', align: 'left'},
    {id: 'annual', label: 'Breakpoint', align: 'left'},
    {id: 'monthly', label: 'Percent', align: 'left'}
  ]

  const mergedData = mergeRowsByNote(breakpoint.details)

  return (
    <TableContainer component={Paper} sx={{boxShadow: 3}}>
      <Table size="small">
        <TableHeadCustom headLabel={TABLE_HEAD} />
        <TableBody>
          {breakpoint.details.map((d, i) => (
            <TableRow>
              <TableCell>{d.increaseDate}</TableCell>
              {d.note ? (
                <TableCell colSpan={4} rowSpan={mergedData[i]?.rowSpan}>
                  {!mergedData[i].spanNote && d.note}
                </TableCell>
              ) : (
                <>
                  <TableCell>{d.breakpoint}</TableCell>
                  <TableCell>{d.percent}</TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
})
