import {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {TableBody, TableCell, TableRow} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import DeliverableTable from 'src/components/project/deliverable/DeliverableTable'
import MCRow from 'src/components/project/deliverable/MCRow'
import {
  TableHeadCustom,
  getComparator,
  useTable
} from 'src/components/minimal/table'
import applyFilter from 'src/utils/applyFilter'

const TABLE_HEAD = [
  {id: 'lpid', label: 'LPID', align: 'left', minWidth: 120},
  {id: 'propertyName', label: 'Property Name', align: 'left', minWidth: 120},
  {id: 'tenant', label: 'File Name', align: 'left', minWidth: 130},
  {id: 'createdAt', label: 'Date Added', align: 'left', minWidth: 130},
  {id: 'issue', label: 'Missing & Conflicting Issue', align: 'left'},
  {id: 'status', label: 'Status', align: 'left'},
  {id: 'reply', label: '', align: 'left'},
  {id: 'response', label: ' ', align: 'left'}
]

const MCTable = observer(function MCTable() {
  const {projectStore} = useMainStore()
  const [searchText, setSearchText] = useState('')

  const {order, orderBy, onSort} = useTable({
    defaultOrderBy: 'none'
  })

  const mcItems = searchText.length
    ? projectStore.MCItems?.filter(
        mc =>
          mc.lpid?.toLowerCase().includes(searchText) ||
          mc.tenant?.toLowerCase().includes(searchText) ||
          mc.propertyName?.toLowerCase().includes(searchText) ||
          mc.mcIssueText.toLowerCase().includes(searchText)
      )
    : projectStore.MCItems

  const dataFiltered = mcItems
    ? applyFilter({
        inputData: mcItems,
        comparator: getComparator(order, orderBy)
      })
    : []

  return (
    <DeliverableTable
      title="Missing and Conflicting"
      actions={true}
      onSearch={setSearchText}
      onDownloadMC={
        dataFiltered.length > 0
          ? () => projectStore.generateMCReport(dataFiltered)
          : undefined
      }
    >
      <TableHeadCustom
        order={order}
        orderBy={orderBy}
        headLabel={TABLE_HEAD}
        rowCount={projectStore.MCItems?.length ?? 0}
        onSort={onSort}
      />
      <TableBody>
        {dataFiltered?.map((i, index) => (
          <MCRow mc={i} key={index} />
        ))}
        <TableRow>
          <TableCell colSpan={8} sx={{textAlign: 'end', border: 'none'}}>
            {`Total: ${projectStore.MCItemsCount}`}
          </TableCell>
        </TableRow>
      </TableBody>
    </DeliverableTable>
  )
})

export default MCTable
