import {useState} from 'react'
import {runInAction} from 'mobx'
import {observer} from 'mobx-react-lite'
import {TableBody, TableCell, TableRow} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import DeliverableTable from 'src/components/project/deliverable/DeliverableTable'
import DeliveredRow from 'src/components/project/deliverable/DeliveredRow'
import {
  TableHeadCustom,
  getComparator,
  useTable
} from 'src/components/minimal/table'
import applyFilter from 'src/utils/applyFilter'
import Item from 'src/entities/Item'

const TABLE_HEAD = [
  {id: 'lpid', label: 'LPID', align: 'left', minWidth: 120},
  {id: 'propertyName', label: 'Property Name', align: 'left', minWidth: 120},
  {id: 'tenant', label: 'File Name', align: 'left', minWidth: 130},
  {id: 'onHold', label: '', align: 'left', with: '10%'},
  {id: 'createdAt', label: 'LPRD Received', align: 'left', minWidth: 160},
  {id: 'dateSentToClient', label: 'Delivered', align: 'left', minWidth: 130},
  {id: 'actions', label: ' ', align: 'left'}
]

const DeliveredTable = observer(function DeliveredTable() {
  const {projectStore} = useMainStore()
  const [searchText, setSearchText] = useState('')

  const onDownload = async () => {
    for (let i = 0; i < dataFiltered.length; i++) {
      const item = dataFiltered[i] as Item
      if (item.id && item.isSelected) {
        await projectStore.generateAbstractReport(item.id)
      }
    }
  }

  const onDownloadZip = async () => {
    await projectStore.generateAbstractReportZip(
      dataFiltered.filter(x => x.isSelected)?.map(x => x.id || 0)
    )
  }

  const onToggleAll = (items: Item[], isChecked: boolean) => {
    runInAction(() => {
      items.forEach(item => {
        if (!item.isInUpdating) {
          item.isSelected = isChecked
        }
      })
    })
  }

  const {order, orderBy, onSort} = useTable({
    defaultOrderBy: 'none'
  })

  const completedItems = searchText.length
    ? projectStore.completedItems?.filter(
        i =>
          i.lpid?.toLowerCase().includes(searchText) ||
          i.propertyName?.toLowerCase().includes(searchText) ||
          i.tenant?.toLowerCase().includes(searchText)
      )
    : projectStore.completedItems

  const dataFiltered: Item[] = completedItems
    ? applyFilter({
        inputData: completedItems,
        comparator: getComparator(order, orderBy)
      })
    : []

  const disabledSelectCell = dataFiltered.every(i => i.isInUpdating === true)
  const selectableData = projectStore.completedItems?.filter(
    r => !r.isInUpdating
  )

  return (
    <DeliverableTable
      title="Completed"
      isDownloadAllDisable={!dataFiltered.find(i => i.isSelected === true)}
      onDownloadAll={onDownload}
      onDownloadZip={onDownloadZip}
      onSearch={setSearchText}
    >
      <>
        <TableHeadCustom
          addSelectCell
          disabledSelectCell={disabledSelectCell}
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEAD}
          rowCount={selectableData?.length ?? 0}
          onSort={onSort}
          numSelected={
            dataFiltered.filter((i: Item) => i.isSelected === true)?.length ?? 0
          }
          onSelectAllRows={checked => onToggleAll(dataFiltered, checked)}
        />

        <TableBody>
          {dataFiltered?.map((i: Item) => (
            <DeliveredRow key={i.id} item={i} />
          ))}
          <TableRow key="footer">
            <TableCell
              colSpan={TABLE_HEAD.length + 1}
              sx={{textAlign: 'end', border: 'none'}}
            >
              {`Total: ${projectStore.deliveredCount}`}
            </TableCell>
          </TableRow>
        </TableBody>
      </>
    </DeliverableTable>
  )
})

export default DeliveredTable
