import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import UploadCard from './UploadCard'
import UploadHeader from './UploadHeader'
import EmptyContent from 'src/components/minimal/empty-content/EmptyContent'
import EmptyIcon from 'src/images/illustration_empty_mail.svg'
import {useCallback, useEffect, useState} from 'react'
import LinkCard from './LinkCard'
import {Stack} from '@mui/material'
import UploadDialog from './UploadDialog'

const UploadTab = observer(function UploadTab() {
  const {
    projectStore: {
      getClientFiles,
      currentdocFolder,
      selectedProject,
      docFolders,
      openUploadDialigFile,
      setOpenUploadDialigFile
    }
  } = useMainStore()

  const [loading, setLoading] = useState(true)

  const loadFiles = useCallback(async () => {
    if (selectedProject?.id) {
      setLoading(true)
      await getClientFiles()
      setLoading(false)
    }
  }, [getClientFiles, selectedProject?.id])

  useEffect(() => {
    loadFiles()
  }, [loadFiles])

  return (
    <>
      {loading ||
      (!loading &&
        !docFolders?.children?.length &&
        !selectedProject?.siteLink &&
        !selectedProject?.siteLinkComment) ? (
        <>
          {!loading && <UploadHeader />}
          <EmptyContent
            title={(loading ? 'Loading ' : 'No') + ' Files'}
            img={EmptyIcon}
          />
        </>
      ) : (
        <Stack direction={'row'} display="flex" flexGrow={1} spacing={2}>
          {!!docFolders?.children?.length && <UploadCard />}
          {(!!selectedProject?.siteLink ||
            !!selectedProject?.siteLinkComment) && (
            <Stack width={!docFolders?.children?.length ? '100%' : '28%'}>
              <LinkCard />
            </Stack>
          )}
        </Stack>
      )}
      {openUploadDialigFile && (
        <UploadDialog
          open={openUploadDialigFile}
          onClose={() => {
            setOpenUploadDialigFile(false)
          }}
          curretFolder={currentdocFolder}
        />
      )}
    </>
  )
})

export default UploadTab
