import {useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import {TreeView} from '@mui/x-tree-view/TreeView'
import {ItemFileNode} from 'src/entities/ItemFileNode'
import {useMainStore} from 'src/contexts/Main'
import {ParentScrollStack} from 'src/components/global/FlexStack'
import Scrollbar from 'src/components/minimal/scrollbar'
import RenderTree from 'src/components/itemsFiles/RenderTree'

export default observer(function FilesTree() {
  const {filesTree, expanedAll, currentJump, setCurrentJump} =
    useMainStore().itemFilesStore
  const [expanded, setExpanded] = useState<string[]>([])
  const [selected, setSelected] = useState<string>('')

  const getAllNodeIds = useCallback((data: ItemFileNode[]) => {
    return data.reduce((ids: string[], node) => {
      node.setIsNodeExpanded(true)
      ids.push(node.id.toString())
      if (node.children) {
        ids = [...ids, ...getAllNodeIds(node.children)]
      }
      return ids
    }, [])
  }, [])

  const toggleExpanded = useCallback(
    (value: boolean) => {
      if (value) {
        const allNodeIds = getAllNodeIds(filesTree)
        setExpanded(allNodeIds)
      } else setExpanded([])
    },
    [filesTree, getAllNodeIds]
  )

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string) => {
    setSelected(nodeIds)
  }

  useEffect(() => {
    if (currentJump) {
      const numbers: string[] = Array.from(
        {length: currentJump + 1},
        (_, index) => index.toString()
      )
      setExpanded([...numbers, ...expanded])
    }
  }, [currentJump, expanded, setCurrentJump])

  useEffect(() => {
    toggleExpanded(expanedAll)
  }, [expanedAll, toggleExpanded])

  return (
    <ParentScrollStack display={'flex'}>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ExpandLessIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={(event, nodeIds) => {
          setExpanded(nodeIds)
        }}
        onNodeSelect={handleSelect}
        sx={{
          flexGrow: 1,
          maxHeight: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          overflow: 'auto'
        }}
      >
        <Scrollbar sx={{display: 'flex'}}>
          {filesTree
            .filter(x => !x.isDocx(x.name))
            .map((node: ItemFileNode) => (
              <RenderTree
                key={node.path}
                node={node}
                parendDisable={false}
                jumpTo={currentJump}
                setSelected={setSelected}
              />
            ))}
        </Scrollbar>
      </TreeView>
    </ParentScrollStack>
  )
})
