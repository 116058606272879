import {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
// @mui
import {Collapse, Divider} from '@mui/material'
// hooks
import NavItem from './NavItem'
import {NavListProps} from './types'
import useActiveLink from 'src/hooks/useActiveLink'

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps
  depth: number
  hasChild: boolean
}

export default function NavList({data, depth, hasChild}: NavListRootProps) {
  const {pathname} = useLocation()

  const {active, isExternalLink} = useActiveLink(data.path)

  const [open, setOpen] = useState(active)
  const [isSubActive, setIsSubActive] = useState(false)

  useEffect(() => {
    if (!active) {
      const subActive = pathname
        .toLowerCase()
        .startsWith(`/${data.title.toLowerCase()}`)
      setIsSubActive(subActive)
      if (!subActive) handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active || (open && isSubActive)}
        isExternalLink={isExternalLink}
        onClick={handleToggle}
      />

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList data={data.children} depth={depth} />
        </Collapse>
      )}

      {depth === 1 && <Divider />}
    </>
  )
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[]
  depth: number
}

function NavSubList({data, depth}: NavListSubProps) {
  return (
    <>
      {data.map(list => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
        />
      ))}
    </>
  )
}
