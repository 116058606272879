import {useEffect, useRef, useState} from 'react'
import WebViewer, {WebViewerInstance} from '@pdftron/webviewer'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import {Box, CircularProgress, Typography} from '@mui/material'
import config from 'src/config'

interface FileDocViewerProps {
  onDocumentLoaded?: () => void
}

export default observer(function FileDocView({
  onDocumentLoaded
}: FileDocViewerProps) {
  const {isDocLoading, currentDoc, jumpToAnnotation, setIsDocLoading} =
    useMainStore().itemFilesStore

  const [webViewer, setWebViewer] = useState<WebViewerInstance | undefined>(
    undefined
  )

  const [annonationLoading, setAannonationLoading] = useState<boolean>(true)
  const [afterJump, setAfterJump] = useState<boolean>(false)

  const viewerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!webViewer || !jumpToAnnotation?.length || annonationLoading) return

    const {annotationManager, documentViewer} = webViewer.Core
    const annotList = annotationManager.getAnnotationsList()
    const annot = annotList.find(a =>
      jumpToAnnotation.some(x => x === a.getCustomData('annotationId'))
    )
    const scroll = documentViewer.getScrollViewElement()
    const scrollLeft = scroll.scrollLeft
    if (annot) {
      documentViewer.displayPageLocation(annot.PageNumber, 0, annot.Y)
      scroll.scrollTo({left: scrollLeft})
    }
    setAfterJump(true)
  }, [jumpToAnnotation, webViewer, annonationLoading])

  useEffect(() => {
    if (!webViewer || !currentDoc) return
    if (currentDoc.blob) {
      webViewer.UI.loadDocument(currentDoc.blob, {
        filename: currentDoc.fileName
      })
      setIsDocLoading(false)
    }
  }, [webViewer, currentDoc, setIsDocLoading])

  useEffect(() => {
    if (!viewerRef.current) return
    WebViewer(
      {
        path: '/webviewer/src',
        licenseKey: config.viewerLicenseKey,
        loadAsPDF: false,
        enableAzureWorkaround: true,
        streaming: false,
        disabledElements: [
          'ribbons',
          'toolsHeader',
          'toggleNotesButton',
          'textPopup'
        ],
        fullAPI: true,
        css: '/webviewer/webviewer.css'
      },
      viewerRef.current
    ).then(instance => {
      instance.UI.iframeWindow.addEventListener('loaderror', function (error) {
        const details = (error as CustomEvent).detail
        const statusCode = details.serverResponse?.status
        instance.UI.displayErrorMessage(
          details === 'License Key Error: no office addon'
            ? 'Preview available only for PDF and Word. \nTo preview other formats, download the file.'
            : statusCode === 404
            ? 'File not found'
            : 'Error occured'
        )
      })

      instance.UI.addEventListener(instance.UI.Events.DOCUMENT_LOADED, () => {
        instance.UI.setFitMode(instance.UI.FitMode.FitWidth)
        onDocumentLoaded?.()
        instance.Core.documentViewer.addEventListener(
          'annotationsLoaded',
          onloadEvent
        )
      })
      const onloadEvent = () => {
        setAannonationLoading(false)
        instance.Core.documentViewer.removeEventListener(
          'annotationsLoaded',
          onloadEvent
        )
      }

      setWebViewer(instance)
    })
  }, [onDocumentLoaded])

  return (
    <>
      {(isDocLoading || !afterJump) && (
        <CircularProgress
          size={60}
          sx={{position: 'absolute', marginX: '60vw', marginY: '30vh'}}
        />
      )}

      <Box flexGrow={1}>
        {!currentDoc && !isDocLoading && (
          <Typography
            sx={{width: '100%', textAlign: 'center', mt: '25%'}}
            variant="h5"
          >
            Select a file to preview
          </Typography>
        )}
        <div
          ref={viewerRef}
          style={{
            height: '100%',
            display: !currentDoc?.blob || !afterJump ? 'none' : 'block'
          }}
        />
      </Box>
    </>
  )
})
