import React, {useEffect, useRef, useState} from 'react'
import {Box, Breadcrumbs, CircularProgress, Typography} from '@mui/material'
import WebViewer, {Core as Core1, WebViewerInstance} from '@pdftron/webviewer'
import {Link} from 'react-router-dom'
import {ReactComponent as DotIcon} from 'src/images/dot.svg'
import {useMainStore} from 'src/contexts/Main'
import {observer} from 'mobx-react-lite'
interface CompareHighlight {
  annot: Core1.Annotations.TextHighlightAnnotation
  text: string
}

export default observer(function ItemAbstractMAppingPage() {
  const {currentScope} = useMainStore().reportStore

  const viewer = useRef(null)
  const [viewerInstance, setViewerInstance] =
    useState<WebViewerInstance | null>(null)
  const [annonteList, setAnnonteList] = useState<CompareHighlight[]>([])
  const [loading, setLoading] = useState(true)

  // useEffect(() => {
  //   scopeStore.initScope()
  // }, [scopeStore])

  useEffect(() => {
    if (!viewer.current) return
    WebViewer(
      {
        path: '/webviewer/src',
        disabledElements: [
          'ribbons,toolsHeader,textPopup,annotationPopup, toggleNotesButton'
        ],
        licenseKey: process.env.REACT_APP_VIEWER_LICENSE_KEY || '',
        fullAPI: true,
        disableMultiViewerComparison: true
        //  css: '/webviewer/MappingWebviewer.css'
      },
      viewer.current
    ).then(instance => {
      const {UI, Core} = instance
      const {PDFNet} = Core
      const doc2Pages = [] as {
        page: Core1.PDFNet.Page
        pageBox: Core1.PDFNet.Rect
        originalHeight: number
        originalwidth: number
      }[]

      UI.enableFeatures([UI.Feature.MultiViewerMode])

      UI.addEventListener(UI.Events.MULTI_VIEWER_READY, () => {
        const [documentViewer1, documentViewer2] =
          instance.Core.getDocumentViewers()

        documentViewer1.loadDocument(
          '/files/CRD-WP-001 - Hungry Pot Westport.pdf'
        )
        documentViewer2.loadDocument('/files/lease.pdf', {
          loadAnnotations: true
        })

        documentViewer1.addEventListener(
          instance.UI.Events.DOCUMENT_LOADED,
          () => {
            documentViewer1.setFitMode(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore :Fix pdfTron TS documentation bug
              instance.Core.documentViewer.FitMode.FitWidth
            )
          },
          {once: true}
        )

        documentViewer2.addEventListener(
          instance.UI.Events.DOCUMENT_LOADED,
          () => {
            documentViewer2.addEventListener('annotationsLoaded', onloadEvent, {
              once: true
            })
            documentViewer2.setFitMode(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore :Fix pdfTron TS documentation bug
              instance.Core.documentViewer.FitMode.FitWidth
            )
          },
          {once: true}
        )
        const onloadEvent = async () => {
          const doc2 = await documentViewer2.getDocument().getPDFDoc()
          const getPageDetails = async (pageNumber: number) => {
            if (!doc2Pages[pageNumber]) {
              const p = await doc2.getPage(pageNumber)
              doc2Pages[pageNumber] = {
                page: p,
                pageBox: await p.getCropBox(),
                originalHeight: await p.getPageHeight(),
                originalwidth: await p.getPageWidth()
              }
            }
            return doc2Pages[pageNumber]
          }

          const getHilighText = async (
            annot1: Core1.Annotations.TextHighlightAnnotation
          ) => {
            const result = [] as string[]
            await Promise.all(
              annot1.Quads.map(async (x, index) => {
                const p = await getPageDetails(annot1.PageNumber)
                const srchStr = await getStringbyPositiion(p.page, p.pageBox, x)
                result[index] = srchStr
              })
            )
            annot1.Color = new Core.Annotations.Color(255, 255, 255, 1)
            documentViewer2.getAnnotationManager().redrawAnnotation(annot1)
            return result.join(' ')
          }

          const getStringbyPositiion = async (
            page: Core1.PDFNet.Page,
            pageBox: Core1.PDFNet.Rect,
            quads: Core1.Math.Quad
          ) => {
            const rect1 = new PDFNet.Rect(
              quads.x1 + pageBox.x1 + 0.5,
              pageBox.y2 - quads.y1 + 1.5,
              quads.x3 + pageBox.x1 - 0.5,
              pageBox.y2 - quads.y3 - 3
            )

            const txt = await PDFNet.TextExtractor.create()
            await txt.begin(page, rect1)
            const a = await txt.getAsText()
            return a.endsWith('\n') ? a.slice(0, a.length - 1) : a
          }

          const initOriginHilight = async () => {
            await documentViewer2.getAnnotationsLoadedPromise()
            const b = documentViewer2
              .getAnnotationManager()
              .getAnnotationsList()
              .filter(
                x =>
                  x instanceof Core.Annotations.TextHighlightAnnotation &&
                  x.getCustomData('dataPointId')
              )

            const a = await Promise.all(
              b.map(
                async x =>
                  ({
                    annot: x,
                    text: await getHilighText(
                      x as Core1.Annotations.TextHighlightAnnotation
                    )
                  } as CompareHighlight)
              )
            )
            return a
          }

          const a = await initOriginHilight()
          setAnnonteList(a)
          setLoading(false)
        }
        setViewerInstance(instance)
      })
    })
  }, [viewer])

  useEffect(() => {
    if (
      !viewerInstance ||
      !annonteList.length ||
      !currentScope?.sections.length
    )
      return
    const [documentViewer1, documentViewer2] =
      viewerInstance.Core.getDocumentViewers()
    // const {annotationManager} = viewerInstance.Core
    documentViewer1
      .getAnnotationManager()
      .addEventListener('annotationSelected', (annotations, action) => {
        if (action === 'selected') {
          const a1: Core1.Annotations.TextUnderlineAnnotation =
            annotations[0] as Core1.Annotations.TextUnderlineAnnotation
          //un select prev
          documentViewer1.getAnnotationManager().deselectAllAnnotations()
          const a = documentViewer2
            .getAnnotationManager()
            .getSelectedAnnotations()
          a.forEach(an => {
            an.Color = new viewerInstance.Core.Annotations.Color(
              255,
              255,
              255,
              1
            )
            documentViewer2.getAnnotationManager().redrawAnnotation(an)
          })
          documentViewer2.getAnnotationManager().deselectAllAnnotations()
          ////

          const dpId = a1.getCustomData('dataPointId')
          const f = annonteList.find(
            x => Number(x.annot.getCustomData('dataPointId')) === Number(dpId)
          )

          if (f) {
            const scroll = documentViewer2.getScrollViewElement()
            const scrollLeft = scroll.scrollLeft
            documentViewer2.displayPageLocation(
              f.annot.PageNumber,
              0,
              f.annot.Y
            )
            scroll.scrollTo({left: scrollLeft})

            f.annot.Color = new viewerInstance.Core.Annotations.Color(
              255,
              230,
              162,
              1
            )
            documentViewer2.getAnnotationManager().redrawAnnotation(f.annot)
            documentViewer2.getAnnotationManager().selectAnnotation(f.annot)
          }
        }
      })
  }, [viewerInstance, currentScope?.sections, annonteList])

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <Breadcrumbs sx={{py: 1}} separator={<DotIcon />}>
        <Link
          style={{textDecoration: 'none', color: '#212B36'}}
          to={'/project'}
        >
          <Typography variant="body2">Deliverable </Typography>
        </Link>
        <Link
          style={{textDecoration: 'none', color: '#212B36'}}
          to={'/project'}
        >
          Viewer
        </Link>
        <Typography sx={{color: 'text.disabled'}}>
          Abstract lease mapping
        </Typography>
      </Breadcrumbs>
      <div
        className="webviewer"
        ref={viewer}
        style={{flexGrow: 1, width: '100%'}}
      />
      {loading && (
        <CircularProgress
          size={60}
          sx={{position: 'absolute', marginX: '43vw', marginY: '40vh'}}
        />
      )}
    </Box>
  )
})
