import {makeAutoObservable, runInAction} from 'mobx'
import Project from '../entities/project/Project'
import Instruction from 'src/entities/project/Instruction'
import downloadBlob from 'src/utils/downloadFile'
import {
  AzureFileResource,
  getCurrentRoot,
  getCurrentRootPath,
  MyFile,
  MyFolder,
  ROOT_NAME,
  updateRoot
} from 'src/entities/file'
import config from '../config'
import {InstructionType} from '../entities/project/Instruction'
import LoginStore from 'src/store/LoginStore'
import {fDateTime, getFormattedDateTimeRL} from 'src/utils/formatTime'
import {downloadFile} from 'src/utils/downloadFile'
import Item, {ItemStatus, MCIssue, MCItemStatus} from 'src/entities/Item'
import BaseResponse from 'src/entities/Response'
import Attachment from 'src/entities/Attachment'

export default class ProjectStore {
  selectedProject?: Project

  currentdocFolder!: string

  searchOnCurrentdocFolder!: string

  docFolders!: MyFolder

  contractFiles?: MyFile[]

  MCConfirmedItems?: Item[]

  inProgressItems?: Item[]

  completedItems?: Item[]

  MCItems?: MCIssue[]

  newInstruction = false

  instructions: Instruction[] = []
  openUploadDialigFile = false

  constructor(readonly loginStore: LoginStore) {
    this.initData()
    makeAutoObservable(this)
  }

  initData() {
    runInAction(() => {
      this.contractFiles = []
      this.newInstruction = false
      this.instructions = []
      this.docFolders = new MyFolder(ROOT_NAME, '', [])
      this.currentdocFolder = ROOT_NAME + '/'
      this.searchOnCurrentdocFolder = ''
      this.openUploadDialigFile = false
    })
  }

  get deliveredCount(): number {
    return this.completedItems?.length ?? 0
  }

  get inProgressCount(): number {
    return this.inProgressItems?.length ?? 0
  }

  get MCItemsCount(): number {
    return this.MCItems?.length ?? 0
  }

  get MCConfirmedItemsCount(): number {
    return this.MCConfirmedItems?.length ?? 0
  }

  get MCNewItemsCount(): number {
    return (
      this.MCItems?.filter(
        i =>
          i.status !== MCItemStatus.Resolved &&
          i.status !== MCItemStatus.Responded
      )?.length ?? 0
    )
  }

  setNewInstruction = (value: boolean) => {
    this.newInstruction = value
  }

  setOpenUploadDialigFile = (value: boolean) => {
    this.openUploadDialigFile = value
  }

  getClientFiles = async () => {
    const projectId: number = this.selectedProject?.id || 0
    return this.loginStore
      .fetchWithUser(`${config.apiUrl}/projects/${projectId}/getClientFiles`)
      .then((data: AzureFileResource[]) => {
        runInAction(() => {
          if (this.selectedProject)
            this.docFolders = new MyFolder(ROOT_NAME, '', [])
          this.currentdocFolder = ROOT_NAME + '/'
          updateRoot(this.docFolders, this.currentdocFolder, data, [], 'load')
        })
      })
  }

  addNewInstruction = async (
    newInstruction: Instruction,
    type: InstructionType
  ) => {
    return this.loginStore
      .fetchWithUser(`${config.apiUrl}/instructions`, true, undefined, {
        method: 'POST',
        body: JSON.stringify({
          type: type,
          projectId:
            type === InstructionType.Project ? this.selectedProject?.id : null,
          ...newInstruction
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((data: Instruction) => {
        runInAction(() => {
          this.instructions.push(
            new Instruction(data.id, data.subject, data.content, data.createdAt)
          )
        })
      })
  }

  getContractFiles = async () => {
    const projectId: number = this.selectedProject?.id || 0
    return this.loginStore
      .fetchWithUser(`${config.apiUrl}/projects/${projectId}/getEngagmentDocs`)
      .then((data: AzureFileResource[]) => {
        runInAction(() => {
          this.contractFiles = []
          data.forEach(p => {
            this.contractFiles?.push(
              new MyFile(
                p.path.split('/').pop() || '',
                p.path,
                null,
                p.lastModified,
                p.length
              )
            )
          })
        })
      })
  }

  upload = async (formData: FormData, uploadMC: boolean) => {
    try {
      const projId = this.selectedProject?.id
      const url = uploadMC
        ? `${config.apiUrl}/items/${projId}/uploadMCAttachments`
        : `${config.apiUrl}/projects/${projId}/uploadClientFiles`
      const response = await this.loginStore.fetchWithUser(
        url,
        false,
        undefined,
        {
          method: 'POST',
          body: formData
        }
      )
      const result = await response.json()
      const fileName: AzureFileResource = result[0]
      if (!uploadMC) {
        return runInAction(() => {
          updateRoot(
            this.docFolders,
            this.currentdocFolder,
            [fileName],
            [],
            'load'
          )
          return fileName.path
        })
      } else return fileName.path
    } catch (error) {
      return ''
    }
  }

  onGetMaliciousFile = async (
    fileFullPath: string,
    isResponsefile: boolean
  ) => {
    if (!isResponsefile) {
      this.onDeleteClientFile(fileFullPath)
    } else {
      this.onDeleteResponseFile(fileFullPath)
    }
  }

  onDeleteClientFile = async (filePath: string) => {
    const currentdocFolder = ROOT_NAME + '/' + getCurrentRootPath(filePath)
    const fileName: MyFile = {
      path: filePath,
      name: filePath.split('/').pop() || ''
    } as MyFile
    return runInAction(() => {
      updateRoot(this.docFolders, currentdocFolder, [], [fileName], 'remove')
    })
  }

  onDeleteResponseFile = async (filePath: string) => {
    runInAction(() => {
      this.selectedProject?.generalResponses?.forEach(response => {
        const index =
          response.attachments?.findIndex(f => f.file.path === filePath) ?? -1
        if (index !== -1) response.attachments?.splice(index, 1)
      })
      this.MCItems?.forEach(mc => {
        mc.clientResponses?.forEach(res => {
          const index =
            res.attachments?.findIndex(f => f.file.path === filePath) ?? -1
          if (index !== -1) {
            res.attachments?.splice(index, 1)
          }
        })
      })
    })
  }

  afterUploadClientFile = async (directories: string[]) => {
    try {
      const response = await this.loginStore.fetchWithUser(
        `${config.apiUrl}/projects/${this.selectedProject?.id}/afterUploadClientFiles`,
        false,
        undefined,
        {
          method: 'PUT',
          body: JSON.stringify({
            updateadAt: getFormattedDateTimeRL(new Date(), true),
            directories: directories
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const result = (await response.json()) as Project
      return runInAction(() => {
        if (this.selectedProject) {
          this.selectedProject.status = result.status
        }
        return true
      })
    } catch (error) {
      return false
    }
  }

  setSelectedDocFolder(selectedDoc: string) {
    this.currentdocFolder = selectedDoc
    this.searchOnCurrentdocFolder = ''
  }

  setSearchCurrentDoc = (searchValue: string) => {
    this.searchOnCurrentdocFolder = searchValue
  }

  async downloaddDocFile(path: string, name: string) {
    const data = getCurrentRoot(this.currentdocFolder, this.docFolders)
    const row = data.children.find(x => x.name === name)
    if (row) (row as MyFile).isDowloading = true

    const p = path.replace(ROOT_NAME + '/', '')
    return this.loginStore
      .fetchWithUser(
        `${config.apiUrl}/projects/${this.selectedProject?.id}/downloadClientFiles?path=${p}`,
        false
      )
      .then(response => response.blob())
      .then(blob => {
        downloadBlob(name, blob)
        if (row)
          runInAction(() => {
            ;(row as MyFile).isDowloading = false
          })
      })
  }

  downloadContractFile = async (path: string, name: string) => {
    const row = this.contractFiles?.find(x => x.path === path)
    if (row) (row as MyFile).isDowloading = true

    return this.loginStore
      .fetchWithUser(
        `${config.apiUrl}/projects/downloadEngagement?path=${path}`,
        false
      )
      .then(response => response.blob())
      .then(blob => {
        downloadBlob(name, blob)
        if (row)
          runInAction(() => {
            ;(row as MyFile).isDowloading = false
          })
      })
  }

  async getSelectedProject(projectId?: number) {
    return this.loginStore
      .fetchWithUser(`${config.apiUrl}/projects/${projectId || 'last'}`)
      .then((data: Project) => {
        this.setSelectedProject(data, true)
      })
  }

  setSelectedProject = (project: Project, fetchData = false) => {
    runInAction(() => {
      this.selectedProject = new Project(project)
    })
    this.initData()
    if (fetchData) this.getInstructions()
  }

  getItems = async (projectId: number) => {
    return this.loginStore
      .fetchWithUser(`${config.apiUrl}/items/ByProjectId/${projectId}`)
      .then((data?: Item[]) => {
        runInAction(() => {
          this.MCConfirmedItems = data?.filter(x => x.status === ItemStatus.MC)
          this.inProgressItems = data
            ?.filter(x => x.status === ItemStatus.InProgress)
            .map(i => new Item(i))
          this.completedItems = data
            ?.filter(x => x.status === ItemStatus.Completed)
            .map(i => new Item(i))
          const mcItems = data?.filter(x => x.mcIssues?.length)
          if (mcItems) {
            const result: MCIssue[] = []
            mcItems.forEach(mcItem => {
              if (mcItem.mcIssues?.length) {
                mcItem.mcIssues.forEach(i => {
                  const newItem = new MCIssue(i as MCIssue)
                  newItem.item = new Item(mcItem)
                  newItem.setClientResponses(newItem.clientResponses)
                  result.push(newItem)
                })
              }
            })
            this.MCItems = result
          }
        })
      })
  }

  getItemsByStatus = async (projectId: number, status: ItemStatus) => {
    return this.loginStore
      .fetchWithUser(
        `${config.apiUrl}/items/ByProjectId/${projectId}/${status}`
      )
      .then((data?: Item[]) => {
        runInAction(() => {
          switch (Number(status)) {
            case ItemStatus.InProgress:
              this.inProgressItems = data?.map(i => new Item(i))
              break
            case ItemStatus.Completed:
              this.completedItems = data?.map(i => new Item(i))
          }
        })
      })
  }

  getClientResponses = async (mc: MCIssue) => {
    return this.loginStore
      .fetchWithUser(`${config.apiUrl}/items/${mc.id}/GetClientResponses`)
      .then((issue?: MCIssue) => {
        if (issue?.id === mc.id) {
          mc.setClientResponses(
            issue?.clientResponses?.map(i => new BaseResponse(i))
          )
          mc.setStatus(issue?.status)
        }
      })
  }

  getGeneralClientResponses = async () => {
    return this.loginStore
      .fetchWithUser(
        `${config.apiUrl}/projects/${this.selectedProject?.id}/GetGeneralClientResponses`
      )
      .then((data?: BaseResponse[]) => {
        this.selectedProject?.setGeneralResponses(data)
      })
  }

  getInstructions = async () => {
    return this.loginStore
      .fetchWithUser(
        `${config.apiUrl}/instructions/ByProjectId/${this.selectedProject?.id}`
      )
      .then((data: Instruction[]) => {
        runInAction(() => {
          this.instructions = []
          data.forEach(x => {
            this.instructions.push(
              new Instruction(x.id, x.subject, x.content, x.createdAt)
            )
          })
        })
      })
  }

  updateLink = async (
    siteLink: string | null,
    siteLinkComment: string | null
  ) => {
    return this.loginStore
      .fetchWithUser(
        `${config.apiUrl}/projects/${this.selectedProject?.id}/updateLink`,
        true,
        undefined,
        {
          method: 'PUT',
          body: JSON.stringify({
            siteLink,
            siteLinkComment,
            updateadAt: getFormattedDateTimeRL(new Date(), true)
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((data: Project) => {
        runInAction(() => {
          if (this.selectedProject) {
            this.selectedProject.status = data.status
            this.selectedProject.siteLink = data.siteLink
            this.selectedProject.siteLinkComment = data.siteLinkComment
          }
        })
      })
  }

  generateClientResponce = (mcIssue: MCIssue) => {
    return mcIssue.clientResponses
      ?.map(
        x =>
          `\n<b>${fDateTime(x.createdAt, 'MM/dd/yyyy')}:</b>\n${x.content}${
            x.attachments?.length
              ? '\nRelevant documents were uploaded to the Site'
              : ''
          }`
      )
      .join(' ')
  }

  generateMCReport = async (items: MCIssue[]) => {
    return this.loginStore
      .fetchWithUser(`${config.apiUrl}/items/GenerateMCReport`, false, [422], {
        method: 'POST',
        body: JSON.stringify({
          items: items.map(i => {
            return {
              lpid: i.item?.lpid,
              fileName: i.item?.tenant,
              PropertyName: i.item?.propertyName,
              DateAdded: i.createdAt?.toISOString(),
              MCIssue: i.issue,
              ClientResponse: this.generateClientResponce(i)
            }
          }),
          projectName: this.selectedProject?.name
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        downloadFile(response)
      })
  }
  generateAbstractReportZip = async (itemIds: number[]) => {
    return this.loginStore
      .fetchWithUser(
        `${
          config.apiUrl
        }/items/DownloadAbstractReportZip?itemIds=${itemIds.join('&itemIds=')}`,
        false,
        [422]
      )
      .then(response => {
        downloadFile(response)
        itemIds.forEach(itemId => {
          const item = this.completedItems?.find(i => i.id === itemId)
          if (item) {
            item.setItemDownloadedAt()
          }
        })
      })
      .catch((respnse: Response) => {
        if (respnse.status === 422) {
          itemIds.forEach(itemId => {
            const item = this.completedItems?.find(i => i.id === itemId)
            if (item) {
              item.setItemInUpdating()
            }
          })
        }
      })
  }

  setItemDownloaded = (itemId: number) => {
    const item = this.completedItems?.find(i => i.id === itemId)
    if (item) {
      item.setItemDownloadedAt()
    }
  }

  generateAbstractReport = async (itemId: number) => {
    return this.loginStore
      .fetchWithUser(
        `${config.apiUrl}/items/${itemId}/DownloadAbstractReport`,
        false,
        [422]
      )
      .then(response => {
        downloadFile(response)
        this.setItemDownloaded(itemId)
      })
      .catch((respnse: Response) => {
        if (respnse.status === 422) {
          const item = this.completedItems?.find(i => i.id === itemId)
          if (item) {
            item.setItemInUpdating()
          }
        }
      })
  }

  sendClientResponse = async (
    mcId: number,
    content: string,
    attachments: string[] = []
  ) => {
    return this.loginStore
      .fetchWithUser(`${config.apiUrl}/items/${mcId}`, true, undefined, {
        method: 'PUT',
        body: JSON.stringify({
          content,
          attachments: attachments.map(a => {
            return {file: {path: a}}
          })
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((mcRsponse: MCIssue) => {
        mcRsponse.clientResponses = mcRsponse.clientResponses?.map(x => {
          return {...x, createdAt: new Date(x.createdAt || 0)}
        })
        const mc = this.MCItems?.find(i => i.id === mcId)
        if (mc) {
          mc.setClientResponses([
            ...(mc.clientResponses ?? []),
            ...(mcRsponse.clientResponses ?? [])
          ])
          mc.setStatus(mc.status)
        }
      })
  }

  sendGeneralClientResponse = async (
    content: string,
    attachments: string[] = []
  ) => {
    return this.loginStore
      .fetchWithUser(
        `${config.apiUrl}/projects/GeneralResponse`,
        false,
        undefined,
        {
          method: 'PUT',
          body: JSON.stringify({
            projectId: this.selectedProject?.id,
            content,
            attachments: attachments.map(a => {
              return {file: {path: a}}
            })
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        if (response.status === 200 && this.selectedProject) {
          this.selectedProject.setGeneralResponses([
            ...(this.selectedProject.generalResponses ?? []),
            new BaseResponse()
          ])
        }
      })
  }

  downloadMCAttachment = async (attachment: Attachment) => {
    attachment.setIsDownloading(true)
    const path = attachment.file.path
    this.loginStore
      .fetchWithUser(
        `${config.apiUrl}/items/downloadMCAttachment?path=${encodeURIComponent(
          path
        )}`,
        false
      )
      .then(response => response.blob())
      .then(blob => {
        downloadBlob(path, blob)
      })
      .finally(() => attachment.setIsDownloading(false))
  }
}
