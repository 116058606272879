import {observer} from 'mobx-react-lite'
import {Divider, Stack, Typography, useTheme} from '@mui/material'
import {ReactComponent as ExpandIcon} from 'src/images/ic_chevron_down.svg'
import {DelivarableType} from 'src/components/project/deliverable/DeliverableTab'
import {FlexStack} from 'src/components/global/FlexStack'

interface DeliverableItemProps {
  selected: DelivarableType
  type: DelivarableType
  title: string
  count: number
  subTitle: string
  icon: React.ReactNode
  onClick: () => void
}

const DeliverableItem = observer(function DeliverableItem({
  selected,
  type,
  title,
  count,
  subTitle,
  icon,
  onClick
}: DeliverableItemProps) {
  const theme = useTheme()

  const color =
    type === selected
      ? type === 'Completed'
        ? theme.palette.primary.main_16
        : type === 'In progress'
        ? theme.palette.info.main_16
        : theme.palette.error.main_16
      : undefined

  return (
    <FlexStack sx={{cursor: 'pointer', background: color}} onClick={onClick}>
      <Divider />
      <Stack px={4} direction="row" alignItems="center" gap={5} height="100%">
        {icon}
        <Stack minWidth={155}>
          <Typography variant="subtitle2">{title}</Typography>
          <Typography variant="caption">{`${count} ${subTitle}${
            count !== 1 ? 's' : ''
          }`}</Typography>
        </Stack>
        <ExpandIcon />
      </Stack>
    </FlexStack>
  )
})

export default DeliverableItem
