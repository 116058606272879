import {makeAutoObservable} from 'mobx'
import {FileWithPath} from 'react-dropzone'
export interface AzureFileResource {
  path: string
  lastModified: Date
  length: number
}

export class MyFolder {
  name: string
  path: string

  children: (MyFolder | MyFile)[]

  constructor(name: string, path: string, children: (MyFolder | MyFile)[]) {
    makeAutoObservable(this)

    this.name = name
    this.path = path
    this.children = children
  }
}

export class MyFile {
  name: string
  path: string
  blob: File | null
  dateModified: Date | null
  size: number | null
  isDowloading: boolean

  constructor(
    name: string,
    path: string,
    blob: File | null,
    dateModified?: Date,
    size?: number | null
  ) {
    makeAutoObservable(this)

    this.name = name
    this.path = path
    this.blob = blob
    this.dateModified = dateModified ? new Date(dateModified) : null
    this.size = size || null
    this.isDowloading = false
  }
}

export const isFile = (f: MyFile | MyFolder) => f instanceof MyFile

export const ROOT_NAME = ':All Files'

export const updateRoot = (
  root: MyFolder,
  paths: string,
  newFiles: (AzureFileResource | File)[],
  deletedFiles: (MyFile | MyFolder)[],
  type: 'load' | 'add' | 'remove'
): MyFolder => {
  if (paths === root.path + root.name + '/')
    return type === 'remove'
      ? {...removeFiles(deletedFiles, root)}
      : {...addFiles(newFiles, root, type)}

  return {
    ...root,
    children: root.children.map(x => {
      if (paths.startsWith(x.path + x.name + '/'))
        return {
          ...updateRoot(x as MyFolder, paths, newFiles, deletedFiles, type)
        }
      return x
    })
  }
}

export const getFiles = (root: MyFolder, currentFolder: string) => {
  const results = {files: [] as FormData[], fileNames: [] as string[]}
  return getAllFiles(root, currentFolder, results)
}
const getAllFiles = (
  root: MyFolder,
  currentfolder: string,
  data: {files: FormData[]; fileNames: string[]}
) => {
  root.children.forEach(f => {
    if (f instanceof MyFile) {
      const fileName = (currentfolder + f.path + f.name).replaceAll(
        ROOT_NAME + '/',
        ''
      )
      if (f.blob) {
        const file = new FormData()
        file.append(`FormFiles`, f.blob, fileName)
        data.files.push(file)
      }
      data.fileNames.push(fileName)
    } else {
      getAllFiles(f as MyFolder, currentfolder, data)
    }
  })

  return data
}

export const getCurrentRoot = (paths: string, root: MyFolder): MyFolder => {
  if (root.path + root.name + '/' === paths) return root

  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
  const a = root.children.find(x => {
    if (paths.startsWith(x.path + x.name + '/')) return true
    return false
  })!

  return getCurrentRoot(paths, a as MyFolder)
}

export const getCurrentRootPath = (fileFullPath: string): string => {
  const lastSeparatorIndex = fileFullPath.lastIndexOf('/')

  if (lastSeparatorIndex === -1) {
    // No separator found, fullPath is likely just a filename
    return ''
  }

  return fileFullPath.substring(0, lastSeparatorIndex + 1)
}

const getFolderNames = (
  p: AzureFileResource | FileWithPath,
  type: 'load' | 'add'
) => {
  if (type === 'load') return (p as AzureFileResource).path
  if ((p as FileWithPath).webkitRelativePath)
    return (p as FileWithPath).webkitRelativePath
  if ((p as FileWithPath).path?.startsWith('/'))
    return (p as FileWithPath).path?.substring(1) || ''
  return ''
}

const addFiles = (
  files: (AzureFileResource | FileWithPath)[],
  node: MyFolder,
  type: 'load' | 'add'
) => {
  const result = files.reduce(
    (r: MyFolder, p: AzureFileResource | FileWithPath) => {
      const fullPath = getFolderNames(p, type)
      const subPath = fullPath.replace(
        (node.path + node.name + '/').replace(ROOT_NAME + '/', ''),
        ''
      )
      const names = subPath.split('/')
      if (names)
        names.reduce((q, name, currentIndex) => {
          let temp = (q as MyFolder).children.find(o => o.name === name)
          if (!temp) {
            const path = q.path + q.name + '/'
            if (currentIndex === names.length - 1)
              //Is it file?
              q.children.push(
                new MyFile(
                  type === 'load'
                    ? (p as AzureFileResource).path.split('/').pop() || ''
                    : (p as File).name,
                  path,
                  type === 'load' ? null : (p as File),
                  type === 'load'
                    ? (p as AzureFileResource).lastModified
                    : new Date((p as File).lastModified),
                  type === 'load'
                    ? (p as AzureFileResource).length
                    : (p as File).size
                )
              )
            else q.children.push(new MyFolder(name, path, []))

            temp = q.children[q.children.length - 1]
          }
          return temp as MyFolder
        }, r)

      return r
    },
    node
  )

  return result
}

const removeFiles = (files: (MyFile | MyFolder)[], node: MyFolder) => {
  node.children = node.children.filter(x => files.every(y => x.name !== y.name))
  return node
}

export const getDuplicate = (
  fileNames: string[],
  prevfileNames: string[]
): string[] => {
  const duplicate = fileNames.reduce((prev, current, currentIndex) => {
    const exist = prevfileNames.find(x => x === current)
    const theSame = fileNames.findLastIndex(x => x === current) > currentIndex
    if ((exist || theSame) && !prev.find(y => y === current)) prev.push(current)
    return prev
  }, [] as string[])

  return duplicate
}

export const getDirectories = (
  fileNames: string[],
  currentFolder: string
): string[] => {
  const sortFiles = fileNames
    .sort((a, b) => (a.toLocaleUpperCase() < b.toLocaleUpperCase() ? -1 : 1))
    .map(x => x.replace(currentFolder, ''))

  const duplicate = sortFiles.reduce((prev, current) => {
    const directory =
      current.substring(
        0,
        current.indexOf('/') > 0 ? current.indexOf('/') + 1 : 0
      ) || ' '
    if (!prev.find(x => directory.startsWith(x))) prev.push(directory)
    return prev
  }, [] as string[])

  return duplicate.map(x => {
    const y = currentFolder + x.trim()
    return y.length ? y.substring(0, y.length - 1) : y
  })
}
