import {observer} from 'mobx-react-lite'
import {LoadingButton} from '@mui/lab'
import {
  Button,
  CardActions,
  InputAdornment,
  Stack,
  Table,
  TextField,
  Typography
} from '@mui/material'
import {
  FlexCard,
  FlexCardContent,
  ParentScrollStack,
  ScrollTableContainer
} from 'src/components/global/FlexStack'
import Scrollbar from 'src/components/minimal/scrollbar/Scrollbar'
import Iconify from 'src/components/minimal/iconify'
import {useState} from 'react'
import MCResponseDialog from 'src/components/project/deliverable/MCResponseDialog'
import {useMainStore} from 'src/contexts/Main'

interface DeliverableTableProps {
  title: string
  actions?: boolean
  children?: React.ReactNode
  isDownloadAllDisable?: boolean
  onSearch?: (text: string) => void
  onDownloadAll?: () => Promise<void>
  onDownloadMC?: () => void
  onDownloadZip?: () => Promise<void>
}

const DeliverableTable = observer(function DeliverableTable({
  title,
  actions,
  children,
  isDownloadAllDisable,
  onSearch,
  onDownloadAll,
  onDownloadMC,
  onDownloadZip
}: DeliverableTableProps) {
  const {selectedProject} = useMainStore().projectStore
  const [openResponse, setOpenResponse] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [isDownloading, setIsDownloading] = useState(false)
  const [isDownloadingMC, setIsDownloadingMC] = useState(false)
  const [isDownloadingZip, setIsDownloadingZip] = useState(false)

  const onChanged = (text: string) => {
    setSearchText(text)
    onSearch?.(text)
  }

  return (
    <>
      <FlexCard>
        <FlexCardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6">{title}</Typography>

            <Stack alignItems="center" direction="row" gap={2}>
              <TextField
                size="small"
                value={searchText}
                onChange={e => onChanged(e.target.value.toLowerCase())}
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{color: 'text.disabled'}}
                      />
                    </InputAdornment>
                  )
                }}
              />
              {onDownloadMC && (
                <LoadingButton
                  disabled={isDownloadAllDisable}
                  size="medium"
                  variant="contained"
                  loading={isDownloadingMC}
                  onClick={async () => {
                    if (onDownloadMC) {
                      setIsDownloadingMC(true)
                      await onDownloadMC()
                      setIsDownloadingMC(false)
                    }
                  }}
                >
                  Download MC Report
                </LoadingButton>
              )}
              {onDownloadZip && (
                <LoadingButton
                  disabled={isDownloadAllDisable}
                  size="medium"
                  variant="contained"
                  loading={isDownloadingZip}
                  onClick={async () => {
                    if (onDownloadZip) {
                      setIsDownloadingZip(true)
                      await onDownloadZip()
                      setIsDownloadingZip(false)
                    }
                  }}
                >
                  Download Zip
                </LoadingButton>
              )}

              {onDownloadAll ? (
                <LoadingButton
                  disabled={isDownloadAllDisable}
                  size="medium"
                  variant="contained"
                  loading={isDownloading}
                  onClick={async () => {
                    if (onDownloadAll) {
                      setIsDownloading(true)
                      await onDownloadAll()
                      setIsDownloading(false)
                    }
                  }}
                >
                  Download
                </LoadingButton>
              ) : (
                ''
              )}
            </Stack>
          </Stack>
          <ParentScrollStack>
            <ScrollTableContainer>
              <Scrollbar>
                <Table size="small" stickyHeader>
                  {children}
                </Table>
              </Scrollbar>
            </ScrollTableContainer>
          </ParentScrollStack>
        </FlexCardContent>
        {actions && (
          <CardActions sx={{justifyContent: 'end', height: 70, pt: 0}}>
            <Button
              startIcon={<Iconify icon="eva:email-fill" />}
              variant="contained"
              onClick={() => setOpenResponse(true)}
            >
              {`General Response ${
                (selectedProject?.generalResponsesCount ?? 0) > 0
                  ? `(${selectedProject?.generalResponsesCount})`
                  : ''
              }`}
            </Button>
          </CardActions>
        )}
      </FlexCard>
      {openResponse && (
        <MCResponseDialog
          isGeneral={true}
          onClose={() => setOpenResponse(false)}
        />
      )}
    </>
  )
})

export default DeliverableTable
