import {useState} from 'react'
import {observer} from 'mobx-react-lite'
// @mui
import {alpha} from '@mui/material/styles'
import {Box, Divider, Typography, MenuItem} from '@mui/material'
// components
import CustomAvatar from 'src/components/minimal/custom-avatar/CustomAvatar'
import {IconButtonAnimate} from 'src/components/minimal/animate'
import MenuPopover from 'src/components/minimal/menu-popover'
import {useMainStore} from 'src/contexts/Main'
// ----------------------------------------------------------------------

function AccountPopover() {
  const {currentUser, logout} = useMainStore().loginStore
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null)

  const firstName = `${currentUser?.firstName ?? ''}`
  const lastName = `${currentUser?.lastName ?? ''}`
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  const handleLogout = async () => {
    logout()
    handleClosePopover()
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0.8)
            }
          })
        }}
      >
        <CustomAvatar firstName={firstName} lastName={lastName} />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{width: 200, p: 0}}
      >
        <Box sx={{my: 1.5, px: 2.5}}>
          <Typography variant="subtitle2" noWrap>
            {`${firstName} ${lastName}`}
          </Typography>

          <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
            {currentUser?.email}
          </Typography>
        </Box>

        <Divider sx={{borderStyle: 'dashed'}} />

        <MenuItem onClick={handleLogout} sx={{m: 1}}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  )
}

export default observer(AccountPopover)
