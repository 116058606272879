import {observer} from 'mobx-react-lite'
import {useTheme} from '@mui/material/styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Card,
  CardContent,
  Stack,
  accordionClasses,
  accordionDetailsClasses
} from '@mui/material'
import Iconify from 'src/components/minimal/iconify'
import {Section} from 'src/entities/AbstractReport'
import AbstractViewer from 'src/components/item/abstract/AbstractViewer'

interface AbstractSectionCardProps {
  section: Section
}

export default observer(function AbstractSectionCard({
  section
}: AbstractSectionCardProps) {
  const theme = useTheme()

  return (
    <Card>
      <CardContent sx={{p: 2}}>
        <Accordion
          defaultExpanded={true}
          sx={{
            [`&.${accordionClasses.expanded}`]: {margin: 0, boxShadow: 'none'}
          }}
        >
          <AccordionSummary
            sx={{background: theme.palette.grey[200]}}
            expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
          >
            <Typography variant="h5">{section.name}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{[`&.${accordionDetailsClasses.root}`]: {pb: 0}}}
          >
            <Stack spacing={2} mt={1}>
              {section.datapoints?.map(dp => (
                <AbstractViewer key={dp.name} dataPoint={dp} />
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Stack spacing={2}></Stack>
      </CardContent>
    </Card>
  )
})
