import {observer} from 'mobx-react-lite'
import {Link} from 'react-router-dom'
import {Card, CardContent, Grid, Stack, Typography} from '@mui/material'
import {ReactComponent as PropertyIcon} from 'src/images/property.svg'
import Project, {ProjectStatus} from 'src/entities/project/Project'
import {ProjectTabEnum} from '../project/ProjectTabs'

interface ProjectCardProps {
  project: Project
}

export default observer(function ProjectCard({project}: ProjectCardProps) {
  const toLink = `/project/${project.id}/${
    project.items.length
      ? ProjectTabEnum[ProjectTabEnum.Items]
      : ProjectTabEnum[ProjectTabEnum.Uploads]
  }`

  return (
    <Card
      component={Link}
      sx={{width: '100%', textDecoration: 'none', color: '#212B36'}}
      to={toLink}
    >
      <CardContent sx={{p: 2}}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="baseline">
            <PropertyIcon></PropertyIcon>
            <Typography variant="h6">{project.toString()}</Typography>
          </Stack>
          <Grid direction={'row'} container spacing={1}>
            <Grid container direction={'column'} xs={3} item>
              <Grid item>
                <Typography variant="subtitle2">City</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{project.city}</Typography>
              </Grid>
            </Grid>
            <Grid container direction={'column'} xs={2} item>
              <Grid item>
                <Typography variant="subtitle2">State</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{project.state}</Typography>
              </Grid>
            </Grid>
            <Grid container xs={4} direction={'column'} item>
              <Grid item>
                <Typography variant="subtitle2">Street Address</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{project.streetAddress}</Typography>
              </Grid>
            </Grid>
            <Grid container xs={3} direction={'column'} item>
              <Grid item>
                <Typography variant="subtitle2">Status</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {ProjectStatus[project.status]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  )
})
