// @mui
import {styled} from '@mui/material/styles'
import {ListItemButton, Popover} from '@mui/material'
import {NavItemProps} from './types'

// ----------------------------------------------------------------------

type StyledItemProps = Omit<NavItemProps, 'item'>

export const StyledItem = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'open'
})<StyledItemProps>(({active, disabled, open, depth, theme}) => {
  const isLight = theme.palette.mode === 'light'

  const isSub = depth !== 1

  const activeStyle = {
    color: isSub ? theme.palette.primary.main : theme.palette.text.primary,
    //  backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    ...(!isLight && {
      color: theme.palette.primary.light
    })
  }

  const hoverStyle = {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover
  }

  return {
    flexDirection: 'column',
    textTransform: 'capitalize',
    // padding: theme.spacing(1, 0, 0.5, 0),
    color: theme.palette.text.secondary,
    width: '100%',
    // Active item
    ...(active && {
      ...activeStyle,
      '&:hover': {
        ...activeStyle
      }
    }),

    // Open
    ...(open && !active && hoverStyle),
    // Disabled
    ...(disabled && {
      '&.Mui-disabled': {
        opacity: 0.64
      }
    })
  }
})
// ----------------------------------------------------------------------

export const StyledPopover = styled(Popover)(({theme}) => ({
  pointerEvents: 'none',
  '& .MuiPopover-paper': {
    width: 250,
    pointerEvents: 'auto',
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    boxShadow: theme.customShadows.dropdown,
    borderRadius: Number(theme.shape.borderRadius) * 1.5
    //...bgBlur({ color: theme.palette.background.default }),
  }
}))
