import {useNavigate} from 'react-router-dom'
import {Stack, Box, Typography, Button} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import {ReactComponent as Logo} from '../../images/newLogo.svg'
import Warning from '../../images/gif/warning.gif'
import Warning2 from '../../images/gif/warning2.gif'
import {CustomErrors} from 'src/store/LoginStore'

export default function ErrorScreen({
  routerError = false
}: {
  routerError?: boolean
}) {
  const {errorStatus, setErrorStatus} = useMainStore().loginStore
  const navigate = useNavigate()

  const goToHome = () => {
    navigate('/dashboard')
    setErrorStatus(undefined)
  }

  const is404 = routerError || errorStatus === 404
  const is500 = errorStatus === 500
  const noConnection = errorStatus === CustomErrors.Typeerror
  const ErrorMsg = is500
    ? '500 Internal Server Error'
    : is404
    ? 'Sorry, page not found!'
    : `${noConnection ? 'No Connection' : errorStatus} Error occurred`
  const SubErrorMsg = is500
    ? 'There was an error, please try again later.'
    : is404
    ? 'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.'
    : `Please try again later.`

  const renderError = () => {
    return (
      <>
        <Typography variant="h3">{ErrorMsg}</Typography>
        <Typography
          align="center"
          variant="body1"
          color={theme => theme.palette.grey[600]}
          width={450}
        >
          {SubErrorMsg}
        </Typography>
        <Box width="160px" height="160px" my={4}>
          <img src={is404 ? Warning : Warning2} alt="warning-gif" />
        </Box>
      </>
    )
  }

  return (
    <Stack width={'100%'} height={'100%'}>
      <Box px={2} bgcolor={theme => theme.palette.primary.main} py={2}>
        <Logo />
      </Box>
      <Box bgcolor={theme => theme.palette.grey[500]} height={'1px'} />
      <Stack
        alignItems={'center'}
        alignSelf={'center'}
        top={'20%'}
        position={'relative'}
      >
        {renderError()}
        <Button variant="contained" onClick={goToHome}>
          Go to Home
        </Button>
      </Stack>
    </Stack>
  )
}
