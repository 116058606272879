import {observer} from 'mobx-react-lite'
import {Button, Card, CardContent, Stack, Typography} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import Iconify from 'src/components/minimal/iconify'
import {ScrollBox, ParentScrollStack} from 'src/components/global/FlexStack'
import InstructionItem from 'src/components/project/instruction/InstructionItem'
import NewInstructionDialog from 'src/components/project/instruction/NewInstructionDialog'
import Scrollbar from 'src/components/minimal/scrollbar/Scrollbar'
import {useEffect} from 'react'
import React from 'react'

const InstructionTab = observer(function InstructionTab() {
  const {
    projectStore: {
      newInstruction,
      getInstructions,
      selectedProject,
      instructions,
      setNewInstruction
    }
  } = useMainStore()

  useEffect(() => {
    getInstructions()
  }, [selectedProject?.id, getInstructions])

  return (
    <Card sx={{width: '100%', height: '100%'}}>
      <CardContent sx={{height: '100%'}}>
        {newInstruction ? (
          <NewInstructionDialog onClose={() => setNewInstruction(false)} />
        ) : (
          <Stack spacing={1} height="100%">
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Instructions</Typography>
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => setNewInstruction(true)}
              >
                New
              </Button>
            </Stack>
            <ParentScrollStack>
              <ScrollBox>
                <Scrollbar>
                  {instructions
                    .slice()
                    .sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1))
                    .map(i => (
                      <InstructionItem key={i.id} item={i} />
                    ))}
                </Scrollbar>
              </ScrollBox>
            </ParentScrollStack>
          </Stack>
        )}
      </CardContent>
    </Card>
  )
})

export default InstructionTab
