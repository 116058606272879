import {makeAutoObservable} from 'mobx'

export default class DataPoint {
  id: string

  name: string
  propertyReportName: string

  isSelected: boolean

  constructor(
    id: string,
    name: string,
    propertyReportName: string,
    isSelected = false
  ) {
    makeAutoObservable(this)
    this.id = id
    this.name = name
    this.propertyReportName = propertyReportName
    this.isSelected = isSelected
  }

  toggleSelection = (select?: boolean) => {
    this.isSelected = select ?? !this.isSelected
  }

  get propertyReport() {
    return this.propertyReportName || this.name
  }
}
