import Router from '../src/routes/root'
// theme
import ThemeProvider from './theme'
import MainStoreProvider from './contexts/Main'
// editor
import 'react-quill/dist/quill.snow.css'
// scroll bar
import 'simplebar-react/dist/simplebar.min.css'
import {ReportersContextProvider} from './contexts/Reporters'

function App() {
  return (
    <MainStoreProvider>
      <ReportersContextProvider>
        <ThemeProvider>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
          <Router />
          {/* </LocalizationProvider> */}
        </ThemeProvider>
      </ReportersContextProvider>
    </MainStoreProvider>
  )
}

export default App
