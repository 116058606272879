// @mui
import {Stack, Checkbox, TableRow, TableCell, Typography} from '@mui/material'
import {LoadingButton} from '@mui/lab'

import {getFormattedDateTime} from '../../../../utils/formatTime'
import {fData} from '../../../../utils/formatNumber'
// @types
import {MyFile, MyFolder} from 'src/entities/file'

import FileThumbnail, {fileFormat} from 'src/components/minimal/file-thumbnail'
import {ReactComponent as DownloadIcon} from 'src/images/ic_download.svg'
import {observer} from 'mobx-react-lite'
import CircularProgress from '@mui/material/CircularProgress'

// ----------------------------------------------------------------------

type Props = {
  row: MyFile | MyFolder
  selected: boolean
  addSelectCell?: boolean
  onSelectRow: VoidFunction
  onDeleteRow: VoidFunction
  onClickRow: VoidFunction
  onClickDownload: VoidFunction
}

export default observer(function FileTableRow({
  row,
  selected,
  addSelectCell,
  onSelectRow,
  onDeleteRow,
  onClickRow,
  onClickDownload
}: Props) {
  return (
    <>
      <TableRow
        sx={{
          borderRadius: 1,
          '& .MuiTableCell-root': {
            mx: 1
          }
        }}
      >
        {addSelectCell && (
          <TableCell
            padding="checkbox"
            sx={{
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8
            }}
          >
            <Checkbox checked={selected} onClick={onSelectRow} />
          </TableCell>
        )}

        <TableCell onClick={onClickRow}>
          <Stack direction="row" alignItems="center" spacing={4} pl={2}>
            <FileThumbnail
              file={row instanceof MyFile ? row.name : 'folder'}
              tooltip
              //imageView
              sx={{position: 'absolute'}}
              imgSx={{position: 'absolute'}}
            />

            <Typography
              noWrap
              variant="inherit"
              sx={{maxWidth: 360, cursor: 'pointer'}}
            >
              {row.name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell
          align="left"
          sx={{color: 'text.secondary', whiteSpace: 'nowrap'}}
        >
          {row instanceof MyFile && fData(row.size)}
        </TableCell>

        <TableCell
          align="center"
          sx={{color: 'text.secondary', whiteSpace: 'nowrap'}}
        >
          {row instanceof MyFile ? fileFormat(row.name) : 'folder'}
        </TableCell>

        <TableCell
          align="left"
          sx={{color: 'text.secondary', whiteSpace: 'nowrap'}}
        >
          {row instanceof MyFile &&
            row.dateModified &&
            getFormattedDateTime(row.dateModified)}
        </TableCell>

        <TableCell
          align="right"
          sx={{
            whiteSpace: 'nowrap',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8
          }}
        >
          {row instanceof MyFile && (
            <LoadingButton
              onClick={onClickDownload}
              disabled={(row as MyFile).isDowloading}
              loading={(row as MyFile).isDowloading}
              loadingIndicator={<CircularProgress size={50} />}
            >
              {<DownloadIcon />}
            </LoadingButton>
          )}
        </TableCell>
      </TableRow>
    </>
  )
})
