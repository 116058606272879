import {useTheme} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import Chart, {useChart} from 'src/components/minimal/chart'

const StatusChart = observer(function StatusChart() {
  const {projectStore} = useMainStore()
  const theme = useTheme()
  const series = [
    projectStore.inProgressCount,
    projectStore.deliveredCount,
    projectStore.MCConfirmedItemsCount
  ]

  const chartOptions = useChart({
    labels: ['In Progress', 'Completed', 'Missing and Conflicting'],
    dataLabels: {
      enabled: false
    },
    colors: ['#2D99FF', theme.palette.primary.main, theme.palette.error.main],
    stroke: {
      show: false
    },
    legend: {
      horizontalAlign: 'center',
      position: 'bottom'
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%'
        }
      }
    }
  })

  return (
    <Chart
      type="donut"
      series={series}
      options={chartOptions}
      height={230}
      width={280}
    />
  )
})

export default StatusChart
