import {makeAutoObservable} from 'mobx'

export class ItemFileNode {
  id: number
  uniqueId?: string
  name: string
  path: string
  blob?: Blob = undefined
  isChecked = false
  disabled: boolean
  children: ItemFileNode[]
  isNodeExpanded = false

  constructor(
    id: number,
    name: string,
    path: string,
    children: ItemFileNode[],
    disabled: boolean,
    uniqueId?: string
  ) {
    makeAutoObservable(this)

    this.id = id
    this.name = name
    this.path = path
    this.children = children
    this.disabled = disabled
    this.uniqueId = uniqueId
  }

  get isFolder() {
    return this.children.length
  }

  get isAnyChecked(): boolean {
    return this.isFolder
      ? this.children?.some(ch => ch.isAnyChecked)
      : this.isChecked
  }

  get isAllChecked(): boolean {
    return this.isFolder
      ? this.available?.every(ch => ch.isAllChecked)
      : this.isChecked
  }

  get isAllExpanded(): boolean {
    return this.isFolder
      ? this.disabled ||
          (this.isNodeExpanded && this.children?.every(ch => ch.isAllExpanded))
      : true
  }

  get checked() {
    return this.children?.filter(ch => ch.isChecked)
  }

  get available(): ItemFileNode[] {
    const items: ItemFileNode[] = []
    if (!this.isFolder) {
      return this.disabled ? [] : [this]
    } else {
      this.children.forEach(child => {
        const chAvailable = child.available
        items.push(...chAvailable)
      })
    }
    return items
  }

  setIsNodeExpanded = (value: boolean) => {
    this.isNodeExpanded = value
  }

  setBlob = (value?: Blob) => {
    this.blob = value
  }

  toggleChecked = () => {
    if (this.isFolder) this.available.forEach(ch => ch.toggleChecked())
    else this.isChecked = !this.isChecked
  }

  toggleExpanAll = (value: boolean) => {
    if (this.disabled) return
    this.setIsNodeExpanded(value)
    if (this.isFolder) this.children?.forEach(ch => ch.toggleExpanAll(value))
  }

  findNodeByFileId = (fileIdToFind: string): ItemFileNode | undefined => {
    if (this.uniqueId?.toLocaleLowerCase() === fileIdToFind.toLocaleLowerCase())
      return this

    for (const child of this.children) {
      const foundNode = child.findNodeByFileId(fileIdToFind)
      if (foundNode) return foundNode
    }

    return undefined
  }

  isDocx = (name: string) => name.split('.').pop() === 'docx'
}
