// @mui
import {Box, BoxProps} from '@mui/material'
// hooks
import useResponsive from 'src/hooks/useResponsive'
// config
import {HEADER, NAV} from 'src/settings/config-setting'
// components
import {useSettingsContext} from 'src/settings'

// ----------------------------------------------------------------------

const SPACING = 8

export default function Main({children, sx, ...other}: BoxProps) {
  const {themeLayout} = useSettingsContext()

  const isNavHorizontal = themeLayout === 'horizontal'

  const isNavMini = true // themeLayout === 'mini'

  const isDesktop = useResponsive('up', 'lg')

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          pt: `${HEADER.H_MOBILE + SPACING}px`,
          pb: `${HEADER.H_MOBILE + SPACING}px`,
          ...(isDesktop && {
            px: 2,
            pt: `${HEADER.H_DASHBOARD_DESKTOP + 80}px`,
            pb: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`
          })
        }}
      >
        {children}
      </Box>
    )
  }

  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        bgcolor: theme => theme.palette.grey[200],

        flexGrow: 1,
        pt: `${HEADER.H_MOBILE + SPACING}px`,
        pb: `${SPACING}px`,
        ...(isDesktop && {
          px: 2,
          pt: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
          // width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`
          })
        }),
        ...sx
      }}
      {...other}
    >
      {children}
    </Box>
  )
}
