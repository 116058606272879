import {FileRejection} from 'react-dropzone'
// @mui
import {alpha} from '@mui/material/styles'
import {Box, Paper, Typography} from '@mui/material'
// utils
import {fileData} from '../../file-thumbnail'

// ----------------------------------------------------------------------

type Props = {
  fileRejections: FileRejection[]
}

export default function RejectionFiles({fileRejections}: Props) {
  if (!fileRejections.length) {
    return null
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        bgcolor: theme => alpha(theme.palette.error.main, 0.08),
        borderColor: theme => alpha(theme.palette.error.main, 0.24)
      }}
    >
      {fileRejections.map(({file, errors}) => {
        const {path} = fileData(file)

        return (
          <Box key={path} sx={{my: 1}}>
            <Typography variant="subtitle2">
              The file type you are attempting to upload is not authorize -
            </Typography>
            <Typography variant="caption" noWrap>
              {path}
            </Typography>
          </Box>
        )
      })}
    </Paper>
  )
}
