import {observer} from 'mobx-react-lite'
import {Card, CardContent, Stack, Typography} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'

export default observer(function AbstractHeader() {
  const {abstractReport} = useMainStore().absractReportStore

  return (
    <Card>
      <CardContent>
        {abstractReport && (
          <>
            <Typography align="center" mb={1} variant="h4">
              {'Abstract'}
            </Typography>
            <Typography align="center" mb={1} variant="h5">
              {abstractReport.tenantName}
            </Typography>
            <Typography mb={1} variant="h6">
              {abstractReport.name}
            </Typography>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Stack>
                <Typography variant="body2">{`Date Reviewed: ${new Date(
                  abstractReport.dateReviewed
                ).toLocaleDateString()}`}</Typography>
                <Typography variant="body2">{`Abstractor: ${
                  abstractReport.abstractorDesc ?? ''
                }`}</Typography>
              </Stack>
              <Stack>
                <Typography variant="body2">{`LPID: ${abstractReport.lpid}`}</Typography>
                <Typography variant="body2">{`QC: ${abstractReport.qcDesc}`}</Typography>
              </Stack>
            </Stack>
            <Typography textAlign={'center'} variant="body2">
              {abstractReport.leaseDocument}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  )
})
