import {
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
  Tooltip,
  Checkbox
} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import Billing, {Status} from 'src/entities/Billing'
import Label from 'src/components/minimal/label/Label'
import {useMainStore} from 'src/contexts/Main'
import {ReactComponent as DownloadIcon} from 'src/images/ic_download.svg'
import {getFormattedDate} from 'src/utils/formatTime'
import {observer} from 'mobx-react-lite'
import {fCurrency} from 'src/utils/formatNumber'

interface BiilingRowProps {
  item: Billing
}

function BiilingRow({item}: BiilingRowProps) {
  const {billingStore} = useMainStore()

  const onDownload = async () => {
    billingStore.downloadAttachments(item)
  }

  const attachmentsTip =
    item.attachments?.map(a => a.file.path).join('\n') ?? ''

  return (
    <TableRow sx={{height: 45.5}}>
      <TableCell padding="checkbox">
        <Checkbox
          disabled={item.attachments.length === 0}
          color="primary"
          checked={item.isSelected}
          onChange={(e, checked) => {
            item.setIsSelected(checked)
          }}
        />
      </TableCell>
      <TableCell>
        <Typography variant="body1">{item.invoiceNumber}</Typography>
      </TableCell>
      <TableCell>{item.projectName}</TableCell>
      <TableCell>{item.propertyName}</TableCell>
      <TableCell>
        {item.invoiceDate && getFormattedDate(item.invoiceDate)}
      </TableCell>
      <TableCell>
        {
          <Label variant="soft" color={item.colorByStatus}>
            {Status[item.status]}
          </Label>
        }
      </TableCell>
      <TableCell>{fCurrency(item.amount ?? 0)}</TableCell>
      <TableCell>{fCurrency(item.paidAmount ?? 0)}</TableCell>
      <TableCell>{fCurrency(item.openBalanceAmount ?? 0)}</TableCell>
      <TableCell>{item.paidDate && getFormattedDate(item.paidDate)}</TableCell>
      <TableCell>
        {item.attachments.length > 0 && (
          <Tooltip title={attachmentsTip}>
            <LoadingButton
              onClick={onDownload}
              disabled={item.isDownloading}
              loading={item.isDownloading}
              loadingIndicator={<CircularProgress size={50} />}
            >
              <DownloadIcon />
            </LoadingButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  )
}

export default observer(BiilingRow)
