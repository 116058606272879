import {Button, InputAdornment, Stack, TextField} from '@mui/material'
import {observer} from 'mobx-react-lite'
import Iconify from 'src/components/minimal/iconify'
import {useMainStore} from 'src/contexts/Main'

export default observer(function UploadHeader() {
  const {
    searchOnCurrentdocFolder,
    docFolders,
    setSearchCurrentDoc,
    setOpenUploadDialigFile
  } = useMainStore().projectStore

  return (
    <>
      <Stack
        direction="row"
        justifyContent="end"
        height={'40px'}
        spacing={1}
        alignContent="start"
        mb={1}
      >
        {docFolders?.children?.length ? (
          <TextField
            size="small"
            value={searchOnCurrentdocFolder}
            onChange={e => {
              setSearchCurrentDoc(e.target.value)
            }}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{color: 'text.disabled'}}
                  />
                </InputAdornment>
              )
            }}
          />
        ) : (
          <></>
        )}
        <Button
          variant="contained"
          onClick={() => {
            setOpenUploadDialigFile(true)
          }}
          startIcon={<Iconify icon="eva:cloud-upload-fill" />}
        >
          Share Files
        </Button>
      </Stack>
    </>
  )
})
