import {makeAutoObservable} from 'mobx'
import {getLocalDateFromUtc} from 'src/utils/formatTime'

export default class Instruction {
  id: number
  subject: string

  content: string

  createdAt: Date

  constructor(
    id: number,
    subject: string,
    content: string,
    createdAt: Date | string
  ) {
    makeAutoObservable(this)
    this.id = id || 0
    this.subject = subject
    this.content = content
    this.createdAt = getLocalDateFromUtc(createdAt)
  }
}

export enum InstructionType {
  Client,
  Project,
  'Client Instruction' = Client,
  'Project Instruction' = Project
}
