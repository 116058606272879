import {observer} from 'mobx-react-lite'
import {Card, CardContent, Stack, Typography} from '@mui/material'
import ReportTable from 'src/components/report/ReportTable'
import ReportTableHeader from 'src/components/report/ReportTableHeader'
import {ReportType} from 'src/entities/Report'

interface ReportPageProps {
  reportType: ReportType
}

function getReportHeader(reportType: ReportType) {
  switch (reportType) {
    case ReportType.PropertyReport:
      return 'Property Data Points'
    case ReportType.RentReport:
      return '12 Months Rent'
    case ReportType.PropertyRentRollReport:
      return 'Rent Report'
  }
}

const ReportPage = observer(function ReportPage({reportType}: ReportPageProps) {
  const header = getReportHeader(reportType)

  return (
    <Stack
      px={6}
      pb={2}
      spacing={2}
      flexGrow={1}
      display="flex"
      flexDirection="column"
    >
      <Card>
        <CardContent sx={{alignItems: 'center'}}>
          <Typography variant="h6">{header}</Typography>
        </CardContent>
      </Card>
      <Card
        sx={{
          display: 'flex',
          flexGrow: 1,

          flexDirection: 'column'
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexGrow: 1,

            flexDirection: 'column'
          }}
        >
          <Stack display="flex" flexDirection="column" spacing={1} flexGrow={1}>
            <ReportTableHeader reportType={reportType} />
            <ReportTable />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  )
})

export default ReportPage
