import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import {
  FlexStack,
  ParentScrollStack,
  ScrollList
} from 'src/components/global/FlexStack'
import AbstractSectionCard from 'src/components/item/abstract/AbstractSectionCard'
import AbstractHeader from 'src/components/item/abstract/AbstractHeader'
import Scrollbar from 'src/components/minimal/scrollbar'
import {useNavigate, useParams} from 'react-router-dom'
import {useEffect} from 'react'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import {Alert, IconButton, Stack} from '@mui/material'

export default observer(function AbstractPage() {
  const {id} = useParams()
  const mainStore = useMainStore()
  const {abstractReport, initData, prevItem, nextItem} =
    mainStore.absractReportStore
  const {filesTree, getAllFiles} = mainStore.itemFilesStore
  const navigate = useNavigate()

  useEffect(() => {
    initData(Number(id))
  }, [initData, id])

  useEffect(() => {
    if (!filesTree.length) {
      getAllFiles()
    }
  }, [getAllFiles, filesTree.length])

  return (
    <FlexStack px={20} pb={2} spacing={2}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <IconButton
          onClick={() => navigate(`/abstract/${prevItem}`)}
          disabled={!prevItem}
        >
          <ArrowCircleLeftIcon></ArrowCircleLeftIcon>
        </IconButton>
        <IconButton
          onClick={() => navigate(`/abstract/${nextItem}`)}
          disabled={!nextItem}
        >
          <ArrowCircleRightIcon></ArrowCircleRightIcon>
        </IconButton>
      </Stack>

      <AbstractHeader />
      {abstractReport?.isInUpdating ? (
        <Alert
          severity="warning"
          sx={{display: 'flex', justifyContent: 'center'}}
        >
          {' '}
          This file is under Review
        </Alert>
      ) : (
        <ParentScrollStack>
          <ScrollList>
            <Scrollbar>
              <FlexStack spacing={1}>
                {abstractReport?.sections?.map(s => (
                  <AbstractSectionCard section={s} key={s.name} />
                ))}
              </FlexStack>
            </Scrollbar>
          </ScrollList>
        </ParentScrollStack>
      )}
    </FlexStack>
  )
})
