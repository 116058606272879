import {observer} from 'mobx-react-lite'
import ProjectTabs from 'src/components/project/ProjectTabs'
import ProjectHeader from 'src/components/project/ProjectHeader'
import {FlexStack} from '../global/FlexStack'
import {useMainStore} from 'src/contexts/Main'
import {useEffect} from 'react'
import {useParams} from 'react-router-dom'

export default observer(function ProjectPage() {
  const {id} = useParams()

  const {projectStore} = useMainStore()

  useEffect(() => {
    projectStore.getSelectedProject(Number(id))
  }, [projectStore, id])

  return (
    <FlexStack px={5} pb={2}>
      <ProjectHeader />
      <ProjectTabs />
    </FlexStack>
  )
})
