import {action, makeObservable, observable} from 'mobx'
import BaseModel from 'src/entities/BaseModel'

export class File extends BaseModel {
  path: string

  constructor(file?: File) {
    super(file)
    this.path = file?.path ?? ''
  }

  get fileName() {
    return this.path.slice(this.path.lastIndexOf('/') + 1)
  }
}

export default class Attachment extends BaseModel {
  file: File
  isDownloading: boolean

  constructor(attachment: Attachment) {
    super(attachment)
    makeObservable(this, {isDownloading: observable, setIsDownloading: action})
    this.file = new File(attachment.file)
    this.isDownloading = attachment.isDownloading
  }

  setIsDownloading = (value: boolean) => {
    this.isDownloading = value
  }
}
