import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {CircularProgress, Stack, Tooltip, IconButton} from '@mui/material'
// import SearchIcon from '@mui/icons-material/Search'
import {ReactComponent as CollapseAllIcon} from 'src/images/collapse_all.svg'
import {ReactComponent as UnfoldMoreIcon} from 'src/images/unfold_more.svg'
import {useMainStore} from 'src/contexts/Main'
import FilesTree from 'src/components/itemsFiles/FilesTree'
import FileDocView from 'src/components/itemsFiles/FileDocView'
import {
  FlexCard,
  FlexCardContent,
  FlexStack
} from 'src/components/global/FlexStack'
import useLocalStorage from 'src/hooks/useLocalStorage'

export default observer(function FilesPage() {
  const [loading, setLoading] = useState(false)
  const {setJumpToAnnotation} = useMainStore().itemFilesStore
  // eslint-disable-next-line
  const [annotation, setAnnotation] = useLocalStorage('annotation', '')

  const {uniqueId} = useParams()

  const {filesTree, getAllFiles, isAllExpanded, toggleExpanedAll, jumpToFile} =
    useMainStore().itemFilesStore

  useEffect(() => {
    setJumpToAnnotation(
      annotation.substring(1, annotation.length - 1).split(',')
    )
    localStorage.removeItem('annotation')
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!filesTree.length) {
      setLoading(true)
      getAllFiles().finally(() => setLoading(false))
    }
  }, [getAllFiles, filesTree.length])

  useEffect(() => {
    if (uniqueId && !!filesTree.length) {
      jumpToFile(uniqueId)
    }
  }, [jumpToFile, uniqueId, filesTree.length])

  return (
    <Stack height={'100%'}>
      {loading ? (
        <CircularProgress
          size={60}
          sx={{position: 'absolute', marginX: '40vw', marginY: '30vh'}}
        />
      ) : (
        <>
          {filesTree.length > 0 && (
            <Stack flexGrow={1} flexDirection={'row'} direction={'row'} gap={1}>
              {/* <Stack spacing={1}>
                    <TextField
                      size="small"
                      sx={{minWidth: 272}}
                      variant="outlined"
                      value={''}
                      onChange={e => {
                        // .setSearchText(e.target.value)
                      }}
                      placeholder="Search"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                    <Typography
                      pl={3}
                      variant="caption"
                      color={theme => theme.palette.grey[600]}
                    >
                      Select documents / folders you want to search
                    </Typography>
                  </Stack> */}
              {/* </Stack> */}
              <FlexCard sx={{maxWidth: '35%'}}>
                <FlexCardContent>
                  <FlexStack minWidth={380}>
                    <Tooltip
                      title={isAllExpanded ? 'Collapse All' : 'Expand All'}
                    >
                      <IconButton
                        sx={{alignSelf: 'self-start', ml: 0.5, width: '25px'}}
                        onClick={toggleExpanedAll}
                      >
                        {isAllExpanded ? (
                          <CollapseAllIcon />
                        ) : (
                          <UnfoldMoreIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                    <FilesTree />
                  </FlexStack>
                </FlexCardContent>
              </FlexCard>
              <FileDocView />
            </Stack>
          )}
        </>
      )}
    </Stack>
  )
})
