import {makeAutoObservable, runInAction} from 'mobx'
import AbstractReport from 'src/entities/AbstractReport'
import LoginStore from 'src/store/LoginStore'
import config from 'src/config'

export default class AbsractReportStore {
  abstractReport?: AbstractReport
  prevItem?: number
  nextItem?: number

  constructor(readonly loginStore: LoginStore) {
    makeAutoObservable(this)
  }

  setAbstractReport = (value?: AbstractReport) => {
    this.abstractReport = value
  }

  initData = async (itemId: number) => {
    this.loginStore
      .fetchWithUser(`${config.apiUrl}/items/${itemId}/getPrevNext`)
      .then((data: {prev?: number; next?: number}) => {
        runInAction(() => {
          this.prevItem = data.prev
          this.nextItem = data.next
        })
      })

    return this.loginStore
      .fetchWithUser(`${config.apiUrl}/items/${itemId}/getAbstractModel`)
      .then((data: AbstractReport) => {
        runInAction(() => {
          this.setAbstractReport(data)
        })
      })
  }
}
