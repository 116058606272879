import {useCallback, useEffect, useState} from 'react'
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemText,
  Typography
} from '@mui/material'
import {Stack} from '@mui/system'
import {Upload} from '../../minimal/upload'
import UploadLink from './UploadLink'
import {
  getCurrentRoot,
  getDirectories,
  getDuplicate,
  getFiles,
  MyFile,
  MyFolder,
  ROOT_NAME,
  updateRoot
} from 'src/entities/file'
import FolderBreadcrumbs from './FolderBreadcrumbs'
import UploadProgress from './UploadProgress'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import WarninigModal from 'src/components/global/WarningModal'
import config from 'src/config'

interface FirstUploadDialogProps {
  open: boolean
  onClose: VoidFunction
  curretFolder: string
  uploadMC?: boolean
}

const initfolder: MyFolder = {
  path: '',
  name: ROOT_NAME,
  children: []
}

export default observer(function UploadDialog({
  open,
  onClose,
  curretFolder,
  uploadMC
}: FirstUploadDialogProps) {
  const {afterUploadClientFile, upload, docFolders, selectedProject} =
    useMainStore().projectStore

  const [root, setRoot] = useState<MyFolder>({...initfolder})
  const [currentPath, setCurrentPath] = useState<string>(ROOT_NAME + '/')
  const [openUploadMessage, setOpenUploadMessage] = useState<boolean>(false)
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false)
  const [duplicateFiles, setduplicateFiles] = useState<string[]>([])
  const [directories, setDirectories] = useState<string[]>([])
  const [progress, setProgress] = useState<{
    filesCount: number
    filesSent: number
  }>({filesCount: -1, filesSent: 0})

  const onRemoveFile = (inputFile: MyFile | MyFolder) => {
    setRoot(p => ({...updateRoot(p, currentPath, [], [inputFile], 'remove')}))
  }

  const onRemoveAllFiles = () => {
    setRoot({...initfolder, children: []})
    setCurrentPath(ROOT_NAME + '/')
  }

  const onUpload = () => {
    onOpenMessage(true)
  }

  const onCloseDialog = useCallback(() => {
    onClose()
  }, [onClose])

  const validateFiles = (fileNames: string[]): boolean => {
    const prevfileData = getFiles(docFolders, ROOT_NAME + '/')
    const duplicate = getDuplicate(fileNames, prevfileData.fileNames)
    setduplicateFiles(duplicate)
    if (duplicate.length) {
      return false
    }
    return true
  }

  const onOpenMessage = (uploadFiles: boolean) => {
    if (uploadFiles) {
      const fileData = getFiles(root, curretFolder)
      if (!validateFiles(fileData.fileNames)) return
      setDirectories(
        getDirectories(
          fileData.fileNames,
          curretFolder.replaceAll(ROOT_NAME + '/', '')
        )
      )
      setOpenUploadMessage(true)
      setProgress({filesSent: 0, filesCount: fileData.files.length})
      fileData.files.map(async f => {
        const r = await upload(f, !!uploadMC)
        if (r) {
          setProgress(p => ({...p, filesSent: p.filesSent + 1}))
        }
      })
    } else {
      setOpenUploadMessage(true)
      setProgress({filesSent: 0, filesCount: 0})
    }
  }
  const onCloseMessage = () => {
    if (root.children.length) setOpenWarningDialog(true)
    else onCloseDialog()
  }
  const onCloseWarninigdialog = (stay: boolean) => {
    if (!stay) onCloseDialog()
    else setOpenWarningDialog(false)
  }

  const onDropMultiFile = useCallback(
    (acceptedFiles: File[]) => {
      setRoot(p => ({...updateRoot(p, currentPath, acceptedFiles, [], 'add')}))
    },
    [currentPath]
  )

  const afterUpload = useCallback(async () => {
    await afterUploadClientFile(directories)
    onCloseDialog()
  }, [onCloseDialog, afterUploadClientFile, directories])

  useEffect(() => {
    if (progress.filesSent === progress.filesCount) {
      if (progress.filesSent) {
        afterUpload()
      } else
        setTimeout(() => {
          onCloseDialog()
        }, 1000)
    }
  }, [progress, afterUpload, onCloseDialog])

  useEffect(() => {
    if (open) {
      setRoot({...initfolder, children: []})
      setCurrentPath(ROOT_NAME + '/')
      setOpenUploadMessage(false)
      setOpenWarningDialog(false)
      setProgress({filesCount: -1, filesSent: 0})
    }
  }, [open])

  const files = getCurrentRoot(currentPath, root).children

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={onCloseMessage}>
        {!openUploadMessage && (
          <DialogTitle sx={{p: theme => theme.spacing(3, 3, 2, 3)}}>
            <Stack direction="row" spacing={2} alignItems={'baseline'}>
              <Typography variant="h4">Share Files</Typography>
              <FolderBreadcrumbs path={curretFolder}></FolderBreadcrumbs>
            </Stack>
          </DialogTitle>
        )}

        <DialogContent sx={{pt: 1, pb: 3, border: 'none'}}>
          {duplicateFiles.length ? (
            <Alert
              severity="warning"
              onClose={() => {
                setduplicateFiles([])
              }}
            >
              <AlertTitle> Duplicate Files </AlertTitle>

              <List>
                {duplicateFiles.map(file => (
                  <ListItemText key={file}>{file}</ListItemText>
                ))}
              </List>
            </Alert>
          ) : (
            <></>
          )}
          {!openUploadMessage ? (
            <Stack spacing={1}>
              <Card>
                <CardContent>
                  <FolderBreadcrumbs
                    path={currentPath}
                    onChoose={p => setCurrentPath(p)}
                    rootPath={'Current Upload'}
                  ></FolderBreadcrumbs>
                  <Upload
                    accept={config.acceptFile}
                    multiple
                    thumbnail={false}
                    files={files}
                    onDrop={onDropMultiFile}
                    onRemove={onRemoveFile}
                    onRemoveAll={onRemoveAllFiles}
                    onUpload={() => {
                      onUpload()
                    }}
                    onClickFolder={folder => {
                      setCurrentPath(folder.path + folder.name + '/')
                    }}
                  />
                </CardContent>
              </Card>
              {!uploadMC &&
                (!selectedProject?.siteLink ||
                  !selectedProject?.siteLinkComment) && (
                  <>
                    {' '}
                    <Divider
                      sx={{
                        my: 1.5,
                        color: 'text.disabled',
                        '&::before, ::after': {
                          borderTopStyle: 'dashed',
                          top: 0
                        }
                      }}
                    >
                      OR
                    </Divider>
                    <Card>
                      <CardContent>
                        <UploadLink
                          onSubmit={() => onOpenMessage(false)}
                        ></UploadLink>
                      </CardContent>
                    </Card>
                  </>
                )}
            </Stack>
          ) : (
            <Stack>
              <UploadProgress
                filesCount={progress.filesCount}
                filesSent={progress.filesSent}
              ></UploadProgress>
            </Stack>
          )}
        </DialogContent>
      </Dialog>
      <WarninigModal
        open={openWarningDialog}
        title={`Are you sure you want to leave this page?`}
        text="You have files that are not uploaded yet."
        cancelText="Leave"
        onClose={(result: boolean) => {
          onCloseWarninigdialog(result)
        }}
      ></WarninigModal>
    </>
  )
})
