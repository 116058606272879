import {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Box, CircularProgress, Stack, Typography} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {styled} from '@mui/material/styles'
import downloadBlob from 'src/utils/downloadFile'
import Chart, {useChart} from 'src/components/minimal/chart'
import {ReactComponent as DownloadIcon} from 'src/images/ic_download.svg'
import {CustomSmallSelect} from 'src/components/minimal/custom-input'
import {FlexCard, FlexCardContent} from 'src/components/global/FlexStack'
import useResponsive from 'src/hooks/useResponsive'

const series = [
  {
    data: [17, 16, 9, 10, 16, 8, 13, 16]
  }
]

const StyledChart = styled('div')(() => {
  const isXXLDesktop = useResponsive('up', 'xxl')
  return {
    '.apexcharts-tooltip-text-y-value': {
      color: '#637381'
    },
    height: isXXLDesktop ? '72%' : undefined
  }
})

export default observer(function ExpirationDateChart() {
  const [isDownloading, setIsDownloading] = useState(false)

  const chartOptions = useChart({
    plotOptions: {
      bar: {
        columnWidth: '16%'
      }
    },
    stroke: {
      show: false
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Des'
      ]
    },
    yaxis: {
      title: {text: 'Number of Tenants'}
    },
    tooltip: {
      marker: {show: false},
      y: {
        formatter: (value: number) => `Number Of Contracts: ${value}<br/>
        BARRE 3<br/> BASKIN ROBBINS<br/> BEVMO<br/> DOWNEAST HOME & CLOTHING<br/> ESTRELLA NAILS<br/> FARMERS INSURANCE<br/>
        GEMINI HAIR STUDIO<br/> JERSEY MIKE'S SUBS<br/>KIRKLAND'S STORES`,
        title: {
          formatter: () => ''
        }
      }
    }
  })

  const onExport = () => {
    setIsDownloading(true)
    fetch('/files/Expiration Date Report.xlsx')
      .then(response => response.blob())
      .then(blob => downloadBlob('Expiration Date Report.xlsx', blob))
      .finally(() => setIsDownloading(false))
  }

  const isXXLDesktop = useResponsive('up', 'xxl')

  return (
    <FlexCard sx={{pt: 0, flexGrow: 1}}>
      <FlexCardContent sx={{height: '100%', ':last-child': {pb: 0}}}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Expiration Date</Typography>
          <LoadingButton
            onClick={onExport}
            loading={isDownloading}
            loadingIndicator={<CircularProgress size={24} color="primary" />}
            loadingPosition="center"
            color="inherit"
            startIcon={<DownloadIcon />}
          >
            <span>Export</span>
          </LoadingButton>
        </Stack>
        <Box display="flex" justifyContent="end">
          <CustomSmallSelect value={'2023'}>
            <>
              <option value={'2022'}>{'2022'}</option>
              <option value={'2023'}>{'2023'}</option>
            </>
          </CustomSmallSelect>
        </Box>
        <StyledChart>
          <Chart
            type="bar"
            series={series}
            options={chartOptions}
            height={isXXLDesktop ? '90%' : '75%'}
          />
        </StyledChart>
      </FlexCardContent>
    </FlexCard>
  )
})
