import {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {
  Card,
  CardContent,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Divider
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {FlexCard, FlexCardContent, FlexStack} from '../global/FlexStack'
import BillingTable from 'src/components/billing/BillingTable'
import SelectProjects from 'src/components/global/SelectProjects'
import {useMainStore} from 'src/contexts/Main'
import {LoadingButton} from '@mui/lab'
import {fCurrency} from 'src/utils/formatNumber'

export default observer(function BillingPage() {
  const {billingStore} = useMainStore()
  const [isDownloading, setIsDownloading] = useState(false)

  const onSelectProjects = (selected: number[]) => {
    billingStore.setSelectedProjects(selected)
  }

  const downloadAll = async () => {
    for (let i = 0; i < billingStore.selectedItems.length; i++) {
      const item = billingStore.selectedItems[i]
      await billingStore.downloadAttachments(item)
    }
  }

  useEffect(() => {
    billingStore.getBillings()
  }, [billingStore])

  return (
    <FlexStack px={6} pb={2} spacing={2}>
      <Card>
        <CardContent>
          <Typography variant="h6">Invoices</Typography>
        </CardContent>
      </Card>
      <FlexCard>
        <FlexCardContent>
          <FlexStack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <SelectProjects
                selected={billingStore.selectedProjects}
                onSelect={onSelectProjects}
                isSmall={true}
              />
              <Stack spacing={1} alignItems={'end'}>
                <Stack direction="row" gap={1}>
                  <TextField
                    size="small"
                    sx={{minWidth: 350}}
                    id="outlined-multiline-flexible"
                    variant="outlined"
                    value={billingStore.searchText}
                    onChange={e => {
                      billingStore.setSearchText(e.target.value)
                    }}
                    placeholder="Search by Project Name or Invoice #"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  <LoadingButton
                    sx={{alignSelf: 'start'}}
                    disabled={
                      isDownloading ||
                      !billingStore.filteredByProject?.find(
                        i => i.isSelected === true
                      )
                    }
                    size="medium"
                    variant="contained"
                    loading={isDownloading}
                    onClick={async () => {
                      setIsDownloading(true)
                      downloadAll().finally(() => setIsDownloading(false))
                    }}
                  >
                    Download
                  </LoadingButton>
                </Stack>
                <Typography variant="subtitle1">
                  Open Balance:
                  <span style={{color: '#FF4842'}}>{` ${fCurrency(
                    billingStore.TotalBalance ?? 0
                  )}`}</span>
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            <BillingTable />
          </FlexStack>
        </FlexCardContent>
      </FlexCard>
    </FlexStack>
  )
})
