import {observer} from 'mobx-react-lite'
import {Typography} from '@mui/material'
import useTable from 'src/components/minimal/table/useTable'
import {useMainStore} from 'src/contexts/Main'
import FileListView from './filesView/FileListView'
import {getCurrentRoot} from 'src/entities/file'
import {FlexStack} from 'src/components/global/FlexStack'
import {useEffect} from 'react'

const FileProject = observer(function FileList() {
  const {projectStore} = useMainStore()

  const table = useTable({
    defaultOrderBy: 'name',
    defaultRowsPerPage: 50
  })
  const setPage = table.setPage

  const data = getCurrentRoot(
    projectStore.currentdocFolder,
    projectStore.docFolders
  )

  const dataInPage = !projectStore.searchOnCurrentdocFolder
    ? [...data.children]
    : data.children.filter(x =>
        x.name
          .toLowerCase()
          .includes(projectStore.searchOnCurrentdocFolder.toLowerCase())
      )
  useEffect(() => {
    setPage(0)
  }, [projectStore.currentdocFolder, projectStore.searchOnCurrentdocFolder, setPage])

  return (
    <FlexStack py={1}>
      <Typography variant="h6">Files</Typography>
      <FileListView
        table={table}
        tableData={data.children}
        dataFiltered={dataInPage}
        onClickRow={p => projectStore.setSelectedDocFolder(p)}
        onClickDownload={row =>
          projectStore.downloaddDocFile(row.path + row.name, row.name)
        }
      />
    </FlexStack>
  )
})

export default FileProject
