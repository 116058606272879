// @mui
import {Theme} from '@mui/material/styles'
import {
  Box,
  Switch,
  SxProps,
  TablePagination,
  FormControlLabel,
  TablePaginationProps
} from '@mui/material'
//

// ----------------------------------------------------------------------

type Props = {
  dense?: boolean
  onChangeDense?: (event: React.ChangeEvent<HTMLInputElement>) => void
  sx?: SxProps<Theme>
}

export default function TablePaginationCustom({
  dense,
  onChangeDense,
  rowsPerPageOptions = [10, 25, 50, 100],
  sx,
  ...other
}: Props & TablePaginationProps) {
  return (
    <Box sx={{position: 'relative', ...sx}}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        {...other}
      />

      {onChangeDense && (
        <FormControlLabel
          label="Compact"
          control={<Switch checked={dense} onChange={onChangeDense} />}
          sx={{
            pl: 2,
            py: 1.5,
            top: 0,
            position: {
              sm: 'absolute'
            }
          }}
        />
      )}
    </Box>
  )
}
