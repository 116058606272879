import {observer} from 'mobx-react-lite'
import {TableCell, TableRow, Checkbox} from '@mui/material'
import Report from 'src/entities/Report'
interface ReportRowProps {
  item: Report
}

const ReportRow = observer(function ReportRow({item}: ReportRowProps) {
  return (
    <TableRow onClick={() => item.toggleSelection()} selected={item.isSelected}>
      <TableCell padding="checkbox">
        <Checkbox checked={item.isSelected} />
      </TableCell>
      <TableCell>{item.lpid}</TableCell>
      <TableCell>{item.projectName}</TableCell>
      <TableCell>{item.propertyName}</TableCell>
      {/* <TableCell>
        <Label variant="soft" color={item.getColorByStatus()}>
          {ItemStatus[item.status || 0]}
        </Label>
      </TableCell> */}
      <TableCell>{item.tenant}</TableCell>
    </TableRow>
  )
})

export default ReportRow
