import {makeObservable, observable} from 'mobx'
import Attachment from 'src/entities/Attachment'
import BaseModel from 'src/entities/BaseModel'

export default class Response extends BaseModel {
  content: string
  attachments?: Attachment[]

  constructor(response?: Response) {
    super(response)
    makeObservable(this, {attachments: observable})
    this.content = response?.content ?? ''
    this.attachments = response?.attachments?.map(a => new Attachment(a))
  }
}
