// @mui
import {Stack, Box} from '@mui/material'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
//
import NavList from './NavList'
import {ReactComponent as Dashboard} from '../../images/Dashboard.svg'
import {ReactComponent as Reports} from '../../images/Reports.svg'
import {ReactComponent as Billing} from '../../images/Billing.svg'
import {NAV} from 'src/settings/config-setting'

import {memo} from 'react'
// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Dashboard',
    path: 'dashboard',
    icon: (fill: string) => <Dashboard fill={fill} />
  },
  {
    title: 'Reports',
    path: '#',
    icon: (fill: string) => <Reports fill={fill} />,
    children: [
      {title: 'Property Data Points', path: 'reports/PropertyDataPoints'},
      // {title: '12 Months Rent', path: 'reports/12MonthsRent'},
      {title: 'Rent Report', path: 'reports/RentReport'}
    ]
  },
  {
    title: 'Invoices',
    path: 'invoices',
    icon: (fill: string) => <Billing fill={fill} />
  },
  {
    title: 'Documents',
    path: 'documents',
    icon: (fill: string) => <TextSnippetOutlinedIcon fill={fill} />
  }
]

function NavSectionVertical() {
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: {lg: 0},
        width: {lg: NAV.W_DASHBOARD_MINI}
      }}
    >
      <Stack
        spacing={5}
        alignItems="center"
        sx={{
          top: 100,
          px: 0.3,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI
        }}
      >
        <Stack width={'100%'}>
          {navConfig.map(item => {
            return (
              <NavList
                key={item.title + item.path}
                data={{
                  title: item.title,
                  path: item.path,
                  children: item.children,
                  icon: item.icon
                }}
                depth={1}
                hasChild={!!item.children}
              />
            )
          })}
        </Stack>
      </Stack>
    </Box>
  )
}

export default memo(NavSectionVertical)
