import {
  Box,
  BoxProps,
  Card,
  CardContent,
  CardContentProps,
  CardProps,
  Stack,
  Typography
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import AbstractRent from './AbstractRent'
import React, {forwardRef} from 'react'
import {
  Breakpoint,
  DataPoint,
  DataPointType,
  Rent
} from '../../../entities/AbstractReport'
import {useMainStore} from 'src/contexts/Main'
import Markdown from 'src/components/minimal/markdown/Markdown'
import AbstractBreakpoint from './AbstractBreakpoint'
import useLocalStorage from 'src/hooks/useLocalStorage'

interface AbstractViewerProps {
  dataPoint: DataPoint
}

interface AbstractSpecialDPProps {
  rents?: Rent[]
  breakpoints?: Breakpoint[]
}

const CardDP = forwardRef<HTMLButtonElement, CardProps>(
  ({children, sx, ...other}, ref) => (
    <Card sx={{boxShadow: 'none'}}>{children}</Card>
  )
)

const CardContentDP = forwardRef<HTMLButtonElement, CardContentProps>(
  ({children, sx, ...other}, ref) => (
    <CardContent
      sx={{
        border: '1px dashed #919EAB3D',
        boxShadow: 'none',
        borderRadius: 2
      }}
    >
      {children}
    </CardContent>
  )
)

const Navigate = forwardRef<HTMLDivElement, BoxProps>(
  ({children, ...other}, ref) => {
    // eslint-disable-next-line
    const [annotation, setAnnotation] = useLocalStorage('annotation', '')

    return (
      <Box
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          if (e.target instanceof HTMLAnchorElement) {
            e.preventDefault()
            const fileUniqueId = e.target.getAttribute('data-file-unique-id')
            const localAnnotation = e.target.getAttribute('data-annotation-ids')

            if (fileUniqueId) {
              if (localAnnotation) setAnnotation(localAnnotation)
              window.open(`/documents/${fileUniqueId}`, '_blank')
            }
          }
        }}
        {...other}
      >
        {children}
      </Box>
    )
  }
)

export default observer(function AbstractViewer({
  dataPoint
}: AbstractViewerProps) {
  const {abstractReport} = useMainStore().absractReportStore

  const renderDPByType = () => {
    return dataPoint.dataPointType === DataPointType.None ? (
      <RegularDP dataPoint={dataPoint}></RegularDP>
    ) : dataPoint.dataPointType === DataPointType.RentChart ? (
      <RentDP rents={abstractReport?.rents}></RentDP>
    ) : dataPoint.dataPointType === DataPointType.BreakpointChart ? (
      <BreakpointDP breakpoints={abstractReport?.breakpoints}></BreakpointDP>
    ) : (
      <></>
    )
  }

  return <>{renderDPByType()}</>
})

const DPValue = ({dataPoint}: AbstractViewerProps) => {
  return (
    <Navigate>
      <Stack
        sx={{
          fontSize: theme => theme.typography.subtitle2.fontSize,
          fontFamily: theme => theme.typography.subtitle2.fontFamily,
          a: {
            color: '#1890FF !important',
            fontSize: theme => theme.typography.caption.fontSize,
            cursor: 'pointer'
          }
        }}
        dangerouslySetInnerHTML={{__html: dataPoint.htmlValue ?? ''}}
      />
    </Navigate>
  )
}

const SpecialDPName = ({header}: {header: string}) => {
  return (
    <Navigate>
      <Markdown
        key={header}
        children={header ?? ''}
        sx={{
          fontSize: theme => theme.typography.subtitle1.fontSize,
          fontFamily: theme => theme.typography.subtitle1.fontFamily,
          '.MuiLink-root': {
            color: '#1890FF !important',
            fontSize: theme =>
              theme.typography.caption.fontSize + ' !important',
            fontWeight: theme =>
              theme.typography.body2.fontWeight + ' !important'
          },
          '.MuiTypography-root': {
            fontSize: theme => theme.typography.subtitle1.fontSize,
            fontWeight: theme => theme.typography.subtitle1.fontWeight,
            fontFamily: theme => theme.typography.subtitle1.fontFamily
          }
        }}
      />
    </Navigate>
  )
}

const DPName = ({name}: {name: string}) => {
  return (
    <Typography sx={{whiteSpace: 'pre-line'}} variant="subtitle1">
      {name}
    </Typography>
  )
}

const RegularDP = ({dataPoint}: AbstractViewerProps) => {
  return (
    <CardDP>
      <CardContentDP>
        <Stack direction={'row'} gap={1} width={'100%'}>
          <Stack width={'30%'}>
            <DPName name={dataPoint.name} />
          </Stack>
          <Stack width={'70%'}>
            <DPValue dataPoint={dataPoint} />
            {dataPoint.children && (
              <Stack gap={1}>
                {dataPoint.children &&
                  dataPoint.children.map(ch => (
                    <Stack key={ch.name}>
                      <Typography variant="subtitle2">{ch.name}</Typography>
                      <DPValue dataPoint={ch} />
                    </Stack>
                  ))}
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardContentDP>
    </CardDP>
  )
}

const RentDP = ({rents}: AbstractSpecialDPProps) => {
  return (
    <Stack gap={1} width={'100%'}>
      {rents &&
        rents.map(rent => (
          <CardDP key={rent.calcName}>
            <CardContentDP>
              <Stack key={rent.header} direction={'row'} width={'100%'} gap={2}>
                <Stack width={'28%'}>
                  <SpecialDPName header={rent.header} />
                </Stack>
                <Stack width={'70%'}>
                  <AbstractRent rent={rent} />
                </Stack>
              </Stack>
            </CardContentDP>
          </CardDP>
        ))}
    </Stack>
  )
}

const BreakpointDP = ({breakpoints}: AbstractSpecialDPProps) => {
  return (
    <Stack gap={2} width={'100%'}>
      {breakpoints &&
        breakpoints.map(breakpoint => (
          <CardDP key={breakpoint.calcName}>
            <CardContentDP>
              <Stack
                key={breakpoint.header}
                direction={'row'}
                gap={2}
                width={'100%'}
                justifyContent={'space-between'}
              >
                <Stack width={'28%'}>
                  <SpecialDPName header={breakpoint.header} />
                </Stack>
                <Stack width={'70%'}>
                  <AbstractBreakpoint breakpoint={breakpoint} />
                </Stack>
              </Stack>
            </CardContentDP>
          </CardDP>
        ))}
    </Stack>
  )
}
