export default class BaseModel {
  id?: number = 0

  createdBy?: number

  createdAt?: Date

  updateBy?: number

  updatedAt?: Date

  constructor(baseModel?: BaseModel) {
    this.id = baseModel?.id
    this.createdBy = baseModel?.createdBy
    this.createdAt = new Date(baseModel?.createdAt ?? '')
    this.updateBy = baseModel?.updateBy
    this.updatedAt = new Date(baseModel?.updatedAt ?? '')
  }
}
