import {Configuration} from '@azure/msal-browser'

export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_AAD_SIGNUPSIGNIN_NAME
  },
  authorities: {
    signUpSignIn: {authority: process.env.REACT_APP_AAD_SIGNUPSIGNIN_AUTHORITY}
  },
  authorityDomain:
    process.env.REACT_APP_AAD_AUTHORITY_DOMAIN || 'lprdcp.b2clogin.com'
}

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AAD_CLIENT_ID || '',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: process.env.REACT_APP_AAD_REDIRECT_URL || '',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line
      loggerCallback: (level: any, message: any, containsPii: any) => {
        // eslint-disable-next-line
        console.log('[msal]', level, message)
      },
      piiLoggingEnabled: false
    }
  }
}

export const aadConfig = {
  readScope: `https://${process.env.REACT_APP_AAD_PUBLISHER_DOMAIN}/${msalConfig.auth.clientId}/User.Read`
}

const config = {
  apiUrl: process.env.REACT_APP_API || '',
  apiSignalRUrl: process.env.REACT_APP_API_SIGNALR_URL || '',
  isProduction: process.env.REACT_APP_BRANCH_NAME === 'main',
  isMain:
    process.env.REACT_APP_URL?.includes('qa') ||
    process.env.REACT_APP_URL?.includes('master'),
  isDevelopment: process.env.NODE_ENV === 'development',
  logRocketAppId: process.env.REACT_APP_LOGROCKET_APPID || 'osegvt/lprd-cp',
  viewerLicenseKey: process.env.REACT_APP_VIEWER_LICENSE_KEY || '',
  acceptFile: {
    'application/pdf': ['.pdf'],
    'text/plain': ['.txt'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx'
    ],
    'application/vnd.ms-excel': ['.xls'],
    'application/zip': ['.zip'],
    'application/x-7z-compressed': ['.7z'],
    'application/vnd.rar': ['.rar'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx'
    ],
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'application/vnd.ms-outlook': ['.msg']
  }
}

export default config
