import {makeAutoObservable, reaction, runInAction} from 'mobx'
import config from '../config'
import Report from 'src/entities/Report'
import {downloadFile} from 'src/utils/downloadFile'
import LoginStore from 'src/store/LoginStore'
import {fDate} from '../utils/formatTime'
import {ItemStatus} from 'src/entities/Item'
import Scope from 'src/entities/Scope'
import Section from 'src/entities/Section'

export default class ReportStore {
  currentScope?: Scope
  items: Report[] = []

  //selectedStatuses: ItemStatus[] = [ItemStatus.Delivered]

  selectedProjects: number[] = []

  searchText = ''

  constructor(readonly loginStore: LoginStore) {
    this.currentScope = undefined
    makeAutoObservable(this)

    reaction(
      () => this.selectedReports,
      () => {
        this.getDatapoints(this.selectedReports?.map(x => x.scopeId))
      }
    )
  }

  setSearchText = (value: string) => {
    this.searchText = value
  }

  // setSelectedStutuses(selected: ItemStatus[]) {
  //   this.selectedStatuses = selected.map(x => Number(x))
  // }

  setSelectedProjects(selected: number[]) {
    this.selectedProjects = selected
  }

  get selectedReports() {
    return this.filteredByProject?.filter(r => r.isSelected)
  }

  get filteredByProject() {
    const search = this.searchText?.toLocaleLowerCase()

    return this.items?.filter(
      i =>
        i.status === ItemStatus.Completed &&
        !i.isInUpdating &&
        this.selectedProjects.includes(i.projectId) &&
        (!this.searchText ||
          i.projectName?.toLocaleLowerCase()?.includes(search) ||
          i.propertyName?.toLocaleLowerCase()?.includes(search) ||
          i.lpid.toLocaleLowerCase().includes(search) ||
          i.tenant.toLocaleLowerCase().includes(search))
    )
  }

  toggleAllSelection(checked: boolean) {
    this.filteredByProject?.forEach(r => (r.isSelected = checked))
  }

  getDatapoints(scopeIds: number[]) {
    const distinct = scopeIds.filter(
      (value, index, self) => self.indexOf(value) === index
    )
    if (!scopeIds.length) return
    this.loginStore
      .fetchWithUser(
        `${config.apiUrl}/datapoints?scopeIds=${distinct
          .filter(x => x !== undefined)
          .join('&scopeIds=')}`
      )
      .then((data: Section[]) => {
        const selected: string[] = []
        this.currentScope?.sections.forEach(s => {
          selected.push(
            ...s.datapoints.filter(d => d.isSelected).map(dp => dp.name)
          )
        })

        runInAction(() => (this.currentScope = new Scope(data)))

        this.currentScope?.sections.forEach(s => {
          s.datapoints
            .filter(d => selected.includes(d.name))
            .forEach(dp => dp.toggleSelection(true))
        })
      })
  }

  async getItemsByCurrentClient() {
    return this.loginStore
      .fetchWithUser(`${config.apiUrl}/items/ByCurrentClient`)
      .then((data: Report[]) => {
        runInAction(() => {
          this.items = data.map(x => {
            const newLocal = {...x, isSelected: true} as Report
            return new Report(newLocal)
          })
          const uniqueProjectIds = Array.from(
            new Set(this.items.map(item => item.projectId))
          )
          if (uniqueProjectIds.length === 1)
            this.selectedProjects = uniqueProjectIds
        })
      })
  }

  async generate(datapoints: string[]) {
    const itemsIds = this.selectedReports?.map(x => x.id) || []

    const options = {
      method: 'POST',
      body: JSON.stringify({
        itemsIds,
        datapoints
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    return this.loginStore
      .fetchWithUser(
        `${config.apiUrl}/items/GeneratePropertyReport`,
        false,
        undefined,
        options
      )
      .then(response => {
        downloadFile(
          response,
          `Property Data Points-${fDate(new Date(), 'MM.dd.yyyy')}.xlsx`
        )
      })
  }

  async generateRentReport() {
    const itemIds = this.selectedReports?.map(x => x.id) || []

    return this.loginStore
      .fetchWithUser(
        `${config.apiUrl}/items/generateRentReport?itemIds=${itemIds.join(
          '&itemIds='
        )}`,
        false
      )
      .then(response => {
        downloadFile(
          response,
          `12 Months Rent-${fDate(new Date(), 'MM.dd.yyyy')}.xlsx`
        )
      })
  }
  async generatePropertyRentRollReport() {
    const itemIds = this.selectedReports?.map(x => x.id) || []
    return this.loginStore
      .fetchWithUser(
        `${
          config.apiUrl
        }/items/GeneratePropertyRentRollReport?itemIds=${itemIds.join(
          '&itemIds='
        )}`,
        false
      )
      .then(response => {
        downloadFile(
          response,
          `Rent Report-${fDate(new Date(), 'MM.dd.yyyy')}.xlsx`
        )
      })
  }
}
