import {Stack} from '@mui/material'

interface TruncatedMarkdownProps {
  source: string
  maxLength: number
}
const TruncatedMarkdown = ({source, maxLength}: TruncatedMarkdownProps) => {
  return (
    <Stack
      sx={{
        maxWidth: maxLength,
        //overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '& p': {margin: 0, overflow: 'hidden'},
        '& ul': {pl: 5}
      }}
      dangerouslySetInnerHTML={{__html: source}}
    />
  )
}

export default TruncatedMarkdown
