import {useCallback, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Card, Divider, Stack, Typography} from '@mui/material'
import {ReactComponent as ClockIcon} from 'src/images/ic_clock.svg'
import {ReactComponent as CheckIcon} from 'src/images/ic_check.svg'
import {useMainStore} from 'src/contexts/Main'
import {FlexCardContent, FlexStack} from 'src/components/global/FlexStack'
import DeliverableItem from 'src/components/project/deliverable/DeliverableItem'
import InProgressTable from 'src/components/project/deliverable/InProgressTable'
import DeliveredTable from 'src/components/project/deliverable/DeliveredTable'
import MCTable from 'src/components/project/deliverable/MCTable'
import StatusChart from 'src/components/project/deliverable/StatusChart'
import {useEffect} from 'react'
import Iconify from 'src/components/minimal/iconify'
import {useParams} from 'react-router-dom'

export type DelivarableType = 'In progress' | 'Completed' | 'MC'

const DeliverableTab = observer(function DeliverableTab() {
  const {selectedStatus} = useParams()

  const [selectedType, setSelectedType] = useState<DelivarableType>(
    !selectedStatus ? 'In progress' : (selectedStatus as DelivarableType)
  )

  const {
    projectStore: {
      getItems,
      selectedProject,
      inProgressCount,
      MCNewItemsCount,
      deliveredCount
    }
  } = useMainStore()

  useEffect(() => {
    if (selectedProject?.id) getItems(selectedProject.id)
  }, [selectedProject?.id, getItems])

  const onChangeTab = useCallback((tabValue: DelivarableType) => {
    setSelectedType(tabValue)
  }, [])

  return (
    <Stack
      display="flex"
      flexGrow={1}
      gap={2}
      justifyContent="space-between"
      flexDirection="row"
    >
      <Card sx={{width: '346px', display: 'flex'}}>
        <FlexCardContent
          sx={{
            alignItems: 'center',
            px: 0,
            ':last-child': {
              py: 0
            }
          }}
        >
          <Stack alignItems="center" width="100%" py={1} spacing={1}>
            <Typography variant="h6">Items Breakdown</Typography>
            <Divider sx={{width: '100%'}} />
            <StatusChart />
          </Stack>
          <FlexStack>
            <DeliverableItem
              selected={selectedType}
              type={'In progress'}
              title="In Progress"
              count={inProgressCount}
              subTitle={'Abstract'}
              icon={<ClockIcon />}
              onClick={() => onChangeTab('In progress')}
            />
            <DeliverableItem
              selected={selectedType}
              type={'Completed'}
              title="Completed"
              count={deliveredCount}
              subTitle={'Abstract'}
              icon={<CheckIcon />}
              onClick={() => onChangeTab('Completed')}
            />
            <DeliverableItem
              selected={selectedType}
              type={'MC'}
              title="Missing and Conflicting"
              count={MCNewItemsCount}
              subTitle={'New Issue'}
              icon={
                <Iconify
                  icon="eva:bell-fill"
                  sx={{
                    color: theme =>
                      MCNewItemsCount > 0
                        ? theme.palette.error.main
                        : theme.palette.grey[500]
                  }}
                />
              }
              onClick={() => onChangeTab('MC')}
            />
          </FlexStack>
        </FlexCardContent>
      </Card>

      {selectedType === 'In progress' && <InProgressTable />}
      {selectedType === 'Completed' && <DeliveredTable />}
      {selectedType === 'MC' && <MCTable />}
    </Stack>
  )
})

export default DeliverableTab
