import {observer} from 'mobx-react-lite'
import {Typography, Stack} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import {Navigate, useLocation} from 'react-router-dom'
import {StyledContent} from '../../settings/styles'
import {useMainStore} from 'src/contexts/Main'
import NotSupportedScreen from '../global/NotSupportedScreen'
import {isChrome, isEdge} from 'react-device-detect'
import useResponsive from 'src/hooks/useResponsive'

const LoginPage = observer(function LoginPage() {
  const location = useLocation()

  const {loginStore} = useMainStore()

  const isSmall = useResponsive('down', 'lg')
  const isNotSupported =
    isSmall ||
    /MSIE|Trident/.test(window.navigator.userAgent) ||
    (!isChrome && !isEdge)

  // eslint-disable-next-line
  let { from } = (location.state as any) || { from: { pathname: '/dashboard' } }
  if (from === '/') from = '/dashboard'
  if (loginStore.isAuth) return <Navigate replace to={from} />

  const onLogin = () => {
    loginStore.login()
  }

  return (
    <StyledContent>
      {isNotSupported ? (
        <NotSupportedScreen />
      ) : (
        <Stack spacing={3} justifyContent="center" alignItems="center">
          <Typography variant="h3">Log in to your LPRD Account</Typography>
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            loading={loginStore.isLoggingIn}
            onClick={onLogin}
          >
            Log In
          </LoadingButton>
          <Typography variant="body2">{loginStore.loginMessage}</Typography>
        </Stack>
      )}
    </StyledContent>
  )
})

export default LoginPage
