import {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {
  Button,
  Chip,
  Stack,
  Typography,
  Divider,
  CircularProgress
} from '@mui/material'
import Markdown from 'src/components/minimal/markdown/Markdown'
import Response from 'src/entities/Response'
import {useMainStore} from 'src/contexts/Main'
import {getFormattedDate} from 'src/utils/formatTime'
import CuiOverflowTooltip from 'src/components/custom/CuiOverflowTooltip'
import Attachment from 'src/entities/Attachment'

interface MCResponsesProps {
  clientResponses?: Response[]
}

function MCResponses({clientResponses}: MCResponsesProps) {
  return (
    <Stack spacing={1}>
      {clientResponses?.map(r => (
        <Stack key={r.id} spacing={1}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack spacing={1} maxWidth={'85%'}>
              <Markdown children={r.content} />
              {r.attachments && <MCAttachments attachments={r.attachments} />}
            </Stack>
            <Typography
              variant="body2"
              color={theme => theme.palette.grey[600]}
            >
              {getFormattedDate(r.createdAt)}
            </Typography>
          </Stack>
          <Divider />
        </Stack>
      ))}
    </Stack>
  )
}

const MCAttachments = observer(function MCAttachments({
  attachments
}: {
  attachments: Attachment[]
}) {
  const {downloadMCAttachment} = useMainStore().projectStore
  const [showAll, setShowAll] = useState(false)

  const onShowAllClick = () => {
    setShowAll(!showAll)
  }
  return (
    <>
      {attachments && (
        <Stack gap={2} direction="row" flexWrap={'wrap'}>
          {(showAll ? attachments : attachments.slice(0, 4)).map((a, i) => (
            <Stack direction="row" gap={1} alignItems={'center'} key={a.id}>
              <Chip
                variant="outlined"
                clickable
                disabled={a.isDownloading}
                onClick={async () => await downloadMCAttachment(a)}
                label={
                  <CuiOverflowTooltip variant="body2" value={a.file.fileName} />
                }
                sx={{maxWidth: 110, borderRadius: '4px'}}
              />
              {a.isDownloading && <CircularProgress size={25} />}
            </Stack>
          ))}
          {!showAll && attachments.length > 4 && (
            <Button onClick={onShowAllClick}>{`+${
              attachments.length - 4
            }`}</Button>
          )}
        </Stack>
      )}
    </>
  )
})

export default observer(MCResponses)
