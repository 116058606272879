import {useDropzone} from 'react-dropzone'
// @mui
import {
  Box,
  Stack,
  Button,
  IconButton,
  Typography,
  StackProps,
  ClickAwayListener,
  MenuItem,
  MenuList,
  ButtonGroup,
  Popper,
  Paper
} from '@mui/material'

import {styled, alpha} from '@mui/material/styles'
// assets
import uploadFolder from '../../../images/gif/uploadFolder.gif'

//
import Iconify from '../iconify'
//
import {UploadProps} from './types'
import RejectionFiles from './errors/RejectionFiles'
import MultiFilePreview from './preview/MultiFilePreview'
import SingleFilePreview from './preview/SingleFilePreview'
import React, {useCallback, useRef, useState} from 'react'
import {LoadingButton} from '@mui/lab'

// ----------------------------------------------------------------------

const StyledDropZone = styled('div')(({theme}) => ({
  outline: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.paper,
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`
}))
/// -----------------------------------------------------------------------
const options = ['Files', 'Folder']

export function SplitButton({
  onClick
}: {
  onClick: (type: 'Files' | 'Folder') => void
}) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setOpen(false)
    onClick(index ? 'Folder' : 'Files')
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      ></ButtonGroup>
      <Button variant="contained" onClick={handleToggle}>
        {'Browse'}
        <Iconify icon="ic:baseline-expand-more" width={18} />
      </Button>
      <ButtonGroup />
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        placement={'bottom-start'}
        role={undefined}
        disablePortal
      >
        <Paper
          elevation={3}
          sx={{width: '100px', marginX: theme => theme.spacing(3)}}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="split-button-menu" autoFocusItem>
              {options.map((option, index) => (
                <MenuItem
                  key={option}
                  onClick={event => handleMenuItemClick(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  )
}

// ----------------------------------------------------------------------
const uploadFoldeDef = {directory: '', webkitdirectory: ''}

export default function Upload({
  disabled,
  multiple = false,
  error,
  helperText,
  //
  file,
  onDelete,
  //
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  onClickFolder,
  disableSave,
  isLoading,
  onSave,
  sx,
  ...other
}: UploadProps) {
  const {
    inputRef,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections
  } = useDropzone({
    multiple,
    disabled,
    ...other
  })

  const {
    inputRef: inputFolderRef,
    getInputProps: getInputFolderProps,
    fileRejections: FolderRejections
  } = useDropzone({
    multiple,
    disabled,
    ...other
  })

  const hasFile = !!file && !multiple

  const hasFiles = files && multiple && files.length > 0

  const isError = isDragReject || !!error

  const onChangeType = useCallback(
    (type: 'Folder' | 'Files') => {
      if (type === 'Files') {
        inputRef.current?.click()
      } else {
        inputFolderRef.current?.click()
      }
    },
    [inputRef, inputFolderRef]
  )

  return (
    <Box sx={{width: 1, position: 'relative', ...sx}}>
      <input
        type="file"
        id="folderSelector"
        style={{display: 'none'}}
        {...getInputFolderProps()}
        {...uploadFoldeDef}
      />

      <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.72,
            borderColor: theme => theme.palette.primary.main
          }),
          ...(isError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light'
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none'
          }),
          ...(hasFile && {
            padding: '5% 0'
          })
        }}
      >
        <input id="fileSelector" {...getInputProps()} />

        <Placeholder
          sx={{
            ...(hasFile && {
              opacity: 0
            })
          }}
        />

        <Box>
          <MultiFilePreview
            files={files}
            thumbnail={thumbnail}
            onRemove={onRemove}
            onClickFolder={onClickFolder}
          />
        </Box>
        {hasFile && <SingleFilePreview file={file} />}
      </StyledDropZone>

      {helperText && helperText}

      <RejectionFiles
        fileRejections={[...fileRejections, ...FolderRejections]}
      />

      {hasFile && onDelete && (
        <IconButton
          size="small"
          onClick={onDelete}
          sx={{
            top: 16,
            right: 16,
            zIndex: 9,
            position: 'absolute',
            color: theme => alpha(theme.palette.common.white, 0.8),
            bgcolor: theme => alpha(theme.palette.grey[900], 0.72),
            '&:hover': {
              bgcolor: theme => alpha(theme.palette.grey[900], 0.48)
            }
          }}
        >
          <Iconify icon="eva:close-fill" width={18} />
        </IconButton>
      )}

      <Stack direction="row" justifyContent="flex-end" spacing={1} pt={3}>
        {onRemoveAll && hasFiles && (
          <Button
            variant="text"
            size="small"
            onClick={onRemoveAll}
            disabled={!hasFiles}
          >
            Remove all
          </Button>
        )}

        <SplitButton onClick={onChangeType}></SplitButton>
        {onUpload && (
          <Button
            variant="contained"
            size="medium"
            onClick={onUpload}
            disabled={!hasFiles}
          >
            Submit{' '}
          </Button>
        )}

        {onSave && (
          <LoadingButton
            variant="contained"
            size="medium"
            onClick={onSave}
            disabled={!hasFiles && disableSave}
            loading={isLoading}
          >
            Save{' '}
          </LoadingButton>
        )}
      </Stack>
    </Box>
  )
}

// ----------------------------------------------------------------------

function Placeholder({sx, ...other}: StackProps) {
  return (
    <Stack
      spacing={5}
      alignItems="center"
      //justifyContent="center"
      direction={{
        xs: 'column',
        md: 'row'
      }}
      sx={{
        width: 1,
        textAlign: {
          xs: 'center',
          md: 'left'
        },
        ...sx
      }}
      {...other}
    >
      <Box width="40px" height="40px">
        <img src={uploadFolder} alt="my-gif" />
      </Box>
      <div>
        <Typography gutterBottom variant="h5">
          Drop files or folders here
        </Typography>

        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          Or click the "Browse" button
        </Typography>
      </div>
    </Stack>
  )
}
