import {ReactElement} from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {CircularProgress} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'

interface Props {
  children: ReactElement
}

const ProtectedRoute = observer(function ProtectedRoute({children}: Props) {
  const {loginStore} = useMainStore()
  const location = useLocation()

  if (loginStore.isAuth) return children

  if (loginStore.isMsalAuth) {
    return (
      <CircularProgress
        size={60}
        sx={{position: 'absolute', marginX: '43vw', marginY: '40vh'}}
      />
    )
  }

  return <Navigate to="/login" state={{from: location.pathname}} />
})

export default ProtectedRoute
