import {m, AnimatePresence} from 'framer-motion'
// @mui
import {IconButton, Stack, Typography} from '@mui/material'
// utils
import {fData} from 'src/utils/formatNumber'
//
import Iconify from '../../iconify'
//import {varFade} from '../../animate'
import FileThumbnail from '../../file-thumbnail'
//
import {UploadProps} from '../types'
import {isFile, MyFile, MyFolder} from 'src/entities/file'

// ----------------------------------------------------------------------

export default function MultiFilePreview({
  thumbnail,
  files,
  onRemove,
  onClickFolder,
  sx
}: UploadProps) {
  if (!files?.length) {
    return null
  }

  return (
    <AnimatePresence initial={false}>
      {files.map(file => {
        const key = file.path + file.name
        const name = file.name
        const isFormatFile = isFile(file) //  typeof file === 'string'

        const size = (isFormatFile && (file as MyFile).blob?.size) || 0

        // if (thumbnail) {
        //   return (
        //     <Stack
        //       key={key}
        //       component={m.div}
        //       {...varFade().inUp}
        //       alignItems="center"
        //       display="inline-flex"
        //       justifyContent="center"
        //       sx={{
        //         m: 0.5,
        //         width: 80,
        //         height: 80,
        //         borderRadius: 1.25,
        //         overflow: 'hidden',
        //         position: 'relative',
        //         border: theme => `solid 1px ${theme.palette.divider}`,
        //         ...sx
        //       }}
        //     >
        //       <FileThumbnail
        //         tooltip
        //         imageView
        //         // file={file}
        //         sx={{position: 'absolute'}}
        //         imgSx={{position: 'absolute'}}
        //       />

        //       {onRemove && (
        //         <IconButton
        //           size="small"
        //           // onClick={() => onRemove(file)}
        //           sx={{
        //             top: 4,
        //             right: 4,
        //             p: '1px',
        //             position: 'absolute',
        //             color: theme => alpha(theme.palette.common.white, 0.72),
        //             bgcolor: theme => alpha(theme.palette.grey[900], 0.48),
        //             '&:hover': {
        //               bgcolor: theme => alpha(theme.palette.grey[900], 0.72)
        //             }
        //           }}
        //         >
        //           <Iconify icon="eva:close-fill" width={16} />
        //         </IconButton>
        //       )}
        //     </Stack>
        //   )
        // }

        return (
          <Stack
            key={key}
            component={m.div}
            // {...varFade().inUp}
            spacing={2}
            direction="row"
            alignItems="center"
            onClick={e => {
              e.stopPropagation()
              !isFile(file) && onClickFolder?.(file as MyFolder)
            }}
            sx={{
              my: 1,
              px: 1,
              py: 0.75,
              borderRadius: 0.75,
              border: theme => `solid 1px ${theme.palette.divider}`,
              '&:hover': {
                backgroundColor: theme => theme.palette.action.hover
              },
              ...sx
            }}
          >
            <FileThumbnail
              file={isFile(file) ? file.path + '/' + file.name : 'folder'}
            />

            <Stack flexGrow={1} sx={{minWidth: 0}}>
              <Typography variant="subtitle2" noWrap>
                {name}
              </Typography>

              <Typography variant="caption" sx={{color: 'text.secondary'}}>
                {isFormatFile ? fData(size) : ''}
              </Typography>
            </Stack>

            {onRemove && (
              <IconButton
                edge="end"
                size="small"
                onClick={e => {
                  e.stopPropagation()
                  onRemove(file)
                }}
              >
                <Iconify icon="eva:close-fill" />
              </IconButton>
            )}
          </Stack>
        )
      })}
    </AnimatePresence>
  )
}
