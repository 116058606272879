import {makeAutoObservable} from 'mobx'
import Section from './Section'

export default class Scope {
  // id: string

  // name: string

  sections: Section[]

  isExpanded = false

  constructor(sections: Section[]) {
    makeAutoObservable(this)
    // this.id = scope.id
    // this.name = scope.name
    this.sections = sections.map(s => new Section(s))
  }

  get isAllSelected() {
    return this.sections.every(s => s.isAllSelected)
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded
    this.sections.forEach(section => {
      section.isExpanded = this.isExpanded
    })
  }
  toggleSelection(select: boolean) {
    this.sections.forEach(section => {
      section.toggleSelection(select)
    })
  }
}
