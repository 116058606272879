import {action, computed, makeObservable, observable} from 'mobx'
import BaseModel from 'src/entities/BaseModel'
import Response from 'src/entities/Response'

export enum ItemStatus {
  InProgress,
  Completed,
  MC,
  Cancelled
}

export enum MCItemStatus {
  None = 0,
  New,
  Approved,
  OnSyncData,
  MCSentToClient,
  ClientResponse,
  Resolved,
  Responded
}

export default class Item extends BaseModel {
  isSelected: boolean

  lpid: string

  tenant: string

  status: ItemStatus

  scopeId: number

  projectId: number

  projectName?: string

  propertyName?: string

  endUpdatedAt?: Date

  downloadedAt?: Date

  mcIssues?: MCIssue[]

  received?: Date

  dueDate?: Date

  dateSentToClient?: Date

  isOnHold: boolean

  isInUpdating: boolean

  constructor(item?: Item) {
    super(item)
    makeObservable(this, {
      isInUpdating: observable,
      endUpdatedAt: observable,
      downloadedAt: observable,
      setItemInUpdating: action,
      setItemDownloadedAt: action,
      isSelected: observable
    })
    this.tenant = item?.tenant || ''
    this.projectId = item?.projectId || 0
    this.projectName = item?.projectName
    this.propertyName = item?.propertyName
    this.scopeId = item?.scopeId || 0
    this.status = item?.status || 0
    this.lpid = item?.lpid || ''
    this.received = item?.received ? new Date(item.received) : undefined
    this.dueDate = item?.dueDate ? new Date(item.dueDate) : undefined
    this.dateSentToClient = item?.dateSentToClient
      ? new Date(item.dateSentToClient)
      : undefined
    this.endUpdatedAt = item?.endUpdatedAt
      ? new Date(item.endUpdatedAt)
      : undefined
    this.downloadedAt = item?.downloadedAt
      ? new Date(item.downloadedAt)
      : undefined
    this.isOnHold = item?.isOnHold || false
    this.isInUpdating = item?.isInUpdating || false
    this.isSelected = !this.isInUpdating
    this.mcIssues = item?.mcIssues?.map(i => new MCIssue(i))
  }
  setItemInUpdating = () => {
    this.isInUpdating = true
    this.endUpdatedAt = undefined
  }
  setItemDownloadedAt = () => {
    this.downloadedAt = new Date()
  }
}

export class MCIssue extends BaseModel {
  issue: string
  status?: MCItemStatus
  clientResponses?: Response[]
  item?: Item
  mcIssueText: string

  constructor(mcIssue?: MCIssue) {
    super(mcIssue)
    makeObservable(this, {
      clientResponses: observable,
      status: observable,
      setClientResponses: action,
      setStatus: action,
      clientResponsesCount: computed,
      lpid: computed,
      tenant: computed
    })

    this.issue = mcIssue?.issue ?? ''
    this.mcIssueText = this.issue.replace(/<[^>]+>|&nbsp;/g, '')
    this.clientResponses = mcIssue?.clientResponses?.map(i => new Response(i))
    this.status = mcIssue?.status
    if (this.status !== MCItemStatus.Resolved && this.clientResponsesCount > 0)
      this.status = MCItemStatus.Responded
  }

  setClientResponses = (value?: Response[]) => {
    this.clientResponses = value?.sort(
      (a, b) => (b.createdAt?.getTime() ?? 0) - (a.createdAt?.getTime() ?? 0)
    )
  }

  setStatus = (value?: MCItemStatus) => {
    if (value !== MCItemStatus.Resolved && this.clientResponsesCount > 0)
      this.status = MCItemStatus.Responded
    else this.status = value
  }

  get tenant() {
    return this.item?.tenant
  }

  get lpid() {
    return this.item?.lpid
  }

  get propertyName() {
    return this.item?.propertyName
  }

  get clientResponsesCount() {
    return this.clientResponses?.length ?? 0
  }

  get colorByStatus() {
    return this.status === MCItemStatus.Resolved
      ? 'success'
      : this.status === MCItemStatus.Responded
      ? 'info'
      : 'primary'
  }

  get labelByStatus() {
    return this.status === MCItemStatus.Resolved
      ? 'Resolved'
      : this.status === MCItemStatus.Responded
      ? 'Responded'
      : 'New'
  }
}
