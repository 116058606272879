import {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  selectClasses
} from '@mui/material'
import {FlexStack, ParentScrollStack, ScrollList} from '../global/FlexStack'
import {useMainStore} from 'src/contexts/Main'
import Scrollbar from '../minimal/scrollbar/Scrollbar'
import ProjectCard from 'src/components/item/ProjectCard'
import Iconify from 'src/components/minimal/iconify'
import {ProjectStatus} from 'src/entities/project/Project'
import {Stack} from '@mui/system'

function ProjectList() {
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const {projectsStore} = useMainStore()

  const projects = searchText.length
    ? projectsStore.filteredProjects.filter(
        p =>
          p.name.toLowerCase().includes(searchText) ||
          p.code.toLowerCase().includes(searchText)
      )
    : projectsStore.filteredProjects

  useEffect(() => {
    setLoading(true)
    projectsStore.getProjects().finally(() => setLoading(false))
  }, [projectsStore])

  return (
    <FlexStack flexGrow={1} px={5}>
      <Typography variant="h4" sx={{px: 1}}>
        My Projects
      </Typography>
      {loading ? (
        <CircularProgress
          size={60}
          sx={{position: 'absolute', marginX: '30%', marginY: '15%'}}
        />
      ) : (
        <>
          <Stack direction="row" gap={2} mt={2} pl={1}>
            <SelectStatus />
            <TextField
              size="small"
              value={searchText}
              onChange={e => setSearchText(e.target.value.toLowerCase())}
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{color: 'text.disabled'}}
                    />
                  </InputAdornment>
                )
              }}
            />
          </Stack>
          <ParentScrollStack>
            <ScrollList>
              <Scrollbar>
                {projects?.map(x => {
                  return (
                    <ListItem sx={{width: '100%', px: 1}} key={x.id}>
                      <ProjectCard project={x}></ProjectCard>
                    </ListItem>
                  )
                })}
              </Scrollbar>
            </ScrollList>
          </ParentScrollStack>
        </>
      )}
    </FlexStack>
  )
}

export default observer(ProjectList)

const SelectStatus = observer(function SelectStatus() {
  const {projectsStore} = useMainStore()
  const {selectedStatuses} = projectsStore

  const onChange = (event: SelectChangeEvent<ProjectStatus[]>) => {
    const value = event.target.value
    if (typeof value !== 'string') projectsStore.setSelectedStutuses(value)
  }

  return (
    <FormControl sx={{minWidth: 200}} size="small">
      <InputLabel>Select Status</InputLabel>
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        sx={
          selectedStatuses.length
            ? {
                [`&.${selectClasses.select}`]: {pt: 1.1, pb: 1}
              }
            : undefined
        }
        autoWidth={true}
        multiple
        value={selectedStatuses}
        onChange={onChange}
        input={<OutlinedInput label="Select Status" />}
        renderValue={selected => (
          <Box sx={{display: 'flex', flexWrap: 'wrap', m: 0, p: 0, gap: 0.5}}>
            {selected.map(value => (
              <Chip size="small" key={value} label={ProjectStatus[value]} />
            ))}
          </Box>
        )}
      >
        {Object.keys(ProjectStatus)
          .filter(v => !isNaN(Number(v)))
          .map(s => (
            <MenuItem key={s} value={Number(s)}>
              <Checkbox checked={selectedStatuses.includes(Number(s))} />
              <ListItemText primary={ProjectStatus[Number(s)]} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
})
