import {makeAutoObservable} from 'mobx'
import DataPoint from './DataPoint'

export default class Section {
  id: string

  name: string

  datapoints: DataPoint[]

  isExpanded = false

  constructor(section: Section) {
    makeAutoObservable(this)
    this.id = section.id
    this.name = section.name
    this.datapoints = section.datapoints.map(
      dp => new DataPoint(dp.id, dp.name, dp.propertyReportName, dp.isSelected)
    )
  }

  get isAnySelected() {
    return this.datapoints.some(dp => dp.isSelected)
  }

  get isAllSelected() {
    return this.datapoints.every(dp => dp.isSelected)
  }

  toggleExpand = () => {
    this.isExpanded = !this.isExpanded
  }

  toggleSelection(select?: boolean) {
    this.datapoints.forEach(dp => dp.toggleSelection(select))
  }
}
