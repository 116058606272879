import {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {TableBody, TableCell, TableRow, Typography} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import DeliverableTable from 'src/components/project/deliverable/DeliverableTable'
import {getFormattedDate} from 'src/utils/formatTime'
import Label from 'src/components/minimal/label/Label'
import {
  TableHeadCustom,
  getComparator,
  useTable
} from 'src/components/minimal/table'
import applyFilter from 'src/utils/applyFilter'

const TABLE_HEAD = [
  {id: 'lpid', label: 'LPID', align: 'left', minWidth: 120},
  {id: 'propertyName', label: 'Property Name', align: 'left', minWidth: 120},
  {id: 'tenant', label: 'File Name', align: 'left', minWidth: 130},
  {id: 'onHold', label: '', align: 'left', with: '10%'},
  {id: 'received', label: 'LPRD Received', align: 'left', minWidth: 160},
  {id: 'dueDate', label: 'Due Date', align: 'left', minWidth: 130}
]

const InProgressTable = observer(function InProgressTable() {
  const {projectStore} = useMainStore()
  const [searchText, setSearchText] = useState('')

  const {order, orderBy, onSort} = useTable({
    defaultOrderBy: 'none'
  })

  const items = searchText.length
    ? projectStore.inProgressItems?.filter(
        i =>
          i.lpid?.toLowerCase().includes(searchText) ||
          i.propertyName?.toLowerCase().includes(searchText) ||
          i.tenant?.toLowerCase().includes(searchText)
      )
    : projectStore.inProgressItems

  const dataFiltered = items
    ? applyFilter({
        inputData: items,
        comparator: getComparator(order, orderBy)
      })
    : []

  return (
    <DeliverableTable title="In Progress" onSearch={setSearchText}>
      <>
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEAD}
          rowCount={projectStore.inProgressItems?.length ?? 0}
          onSort={onSort}
        />
        <TableBody>
          {dataFiltered?.map(i => (
            <TableRow key={i.id}>
              <TableCell>{i.lpid}</TableCell>
              <TableCell>{i.propertyName}</TableCell>
              <TableCell sx={{py: 3}}>
                <Typography variant="body2">{i.tenant}</Typography>
              </TableCell>
              <TableCell>
                {i.isOnHold && (
                  <Label variant="soft" color="error">
                    On Hold
                  </Label>
                )}{' '}
              </TableCell>
              <TableCell>{getFormattedDate(i.createdAt)}</TableCell>
              <TableCell>{getFormattedDate(i.dueDate)}</TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell colSpan={6} sx={{textAlign: 'end', border: 'none'}}>
              {`Total: ${projectStore.inProgressCount}`}
            </TableCell>
          </TableRow>
        </TableBody>
      </>
    </DeliverableTable>
  )
})

export default InProgressTable
