export default function applyFilter({
  inputData,
  comparator
}: {
  // eslint-disable-next-line
  inputData: any[]
  // eslint-disable-next-line
  comparator: (a: any, b: any) => number
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const)

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  inputData = stabilizedThis.map(el => el[0])
  return inputData
}
