import {makeAutoObservable} from 'mobx'
//should be observer???

export default class AbstractReport {
  isInUpdating: boolean
  name: string
  lpid: string
  tenantName: string
  abstractorDesc: string
  qcDesc: string
  leaseDocument: string
  dateReviewed: Date
  sections: Section[] = []
  rents: Rent[] = []
  breakpoints: Breakpoint[] = []

  constructor(abstractReport: AbstractReport) {
    makeAutoObservable(this)
    this.isInUpdating = abstractReport.isInUpdating
    this.name = abstractReport.name
    this.lpid = abstractReport.lpid
    this.tenantName = abstractReport.tenantName
    this.abstractorDesc = abstractReport.abstractorDesc
    this.qcDesc = abstractReport.qcDesc
    this.leaseDocument = abstractReport.leaseDocument
    this.dateReviewed = abstractReport.dateReviewed
    this.sections = abstractReport.sections.map(s => new Section(s))
    this.rents = abstractReport.rents.map(r => new Rent(r))
    this.breakpoints = abstractReport.breakpoints.map(b => new Breakpoint(b))
  }
}

export class Section {
  name: string
  datapoints: DataPoint[]

  constructor(section: Section) {
    makeAutoObservable(this)
    this.name = section.name
    this.datapoints = section.datapoints.map(dp => new DataPoint(dp))
  }
}

export class DataPoint {
  name: string
  order?: number
  htmlValue?: string
  dataPointType: DataPointType
  children?: DataPoint[]

  constructor(dataPoint: DataPoint) {
    makeAutoObservable(this)
    this.name = dataPoint.name
    this.order = dataPoint.order
    this.htmlValue = dataPoint.htmlValue
    this.dataPointType = dataPoint.dataPointType
    this.children = dataPoint.children?.map(dp => new DataPoint(dp))
  }
}

export class Rent {
  calcName: string
  calcSource: string
  header: string
  details: RentDetails[]

  constructor(rent: Rent) {
    makeAutoObservable(this)
    this.calcName = rent.calcName
    this.calcSource = rent.calcSource
    this.header = rent.header
    this.details = rent.details?.map(d => new RentDetails(d))
  }
}

export class RentDetails {
  increaseDate: string
  annual: string
  monthly: string
  psf: string
  note: string
  constructor(detail: RentDetails) {
    makeAutoObservable(this)
    this.increaseDate = detail.increaseDate
    this.annual = detail.annual
    this.monthly = detail.monthly
    this.psf = detail.psf
    this.note = detail.note
  }
}

export class Breakpoint {
  calcName: string
  calcSource: string
  header: string
  details: BreakpointDetails[]

  constructor(breakpoint: Breakpoint) {
    makeAutoObservable(this)
    this.calcName = breakpoint.calcName
    this.calcSource = breakpoint.calcSource
    this.header = breakpoint.header
    this.details = breakpoint.details?.map(d => new BreakpointDetails(d))
  }
}

export class BreakpointDetails {
  increaseDate: string
  breakpoint: string
  percent: string
  note: string
  constructor(details: BreakpointDetails) {
    makeAutoObservable(this)
    this.increaseDate = details.increaseDate
    this.breakpoint = details.breakpoint
    this.percent = details.percent
    this.note = details.note
  }
}

export enum DataPointType {
  None = 0,
  RentChart = 1,
  BreakpointChart = 2
}

export const mergeRowsByNote = (data: RentDetails[] | BreakpointDetails[]) => {
  const mergedRows = []
  let rowIndex = 0

  while (rowIndex < data.length) {
    const currentRow = {...data[rowIndex]}
    let rowSpan = 1
    while (
      rowIndex + rowSpan < data.length &&
      currentRow.note === data[rowIndex + rowSpan].note &&
      currentRow.note
    ) {
      mergedRows[rowIndex + rowSpan] = {
        ...data[rowIndex + rowSpan],
        rowSpan: 1,
        spanNote: true
      }
      rowSpan++
    }

    mergedRows[rowIndex] = {...currentRow, rowSpan, spanNote: false}
    rowIndex += rowSpan
  }

  return mergedRows
}
