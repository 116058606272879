// ----------------------------------------------------------------------

export function emptyRows(
  page: number,
  rowsPerPage: number,
  arrayLength: number
) {
  return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0
}

function descendingComparator<T>(
  a: T,
  b: T,
  orderBy: keyof T,
  // eslint-disable-next-line
  getValue?: (object: any) => string | number
) {
  const aValue = getValue?.(a) ?? a[orderBy] ?? -1
  const bValue = getValue?.(b) ?? b[orderBy] ?? -1
  if (bValue < aValue) {
    return -1
  }
  if (bValue > aValue) {
    return 1
  }
  return 0
}
// eslint-disable-next-line
export function getComparator<Key extends keyof any>(
  order: 'asc' | 'desc',
  orderBy: Key,
  // eslint-disable-next-line
  getValue?: (object: any) => string | number
): (
  a: {[key in Key]: number | string},
  b: {[key in Key]: number | string}
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, getValue)
    : (a, b) => -descendingComparator(a, b, orderBy, getValue)
}
