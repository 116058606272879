import {Typography, Link} from '@mui/material'

import Breadcrumbs from '@mui/material/Breadcrumbs'
import {ReactComponent as DotIcon} from 'src/images/dot.svg'

interface FolderBreadcrumbsProps {
  path: string
  onChoose?: (path: string) => void
  rootPath?: string
}
export default function FolderBreadcrumbs({
  path,
  onChoose,
  rootPath
}: FolderBreadcrumbsProps) {
  const folders = path.split('/').reduce((all, currentFolder, index) => {
    currentFolder &&
      all.push({
        folderName: currentFolder,
        path:
          (all.length ? all.map(x => x.folderName).join('/') + '/' : '') +
          currentFolder +
          '/'
      })
    return all
  }, [] as {folderName: string; path: string}[])

  return (
    <>
      {folders.length > 1 && (
        <Breadcrumbs sx={{py: 1}} separator={<DotIcon />}>
          {folders.map((p, index) => {
            return (
              <Link
                key={p.path}
                sx={{
                  color:
                    index === folders.length - 1
                      ? 'text.disabled'
                      : 'text.primary'
                }}
                underline={
                  index === folders.length - 1 || !onChoose ? 'none' : 'hover'
                }
                onClick={() => {
                  onChoose?.(p.path)
                }}
              >
                <Typography
                  sx={{
                    cursor:
                      index === folders.length - 1 || !onChoose
                        ? 'default'
                        : 'pointer'
                  }}
                  variant="body2"
                >
                  {(index === 0 && rootPath ? rootPath : p.folderName).replace(
                    ':',
                    ''
                  )}
                </Typography>
              </Link>
            )
          })}
        </Breadcrumbs>
      )}
    </>
  )
}
