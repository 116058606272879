import {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material'
import ProjectList from './ProjectList'
import AmountRentChart from 'src/components/item/AmountRentChart'
import ExpirationDateChart from 'src/components/item/ExpirationDateChart'

function DashboardPage() {
  return (
    <Stack direction={'row'} display="flex" flexGrow={1} pb={2} spacing={1}>
      <ProjectList />
      <Stack width="35%" spacing={1} sx={{opacity: 0.4}}>
        <SelectExpirationDate />
        <Stack display="flex" spacing={1} flexDirection="column" flexGrow={1}>
          <AmountRentChart />
          <ExpirationDateChart />
        </Stack>
      </Stack>
      <Typography
        variant="h4"
        sx={{position: 'absolute', top: '50%', right: '10%'}}
      >
        Coming soon...
      </Typography>
    </Stack>
  )
}

export default observer(DashboardPage)

const options = [
  'Expiration Date',
  'Renewal Notice Date',
  'Security Deposit',
  'TI Allowance'
]

export function SelectExpirationDate() {
  const [selected, setSelected] = useState<string | undefined>('')

  const onChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value as string)
  }

  return (
    <Box>
      <FormControl sx={{minWidth: 200}}>
        <InputLabel size="small">Select</InputLabel>
        <Select
          size="small"
          value={selected}
          onChange={onChange}
          input={<OutlinedInput label="Select" />}
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
