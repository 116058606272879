import {useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import SelectedDataPointsModal from '../report/SelectedDataPointsModal'
import SelectProjects from '../../components/global/SelectProjects'
import {useMainStore} from 'src/contexts/Main'
import {ReportType} from 'src/entities/Report'

interface ReportReportTableHeaderProps {
  reportType: ReportType
}

export default observer(function ReportTableHeader({
  reportType
}: ReportReportTableHeaderProps) {
  const {reportStore} = useMainStore()
  const [openDataPoints, setOpenDataPoints] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)

  const onSelectProjects = (selected: number[]) => {
    reportStore.setSelectedProjects(selected)
  }

  const onGenerate = () => {
    setIsGenerating(true)

    switch (reportType) {
      case ReportType.PropertyReport:
        generateDatapointReport()
        break
      case ReportType.RentReport:
        generateRent()
        break
      case ReportType.PropertyRentRollReport:
        generatePropertyRentRoll()
    }
  }

  const generateDatapointReport = () => {
    reportStore
      .generate(
        reportStore.currentScope?.sections
          .flatMap(x => x.datapoints)
          .filter(x => x.isSelected)
          .map(x => x.propertyReport) || []
      )
      .finally(() => setIsGenerating(false))
  }

  const generateRent = () => {
    reportStore.generateRentReport().finally(() => setIsGenerating(false))
  }

  const generatePropertyRentRoll = () => {
    reportStore
      .generatePropertyRentRollReport()
      .finally(() => setIsGenerating(false))
  }

  const generateButton = (
    <LoadingButton
      disabled={
        reportType === ReportType.PropertyReport
          ? !reportStore.currentScope?.sections?.some(s => s.isAnySelected)
          : !reportStore.selectedReports?.length
      }
      onClick={onGenerate}
      loading={isGenerating}
      loadingIndicator={<CircularProgress size={24} color="primary" />}
      loadingPosition="center"
      variant="contained"
    >
      <span>Generate</span>
    </LoadingButton>
  )

  const resetSelection = useCallback(() => {
    reportStore.setSelectedProjects([])
    reportStore.currentScope?.toggleSelection(true)
  }, [reportStore])

  useEffect(() => {
    //scopeStore.initScope()
    reportStore.getItemsByCurrentClient()
  }, [reportStore])

  useEffect(() => {
    return () => {
      resetSelection()
    }
  }, [resetSelection])

  return (
    <>
      {reportStore.items.length > 0 && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
        >
          <Stack direction="row" gap={2} alignItems="start">
            <SelectProjects
              isSmall={true}
              onSelect={onSelectProjects}
              selected={reportStore.selectedProjects}
            />
            {reportStore.selectedReports.length > 0 &&
              reportType === ReportType.PropertyReport && (
                <Button
                  onClick={() => setOpenDataPoints(true)}
                  size="medium"
                  variant="outlined"
                  sx={{pt: 1}}
                >
                  Select data points
                </Button>
              )}
          </Stack>

          <Stack direction={'row'} gap={1}>
            <TextField
              size="small"
              sx={{minWidth: 390}}
              id="outlined-multiline-flexible"
              variant="outlined"
              value={reportStore.searchText}
              onChange={e => {
                reportStore.setSearchText(e.target.value)
              }}
              placeholder="Search by Project Name or LPID or File Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />

            {generateButton}
          </Stack>
        </Stack>
      )}{' '}
      {openDataPoints && (
        <SelectedDataPointsModal
          generateButton={generateButton}
          onClose={() => setOpenDataPoints(false)}
        />
      )}
    </>
  )
})
