import {useState} from 'react'
import {
  Checkbox,
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import Item from 'src/entities/Item'
import {ReactComponent as DownloadIcon} from 'src/images/ic_download.svg'
import {getFormattedDate} from 'src/utils/formatTime'
import Label from 'src/components/minimal/label/Label'
import {runInAction} from 'mobx'
import {observer} from 'mobx-react-lite'
import VisibilityIcon from '@mui/icons-material/Visibility'

interface CompletedRowProps {
  item: Item
}

const getUpdateLabe = (
  isInUpdating: boolean,
  endUpdatedAt?: Date,
  downloadedAt?: Date
) => {
  const updated = endUpdatedAt && (!downloadedAt || endUpdatedAt > downloadedAt)
  if (isInUpdating || updated)
    return (
      <Label variant="soft" color={isInUpdating ? 'warning' : 'info'}>
        {isInUpdating ? 'This file is under Review' : 'updated'}
      </Label>
    )
  return <></>
}

function DeliveredRow({item}: CompletedRowProps) {
  const [isDownloading, setIsDownloading] = useState(false)
  const {projectStore} = useMainStore()

  const onDownload = (itemId: number) => {
    setIsDownloading(true)
    projectStore
      .generateAbstractReport(itemId)
      .finally(() => setIsDownloading(false))
  }
  const onPreview = (itemId: number) => {
    projectStore.setItemDownloaded(itemId)
    window.open(`/abstract/${item.id}`, '_blank')
  }
  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          disabled={item.isInUpdating}
          color="primary"
          checked={item.isSelected}
          onChange={(e, checked) => {
            runInAction(() => {
              item.isSelected = checked
            })
          }}
        />
      </TableCell>
      <TableCell>{item.lpid}</TableCell>
      <TableCell>{item.propertyName}</TableCell>
      <TableCell sx={{py: 3}}>
        <Typography variant="body2">{item.tenant}</Typography>
      </TableCell>
      <TableCell>
        {getUpdateLabe(item.isInUpdating, item.endUpdatedAt, item.downloadedAt)}
      </TableCell>
      <TableCell>{getFormattedDate(item.createdAt)}</TableCell>
      <TableCell>{getFormattedDate(item.dateSentToClient)}</TableCell>
      <TableCell>
        <IconButton
          disabled={item.isInUpdating}
          onClick={() => onPreview(item.id || -1)}
        >
          <VisibilityIcon fill={item.isInUpdating ? '#919eab' : '#212B36'} />
        </IconButton>
        <IconButton
          disabled={item.isInUpdating || isDownloading}
          onClick={() => onDownload(item.id || -1)}
        >
          {isDownloading ? (
            <CircularProgress size={16} />
          ) : (
            <DownloadIcon fill={item.isInUpdating ? '#919eab' : '#212B36'} />
          )}
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default observer(DeliveredRow)
