import {makeAutoObservable} from 'mobx'
import ProjectStore from 'src/store/ProjectStore'
import BillingStore from 'src/store/BillingStore'
import ReportStore from 'src/store/ReportStore'
// import ScopeStore from './ScopeStore'
import ProjecstStore from './ProjectsStore'
import LoginStore from 'src/store/LoginStore'
import ItemFilesStore from 'src/store/ItemFilesStore'
import AbsractReportStore from 'src/store/AbsractReportStore'
import SignalrStore from './SignalrStore'

export class MainStore {
  readonly loginStore: LoginStore
  readonly signalrStore: SignalrStore
  readonly projectStore: ProjectStore
  readonly projectsStore: ProjecstStore
  readonly reportStore: ReportStore
  readonly billingStore: BillingStore
  readonly itemFilesStore: ItemFilesStore
  readonly absractReportStore: AbsractReportStore
  // readonly scopeStore: ScopeStore

  constructor() {
    this.loginStore = new LoginStore()
    this.projectsStore = new ProjecstStore(this.loginStore)
    this.projectStore = new ProjectStore(this.loginStore)
    this.billingStore = new BillingStore(this.loginStore)
    this.reportStore = new ReportStore(this.loginStore)
    this.itemFilesStore = new ItemFilesStore(this.loginStore)
    this.absractReportStore = new AbsractReportStore(this.loginStore)
    this.signalrStore = new SignalrStore(this.loginStore, this.projectStore)

    makeAutoObservable(this)
    this.loginStore.init()
  }
}
