import {observer} from 'mobx-react-lite'
import {Alert, Box, Snackbar, Stack, Typography} from '@mui/material'
import {Outlet, useLocation} from 'react-router-dom'
import Header from '../components/global/Header'
import MinimalMain from './MinimalMain'
import ErrorScreen from 'src/components/global/ErrorScreen'
import {useMainStore} from 'src/contexts/Main'
import NavSectionVertical from 'src/components/global/NavSectionVertical'
import NotSupportedScreen from 'src/components/global/NotSupportedScreen'
import useResponsive from 'src/hooks/useResponsive'
import {isChrome, isEdge} from 'react-device-detect'

function Main() {
  const {currentUser, errorStatus} = useMainStore().loginStore
  const {signalrStore} = useMainStore()

  const {pathname} = useLocation()
  const isSmall = useResponsive('down', 'lg')
  const isNotSupported =
    isSmall ||
    /MSIE|Trident/.test(window.navigator.userAgent) ||
    (!isChrome && !isEdge)

  return (
    <Box height="100%" width="100%">
      {errorStatus === undefined ? (
        <>
          <Header />
          {isNotSupported ? (
            <NotSupportedScreen />
          ) : (
            <Box
              sx={{
                display: {lg: 'flex'},
                minHeight: {lg: 1}
              }}
            >
              <NavSectionVertical />
              <MinimalMain>
                <Stack width={'100%'} spacing={1} pt={1}>
                  {currentUser?.clientName && (
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      pl={pathname === '/dashboard' ? 6 : 9}
                      py={1}
                    >
                      Welcome
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{
                          mx: 0.5
                        }}
                      >
                        {`${currentUser?.firstName} ${currentUser?.lastName}`}
                      </Typography>
                    </Typography>
                  )}
                  <Outlet />
                </Stack>
              </MinimalMain>
              <Snackbar
                open={!!signalrStore.maliciousFile}
                onClose={() => {
                  signalrStore.setMaliciousFile('')
                }}
              >
                <Alert
                  onClose={() => {
                    signalrStore.setMaliciousFile('')
                  }}
                  severity="error"
                >
                  You have a malicious file: {signalrStore.maliciousFile}
                </Alert>
              </Snackbar>
            </Box>
          )}
        </>
      ) : (
        <ErrorScreen />
      )}
    </Box>
  )
}

export default observer(Main)
