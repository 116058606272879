import {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {
  Card,
  CardContent,
  useTheme,
  Stack,
  Typography,
  CircularProgress
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {styled} from '@mui/material/styles'
import downloadBlob from 'src/utils/downloadFile'
import Chart, {useChart} from 'src/components/minimal/chart'
import {ReactComponent as DownloadIcon} from 'src/images/ic_download.svg'
import {fCurrency} from 'src/utils/formatNumber'
import useResponsive from 'src/hooks/useResponsive'

const StyledChart = styled('div')(({theme}) => ({
  '.apexcharts-legend': {
    justifyContent: 'center !important',
    gap: 8
  }
}))

const series = [619119, 985469, 599718, 1105400]

export default observer(function AmountRentChart() {
  const [isDownloading, setIsDownloading] = useState(false)

  const theme = useTheme()
  const chartOptions = useChart({
    labels: [
      'Westport Plaza',
      'New York Shopping Center',
      'Florida Shopping Center',
      'New Jersey Shopping Center'
    ],
    dataLabels: {
      enabled: false
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.info.main,
      theme.palette.error.main,
      theme.palette.warning.main
    ],
    stroke: {
      show: true,
      width: 1
    },
    legend: {
      horizontalAlign: 'center',
      position: 'right'
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            total: {
              showAlways: true,
              formatter: function (w) {
                const total = w.globals.seriesTotals.reduce(
                  (a: number, b: number) => a + b,
                  0
                )
                return fCurrency(total)
              }
            }
          }
        }
      }
    },
    chart: {
      sparkline: {
        enabled: true
      }
    },
    tooltip: {
      style: {
        fontSize: '14px'
      },
      y: {
        formatter: (value: number) => `${fCurrency(value)}`,
        title: {
          formatter: () => ''
        }
      }
    }
  })

  const onExport = () => {
    setIsDownloading(true)
    fetch('/files/Annual Rent Report.xlsx')
      .then(response => response.blob())
      .then(blob => downloadBlob('Annual Rent Report.xlsx', blob))
      .finally(() => setIsDownloading(false))
  }
  const isXXLDesktop = useResponsive('up', 'xxl')

  return (
    <Card>
      <CardContent>
        <Stack mb={1} direction="row" justifyContent="space-between">
          <Typography variant="h6">Total Annual Rent</Typography>
          <LoadingButton
            onClick={onExport}
            loading={isDownloading}
            loadingIndicator={<CircularProgress size={24} color="primary" />}
            loadingPosition="center"
            color="inherit"
            startIcon={<DownloadIcon />}
          >
            <span>Export</span>
          </LoadingButton>
        </Stack>
        <StyledChart>
          <Chart
            type="donut"
            series={series}
            options={chartOptions}
            width={isXXLDesktop ? '90%' : '100%'}
          />
        </StyledChart>
      </CardContent>
    </Card>
  )
})
