import {useState} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardContent,
  Stack,
  Typography,
  TextField,
  Button,
  IconButton,
  DialogActions
  // RadioGroup,
  // Radio,
  // FormControlLabel
} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import Instruction from 'src/entities/project/Instruction'
import Editor from 'src/components/minimal/editor'
import Iconify from 'src/components/minimal/iconify'
import {LoadingButton} from '@mui/lab'
// import {InstructionType} from '../../../entities/project/Instruction'

interface NewInstructionDialogProps {
  onClose: VoidFunction
}

export default function NewInstructionDialog({
  onClose
}: NewInstructionDialogProps) {
  const {projectStore} = useMainStore()
  const [subject, setSubject] = useState('')
  const [content, setContent] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  //const [type, setType] = useState(1)
  const type = 1

  const onSave = () => {
    setIsSaving(true)
    const newInstruction = new Instruction(0, subject, content, new Date())
    projectStore.addNewInstruction(newInstruction, type).finally(() => {
      setIsSaving(false)
      onClose()
    })
  }

  // const onTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setType(Number(event.target.value))
  // }

  return (
    <Dialog fullWidth maxWidth="lg" open onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">New Instruction </Typography>
          <IconButton size="small" disabled={isSaving} onClick={onClose}>
            <Iconify icon="eva:close-fill" width={16} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{height: '70vh'}}>
        <Card sx={{height: '100%'}}>
          <CardContent sx={{height: '100%'}}>
            <Stack spacing={2} sx={{height: '100%'}}>
              <Stack spacing={2} justifyContent="space-between" direction="row">
                <TextField
                  //sx={{width: '70%'}}
                  fullWidth
                  placeholder="Subject"
                  value={subject}
                  onChange={e => setSubject(e.target.value)}
                />
                {/* <RadioGroup row value={type} onChange={onTypeChange}>
                  {Object.keys(InstructionType)
                    .filter(v => !isNaN(Number(v)))
                    .map((option, index) => (
                      <FormControlLabel
                        value={index}
                        control={<Radio color="primary" />}
                        label={InstructionType[index]}
                      />
                    ))}
                </RadioGroup> */}
              </Stack>
              <Editor
                simple
                id="simple-editor"
                value={content}
                placeholder="Insert instruction here"
                onChange={value => setContent(value)}
                sx={{minHeight: 100, height: '100%'}}
              />
            </Stack>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isSaving} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          size="medium"
          onClick={onSave}
          disabled={isSaving || !content}
          loading={isSaving}
        >
          Save{' '}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
