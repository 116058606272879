import {makeAutoObservable, runInAction} from 'mobx'
import Project, {ProjectStatus} from '../entities/project/Project'
import config from '../config'
import LoginStore from 'src/store/LoginStore'

export default class ProjecstStore {
  projects: Project[] = []

  selectedStatuses: ProjectStatus[] = []

  constructor(readonly loginStore: LoginStore) {
    makeAutoObservable(this)
  }

  setSelectedStutuses(selected: ProjectStatus[]) {
    this.selectedStatuses = selected.map(x => Number(x))
  }

  get filteredProjects() {
    return this.selectedStatuses.length
      ? this.projects?.filter(p => this.selectedStatuses.includes(p.status))
      : this.projects
  }

  async getProjects() {
    return this.loginStore
      .fetchWithUser(`${config.apiUrl}/projects/getByCurrrentClient`)
      .then((data: Project[]) => {
        const result = data.map(i => new Project(i))
        runInAction(() => {
          this.projects = result.sort(
            (a, b) =>
              (b.createdAt?.getTime() ?? 0) - (a.createdAt?.getTime() ?? 0)
          )
        })
      })
      .catch()
  }
}
