import Iconify from '../minimal/iconify/Iconify'

// // @mui
import {useTheme} from '@mui/material/styles'
import {AppBar, Toolbar, IconButton, Stack} from '@mui/material'
// // utils
import {bgBlur} from 'src/utils/cssStyles'
// // hooks
import useOffSetTop from 'src/hooks/useOffSetTop'
import useResponsive from 'src/hooks/useResponsive'
// // config
import {HEADER} from 'src/settings/config-setting' //chekkkkkkkkk
// // components
// import Logo from '../../../components/logo';
import {ReactComponent as Logo} from '../../images/newLogo.svg'

// import Iconify from '../../../components/iconify';
import {useSettingsContext} from 'src/settings'
import Box from '@mui/material/Box'
import AccountPopover from 'src/components/global/AccountPopover'
// //
// import Searchbar from './Searchbar';
// import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme()
  const {themeLayout} = useSettingsContext()

  const isNavHorizontal = themeLayout === 'horizontal'

  const isNavMini = true // themeLayout === 'mini'

  const isDesktop = useResponsive('up', 'lg')

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal

  const renderContent = (
    <Stack
      width={'100%'}
      height={'100%'}
      direction="row"
      justifyContent={'space-between'}
      alignItems={'center'}
      bgcolor={theme.palette.primary.main}
      sx={{px: {lg: 5}}}
    >
      {/* {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />} */}

      {/* {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )} */}

      {/* <Searchbar /> */}
      <Logo />

      {/* <LanguagePopover />
        <NotificationsPopover />
        <ContactsPopover /> */}
      <AccountPopover />
    </Stack>
  )

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter
        }),
        ...(isDesktop && {
          //width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`
          }),
          ...(isNavMini && {
            width: `100%`
          })
        })
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: '0!important'
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  )
}

export function Header1() {
  return (
    <Box
      sx={{
        justifyContent: 'end',
        flexGrow: 1,
        display: 'flex',
        px: 5
      }}
    >
      <IconButton edge="end">
        <Iconify icon={'eva:search-fill'} />
      </IconButton>
    </Box>
  )
}
