import {observer} from 'mobx-react-lite'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import Iconify from 'src/components/minimal/iconify'
import {ReactComponent as CollapseAllIcon} from 'src/images/collapse_all.svg'
import {ReactComponent as UnfoldMoreIcon} from 'src/images/unfold_more.svg'

interface SelectedDataPointsModalProps {
  generateButton: JSX.Element
  onClose: () => void
}

export default observer(function SelectedDataPointsModal({
  generateButton,
  onClose
}: SelectedDataPointsModalProps) {
  const {currentScope} = useMainStore().reportStore

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onClose}>
      <DialogTitle>Select data points</DialogTitle>
      <DialogContent>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
        >
          <Stack direction={'row'} alignItems={'center'} pl={3}>
            <Checkbox
              defaultChecked
              checked={currentScope?.isAllSelected}
              onChange={e => {
                e.stopPropagation()
                currentScope?.toggleSelection(
                  currentScope.isAllSelected ? false : true
                )
              }}
              indeterminate={
                (
                  currentScope?.sections
                    .flatMap(x => x.datapoints)
                    .filter(x => x.isSelected) || []
                ).length > 0 &&
                (currentScope?.sections
                  .flatMap(x => x.datapoints)
                  .filter(x => x.isSelected)?.length || 0) <
                  (currentScope?.sections.flatMap(x => x.datapoints).length ||
                    0)
              }
            />
            <Typography>Select All</Typography>
          </Stack>
          <Tooltip
            title={currentScope?.isExpanded ? 'Collapse All' : 'Expand All'}
          >
            <IconButton
              sx={{mr: 0.5}}
              onClick={() => currentScope?.toggleExpand()}
            >
              {currentScope?.isExpanded ? (
                <CollapseAllIcon />
              ) : (
                <UnfoldMoreIcon />
              )}
            </IconButton>
          </Tooltip>
        </Stack>
        {currentScope?.sections?.map(section => (
          <Accordion
            key={section.id + section.name}
            expanded={section.isExpanded}
          >
            <AccordionSummary
              expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
              onClick={section.toggleExpand}
            >
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  section.toggleSelection(section.isAllSelected ? false : true)
                }}
              >
                <Checkbox
                  checked={section.isAllSelected}
                  indeterminate={
                    section.datapoints.filter(x => x.isSelected).length > 0 &&
                    section.datapoints.filter(x => x.isSelected)?.length <
                      section.datapoints.length
                  }
                />
                <Typography variant="subtitle1">{section.name}</Typography>
              </IconButton>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {section.datapoints.map(dp => (
                  <ListItem key={dp.id} dense>
                    <ListItemIcon onClick={() => dp.toggleSelection()}>
                      <Checkbox checked={dp.isSelected} />
                    </ListItemIcon>
                    <ListItemText primary={dp.propertyReport} />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions>{generateButton}</DialogActions>
    </Dialog>
  )
})
