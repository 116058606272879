import {makeAutoObservable} from 'mobx'
import LoginStore from 'src/store/LoginStore'
import config from '../config'
import {ItemFileNode} from 'src/entities/ItemFileNode'
import {ItemFileResource} from 'src/entities/itemFile'
import downloadFile from 'src/utils/downloadFile'

export default class ItemFilesStore {
  selectedFiles: number[] = []

  searchText = ''

  filesTree: ItemFileNode[] = []

  currentDoc: CurrentDoc | undefined = undefined

  isDocLoading = false

  expanedAll = true

  currentJump?: number = undefined

  jumpToAnnotation?: string[]

  constructor(readonly loginStore: LoginStore) {
    makeAutoObservable(this)
  }

  get isAllExpanded() {
    return this.filesTree.every(f => f.isAllExpanded)
  }

  setSelectedProjects(selected: number[]) {
    this.selectedFiles = selected
  }

  setSearchText = (value: string) => {
    this.searchText = value
  }

  setCurrentJump = (value?: number) => {
    this.currentJump = value
  }

  setJumpToAnnotation = (value?: string[]) => {
    this.jumpToAnnotation = value
  }

  toggleExpanedAll = () => {
    this.expanedAll = !this.expanedAll
    this.filesTree.forEach(n => n.toggleExpanAll(this.expanedAll))
  }

  setCurrentDocBlob = (extention: string, blob?: Blob) => {
    this.currentDoc = new CurrentDoc(extention, blob)
  }

  setIsDocLoading = (value: boolean) => {
    this.isDocLoading = value
  }

  setFilesTree = (value: ItemFileNode[]) => {
    this.filesTree = value
  }

  getAllFiles = async () => {
    const fetchData1 = this.loginStore.fetchWithUser(
      `${config.apiUrl}/documents`
    )

    const fetchData2 = this.loginStore.fetchWithUser(
      `${config.apiUrl}/items/files`
    )

    return Promise.all([fetchData1, fetchData2]).then(
      ([data1, data2]: [ItemFileResource[], ItemFileResource[]]) => {
        const folderTree = this.buildFolderTree(data1, data2)
        this.setFilesTree(folderTree)
      }
    )
  }

  getFileBlob = async (folderNode: ItemFileNode) => {
    if (folderNode.blob) {
      this.setCurrentDocBlob(folderNode.name, folderNode.blob)
      return
    }
    const path = folderNode.path
    const response = await this.loginStore.fetchWithUser(
      `${config.apiUrl}/items/downloadFile?path=${encodeURIComponent(path)}`,
      false
    )
    const blob = await response.blob()
    folderNode.blob = blob
    this.setCurrentDocBlob(folderNode.name, blob)
  }

  buildFolderTree(
    data: ItemFileResource[],
    files: ItemFileResource[]
  ): ItemFileNode[] {
    const root: ItemFileNode[] = []
    let i = 0
    for (const itemFile of data) {
      const pathParts = itemFile.path.split(/[\\/]/) // Split path by slashes
      let currentNode = root
      const length = pathParts.length
      for (const part of pathParts) {
        // Check if a node with the current part already exists
        let existingNode: ItemFileNode | undefined = currentNode?.find(
          node => node.name === part
        )
        if (!existingNode && part !== '$$$.$$$') {
          const isFile =
            /\.[^/.]+$/.test(part) && part === pathParts[length - 1]
          const path = itemFile.path.substring(
            0,
            itemFile.path.indexOf(part) + part.length
          )

          const file = files.find(x => x.path === (isFile ? path : path + '/'))

          existingNode = new ItemFileNode(
            i++,
            part,
            path,
            [],
            file ? file.disabled : itemFile.disabled,
            file ? file.uniqueId : undefined
          )
          currentNode.push(existingNode)
        }
        currentNode = existingNode?.children ?? []
      }
    }

    return root
  }

  jumpToFile = (uniqueId: string) => {
    const file = this.filesTree.reduce(
      (node: ItemFileNode | undefined, currentNode: ItemFileNode) => {
        if (node) return node
        const found = currentNode.findNodeByFileId(uniqueId)
        return found
      },
      undefined
    )
    this.setCurrentJump(file?.id)
  }

  downloadFile = async (path: string) => {
    const response = await this.loginStore.fetchWithUser(
      `${config.apiUrl}/items/downloadFile?path=${encodeURIComponent(path)}`,
      false
    )

    const blob = await response.blob()
    downloadFile(path.split('/').pop() || '', blob)
  }
}

export class CurrentDoc {
  blob?: Blob
  fileName = ''

  constructor(fileName: string, blob?: Blob) {
    makeAutoObservable(this)
    this.blob = blob
    this.fileName = fileName
  }
}
