import React, {useEffect, useRef, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import config from 'src/config'
interface ReportersContextProviderProps {
  children: React.ReactNode
}

const ReportersContext = React.createContext({})

const ReportersContextProvider = observer(function ReportersContextProvider({
  children
}: ReportersContextProviderProps) {
  // eslint-disable-next-line
  const reportersRef = useRef<any>(null)
  const [ready, setReady] = useState(false)
  const {currentUser} = useMainStore().loginStore

  useEffect(() => {
    if (config.isDevelopment) return // dont run in dev

    import(/* webpackChunkName: 'reporters' */ '../utils/reporters').then(x => {
      reportersRef.current = x.default
      setReady(true)
    })
  }, [])

  // update reporters with user data
  useEffect(() => {
    if (!ready || !currentUser || !Object.keys(currentUser).length) return

    const {logrocket} = reportersRef.current
    const {id, firstName, lastName, email} = currentUser
    const username: string = firstName + ' ' + lastName

    logrocket.identify(id, {username, email})
  }, [ready, currentUser])

  return (
    <ReportersContext.Provider value={{}}>{children}</ReportersContext.Provider>
  )
})

export {ReportersContextProvider, ReportersContext}
