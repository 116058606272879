import React, {useRef, useEffect, useState} from 'react'
import Tooltip from '@mui/material/Tooltip'
import {Typography, TypographyProps} from '@mui/material'

interface CuiOverflowTooltipProps extends TypographyProps {
  value: string | JSX.Element
  title?: string
}

export default function CuiOverflowTooltip({
  value,
  title,
  ...props
}: CuiOverflowTooltipProps) {
  const [hoverStatus, setHover] = useState(false)
  const textElementRef = useRef<HTMLDivElement>(null)
  const compareSize = () => {
    if (textElementRef.current !== null) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth
      setHover(compare)
    }
  }
  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize()
    window.addEventListener('resize', compareSize)
    return () => window.removeEventListener('resize', compareSize) // remove resize listener
  }, [])

  return title || value ? (
    <Tooltip title={title || value} disableHoverListener={!hoverStatus}>
      <Typography
        {...props}
        ref={textElementRef}
        sx={{
          ...props.sx,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {value}
      </Typography>
    </Tooltip>
  ) : (
    <div>{value}</div>
  )
}
