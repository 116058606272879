// @mui
import {Table, TableBody} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {
  FlexStack,
  ParentScrollStack,
  ScrollTableContainer
} from 'src/components/global/FlexStack'
import Scrollbar from 'src/components/minimal/scrollbar'
// @types
import {isFile, MyFile, MyFolder} from 'src/entities/file'
// components
// import Iconify from '../../../../components/iconify';
import {
  // emptyRows,
  TableProps,
  // TableNoData,
  // TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
  getComparator
} from '../../../minimal/table'
//
import FileTableRow from './FileTableRow'
import applyFilter from 'src/utils/applyFilter'
import {fileFormat} from 'src/components/minimal/file-thumbnail'
import {fData} from 'src/utils/formatNumber'

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {id: 'name', label: 'Name', align: 'left'},
  {id: 'size', label: 'Size', align: 'left', width: 120},
  {id: 'type', label: 'Type', align: 'center', width: 120},
  {id: 'dateModified', label: 'Modified', align: 'left', width: 160},
  {id: '', label: '', align: 'left'}
]

const getTypeComparator = (object: MyFile | MyFolder) => {
  return object instanceof MyFile ? fileFormat(object.name) : 'folder'
}
const getSizeComparator = (object: MyFile | MyFolder) => {
  return object instanceof MyFile ? fData(object.size) : ''
}
const comparator: {
  [orderBy: string]: (object: MyFile | MyFolder) => string | number
} = {
  type: getTypeComparator,
  size: getSizeComparator
}

type Props = {
  table: TableProps
  tableData: (MyFile | MyFolder)[]
  dataFiltered: (MyFile | MyFolder)[]
  onDeleteRow?: (id: string) => void
  onClickRow?: (id: string) => void
  onClickDownload?: (row: MyFile) => void
}

function FileListView({
  table,
  tableData,
  onDeleteRow,
  dataFiltered,
  onClickRow,
  onClickDownload
}: Props) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage
  } = table

  const dataFiltered2 = dataFiltered
    ? applyFilter({
        inputData: dataFiltered,
        comparator: getComparator(order, orderBy, comparator[orderBy])
      })
    : []

  return (
    <FlexStack>
      <ParentScrollStack
        sx={{
          px: 1,
          borderRadius: 1.5,

          bgcolor: 'background.neutral'
        }}
      >
        <ScrollTableContainer>
          <Scrollbar>
            <Table
              size={dense ? 'small' : 'medium'}
              sx={{
                px: 1,
                minWidth: 960,
                borderCollapse: 'separate',
                borderSpacing: '0 8px',
                '& .MuiTableCell-head': {
                  boxShadow: 'none !important'
                }
              }}
            >
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={onSort}
                onSelectAllRows={checked =>
                  onSelectAllRows(
                    checked,
                    tableData.map(row => row.name)
                  )
                }
              />

              <TableBody>
                {dataFiltered2
                  //.sort((a, b) => Number(isFile(a)) - Number(isFile(b)))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <FileTableRow
                      key={row.name}
                      row={row}
                      selected={selected.includes(row.name)}
                      onSelectRow={() => onSelectRow?.(row.name)}
                      onDeleteRow={() => onDeleteRow?.(row.name)}
                      onClickRow={() =>
                        !isFile(row) && onClickRow?.(row.path + row.name + '/')
                      }
                      onClickDownload={() =>
                        isFile(row) && onClickDownload?.(row as MyFile)
                      }
                    />
                  ))}

                {/* <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
              />

              <TableNoData isNotFound={isNotFound} />  */}
              </TableBody>
            </Table>
          </Scrollbar>
        </ScrollTableContainer>
      </ParentScrollStack>
      <TablePaginationCustom
        count={tableData.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        //
        dense={dense}
        onChangeDense={onChangeDense}
        sx={{
          '& .MuiTablePagination-root': {borderTop: 'none'},
          '& .MuiFormControlLabel-root': {px: 0},
          p: 0,
          maxHeight: '40px'
        }}
      />
    </FlexStack>
  )
}

export default observer(FileListView)
