import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material'

interface WarninigModalProps {
  title: string
  text: string
  okText?: string
  cancelText?: string
  open: boolean

  onClose: (result: boolean) => void
}

export default function WarninigModal({
  title,
  text,
  okText,
  cancelText,
  open,
  onClose
}: WarninigModalProps) {
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose(false)
      }}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            onClose(false)
          }}
        >
          {cancelText || 'Cancel'}
        </Button>
        <Button
          onClick={() => {
            onClose(true)
          }}
          autoFocus
        >
          {okText || 'Stay'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
