import {forwardRef} from 'react'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import {Link, ListItemText, Stack} from '@mui/material'
import {NavItemProps} from './types'
import {StyledItem} from './styles'
import {useTheme} from '@mui/material/styles'
import Iconify from '../minimal/iconify'
// ----------------------------------------------------------------------

const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
  ({item, depth, open, active, isExternalLink, ...other}, ref) => {
    const theme = useTheme()
    const {pathname} = useLocation()
    const {title, path, icon, children, disabled} = item

    const subItem = depth !== 1
    const names = pathname.split('/')
    const isborder =
      active || (subItem && names?.length > 0 && path.startsWith(names[1]))

    const renderContent = (
      <StyledItem
        depth={depth}
        open={open}
        active={active}
        disabled={disabled}
        sx={{
          borderLeft: isborder ? 2 : 0,
          borderColor: theme.palette.primary.main,
          py: icon ? 3 : 1,
          px: 1,
          display: 'flex',
          alignItems: icon ? 'center' : 'flex-start'
        }}
        {...other}
      >
        <Stack
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Stack alignItems={'center'} pl={children ? 3 : undefined}>
            {icon &&
              icon(
                active
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              )}
            {/* {subItem && (
            <StyledIcon>
              <StyledDotIcon active={active && subItem} />
            </StyledIcon>
          )} */}
            <ListItemText
              sx={{pt: 1}}
              primary={title}
              primaryTypographyProps={{
                noWrap: true,
                variant: 'subtitle2'
              }}
            />
          </Stack>
          {!!children && (
            <Iconify
              width={16}
              icon={
                open
                  ? 'eva:arrow-ios-downward-fill'
                  : 'eva:arrow-ios-forward-fill'
              }
              sx={{ml: 1, flexShrink: 0}}
            />
          )}
        </Stack>
      </StyledItem>
    )

    const renderItem = () => {
      // ExternalLink
      if (isExternalLink)
        return (
          <Link href={path} target="_blank" rel="noopener" underline="none">
            {renderContent}
          </Link>
        )

      // Has child
      if (children) {
        return renderContent
      }

      // Default
      return (
        <Link component={RouterLink} to={path} underline="none">
          {renderContent}
        </Link>
      )
    }

    return renderItem()
  }
)

export default NavItem
