import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom'
import LoginPage from '../components/auth/LoginPage'
import ProjectPage from '../components/project/ProjectPage'
import Main from './main'
import DocumentPage from '../components/document/DocumentPage'
import BillingPage from '../components/billing/BillingPage'
import ReportPage from '../components/report/ReportPage'
import ItemAbstractMAppingPage from 'src/components/item/ItemAbstractPage'
import DashboardPage from 'src/components/item/DashboardPage'
import {ReportType} from 'src/entities/Report'
import ProtectedRoute from 'src/components/auth/ProtectedRoute'
import Logout from 'src/components/auth/LogOut'
import ErrorScreen from 'src/components/global/ErrorScreen'
import DocumentsPage from 'src/components/itemsFiles/DocumentsPage'
import AbstractPage from 'src/components/item/abstract/AbstractPage'

export default function Router() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Main />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: <Navigate to="dashboard" />
        },
        {
          path: 'dashboard',
          element: <DashboardPage />
        },
        {
          path: 'project/:id/:selectedTab/:selectedStatus?',
          element: <ProjectPage />
        },
        {
          path: 'project/item-abstract',
          element: <ItemAbstractMAppingPage />
        },
        {
          path: 'document',
          element: <DocumentPage />
        },
        {
          path: 'invoices',
          element: <BillingPage />
        },
        {
          path: 'documents/:uniqueId?',
          element: <DocumentsPage />
        },
        {
          path: 'abstract/:id',
          element: <AbstractPage />
        },
        {
          path: 'reports/PropertyDataPoints',
          element: <ReportPage reportType={ReportType.PropertyReport} />
        },
        {
          path: 'reports/12MonthsRent',
          element: <ReportPage reportType={ReportType.RentReport} />
        },
        {
          path: 'reports/RentReport',
          element: <ReportPage reportType={ReportType.PropertyRentRollReport} />
        }
      ]
    },
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/logout',
      element: <Logout />
    },
    {
      path: '*',
      element: <ErrorScreen routerError={true} />
    }
    // {
    //   path: 'sign-in',
    //   element: <SignInPage />
    // },
    // {
    //   path: 'sign-document',
    //   element: <SignDocument />
    // },
    // {
    //   path: 'choose-sign-document',
    //   element: <ChooseSignDocument />
    // }
  ])

  return <RouterProvider router={router} />
}
