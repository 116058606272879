import {action, computed, makeObservable, observable} from 'mobx'
import Report from '../Report'
import BaseModel from 'src/entities/BaseModel'
import Response from 'src/entities/Response'

export enum ProjectStatus {
  New,
  InProgress,
  Completed,
  OnHold,
  Cancelled,
  Reopened,
  'In Progress' = InProgress,
  'On Hold' = OnHold,
  'Re-opened' = 'Reopened'
}

export default class Project extends BaseModel {
  name = ''

  code = ''

  dueDate = ''

  status: ProjectStatus

  items: Report[]

  city: string

  state: string

  streetAddress: string

  siteLink: string

  siteLinkComment: string

  generalResponses?: Response[]

  constructor(project?: Project) {
    super(project)
    makeObservable(this, {
      status: observable,
      items: observable,
      siteLink: observable,
      siteLinkComment: observable,
      generalResponses: observable,
      setGeneralResponses: action,
      generalResponsesCount: computed
    })

    this.name = project?.name ?? ''
    this.code = project?.code ?? ''
    this.dueDate = project?.dueDate ?? ''
    this.status = project?.status ?? ProjectStatus.InProgress
    this.items = project?.items ?? []
    this.city = project?.city ?? ''
    this.state = project?.state ?? ''
    this.streetAddress = project?.streetAddress ?? ''
    this.siteLink = project?.siteLink ?? ''
    this.siteLinkComment = project?.siteLinkComment ?? ''
    this.generalResponses = project?.generalResponses?.map(i => new Response(i))
  }

  setGeneralResponses = (value?: Response[]) => {
    this.generalResponses = value
      ?.map(i => new Response(i))
      ?.sort(
        (a, b) => (b.createdAt?.getTime() ?? 0) - (a.createdAt?.getTime() ?? 0)
      )
  }

  get generalResponsesCount() {
    return this.generalResponses?.length ?? 0
  }

  toString = (): string => {
    return `${this.name} (${this.code})`
  }
}
