import {Box, LinearProgress, Typography} from '@mui/material'
import {Stack} from '@mui/system'
import UploadDocuments from '../../../images/gif/verified.gif'

export interface UploadProgressProps {
  filesCount: number
  filesSent: number
}

export default function UploadProgress({
  filesCount,
  filesSent
}: UploadProgressProps) {
  return (
    <Stack>
      <Box
        display="flex"
        flexWrap="wrap"
        height="600px"
        alignContent="center"
        alignSelf="center"
      >
        <Stack alignItems="center" spacing={5} pb={10}>
          <Box width="160px" height="160px">
            <img src={UploadDocuments} alt="my-gif" />
          </Box>
          <Typography variant="h3">
            {!filesCount
              ? 'Your Link Was Saved Successfully'
              : filesSent < filesCount
              ? `File ${filesSent + 1} out of ${filesCount} is uploading`
              : 'Your files have been uploaded successfully'}
          </Typography>
          <Box sx={{width: '100%'}}>
            <LinearProgress
              variant="determinate"
              value={(filesSent / filesCount) * 100}
            />
          </Box>
        </Stack>
      </Box>
    </Stack>
  )
}
