import {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {Table, TableBody} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import {
  TableHeadCustom,
  TablePaginationCustom,
  getComparator,
  useTable
} from 'src/components/minimal/table'
import {FlexStack, ScrollTableContainer} from 'src/components/global/FlexStack'
import ReportRow from 'src/components/report/ReportRow'
import Scrollbar from '../minimal/scrollbar'
import applyFilter from 'src/utils/applyFilter'

const TABLE_HEAD = [
  {id: 'lpid', label: 'LPID', align: 'left', minWidth: 120},
  {id: 'projectName', label: 'Project Name', align: 'left', minWidth: 150},
  {id: 'propertyName', label: 'Property Name', align: 'left', minWidth: 160},
  // {id: 'status', label: 'Status', align: 'left'},
  {id: 'tenant', label: 'File Name', align: 'left', minWidth: 130}
]

const ReportTable = observer(function ReportTable() {
  const {reportStore} = useMainStore()

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage
  } = useTable({
    defaultOrderBy: 'none',
    defaultRowsPerPage: 50
  })

  const dataFiltered = reportStore.filteredByProject
    ? applyFilter({
        inputData: reportStore.filteredByProject,
        comparator: getComparator(order, orderBy)
      })
    : []

  useEffect(() => {
    setPage(0)
  }, [reportStore.searchText, reportStore.selectedProjects, setPage])

  return (
    <FlexStack sx={{position: 'relative'}}>
      <FlexStack sx={{position: 'relative'}}>
        <ScrollTableContainer>
          {reportStore.filteredByProject && (
            <Scrollbar>
              <Table
                stickyHeader
                size={dense ? 'small' : 'medium'}
                sx={{minWidth: 800}}
              >
                <TableHeadCustom
                  addSelectCell
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={reportStore.filteredByProject?.length ?? 0}
                  numSelected={reportStore.selectedReports?.length ?? 0}
                  onSort={onSort}
                  onSelectAllRows={checked =>
                    reportStore.toggleAllSelection(checked)
                  }
                />
                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <ReportRow key={row.id} item={row} />
                    ))}
                </TableBody>
              </Table>
            </Scrollbar>
          )}
        </ScrollTableContainer>
      </FlexStack>
      <TablePaginationCustom
        count={dataFiltered.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        dense={dense}
        onChangeDense={onChangeDense}
        sx={{
          '& .MuiTablePagination-root': {borderTop: 'none'},
          '& .MuiFormControlLabel-root': {px: 0}
        }}
      />
    </FlexStack>
  )
})

export default ReportTable
