import {useEffect, useRef, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {TableRow, TableCell, Typography, Tooltip, Button} from '@mui/material'
import {MCIssue} from 'src/entities/Item'
import {getFormattedDate} from 'src/utils/formatTime'
import Label from 'src/components/minimal/label/Label'
import Iconify from 'src/components/minimal/iconify'
import MCResponseDialog from 'src/components/project/deliverable/MCResponseDialog'
import TruncatedMarkdown from 'src/components/global/TruncatedMarkdown'
import {ReactComponent as ResponseIcon} from 'src/images/response.svg'

interface MCRowProps {
  mc: MCIssue
}

function MCRow({mc}: MCRowProps) {
  const [reply, setReply] = useState(false)
  const [maxLength, setMaxLength] = useState<number | null>(null)

  const cellRef = useRef<HTMLTableCellElement>(null)

  useEffect(() => {
    const calculateMaxLength = () => {
      if (cellRef.current) {
        const charsPerLine = cellRef.current.clientWidth / 3.2
        const maxCharsTwoLines = charsPerLine * 2
        setMaxLength(Math.max(maxCharsTwoLines, 180))
      }
    }

    calculateMaxLength()

    window.addEventListener('resize', calculateMaxLength)
    return () => window.removeEventListener('resize', calculateMaxLength)
  }, [])

  return (
    <>
      {mc.item && (
        <TableRow>
          <TableCell>{mc.lpid}</TableCell>
          <TableCell>{mc.propertyName}</TableCell>
          <TableCell sx={{py: 3}}>
            <Typography variant="body2">{mc.tenant}</Typography>
          </TableCell>
          <TableCell>{getFormattedDate(mc.createdAt)}</TableCell>
          <TableCell ref={cellRef} sx={{width: '35%'}}>
            {maxLength && mc.issue && (
              <Tooltip title={mc.mcIssueText} arrow>
                <div>
                  <TruncatedMarkdown source={mc.issue} maxLength={maxLength} />
                </div>
              </Tooltip>
            )}
          </TableCell>
          <TableCell>
            {mc.status != null && (
              <Label variant="soft" color={mc.colorByStatus}>
                {mc.labelByStatus}
              </Label>
            )}
          </TableCell>
          <TableCell>
            <Button
              size="small"
              startIcon={
                <Iconify
                  color={theme => theme.palette.primary.main}
                  icon="eva:email-fill"
                  width={18}
                  height={18}
                />
              }
              variant="outlined"
              onClick={() => setReply(true)}
            >
              Response
            </Button>
          </TableCell>
          <TableCell>
            {mc.clientResponsesCount > 0 && <ResponseIcon />}
          </TableCell>
        </TableRow>
      )}

      {reply && <MCResponseDialog mc={mc} onClose={() => setReply(false)} />}
    </>
  )
}

export default observer(MCRow)
