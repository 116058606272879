import {Box, Stack, Typography} from '@mui/material'
import Warning from '../../images/gif/warning.gif'

export default function NotSupportedScreen() {
  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Box width="80px" height="70px" mb={4}>
        <img src={Warning} alt="warning-gif" />
      </Box>
      <Typography variant="h3">Unsupported device or browser</Typography>
      <Stack color={theme => theme.palette.grey[600]} alignItems={'center'}>
        <Typography variant="body1" align="center" maxWidth={600}>
          The Portal is not supported by mobile devices, and is intended to be
          launched by standard browsers (Chrome, EDGE)
        </Typography>
        <Typography mt={2} variant="body1" align="center">
          For best user experience, please make sure:
        </Typography>
        <Typography variant="body1" align="center">
          1. To view the Portal in full screen mode, and
        </Typography>
        <Typography variant="body1" align="center">
          2. Screen resolution should be set between: 720*1280 and 2160*3840 (Go
          to Settings)
        </Typography>
      </Stack>
    </Stack>
  )
}
