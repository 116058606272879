import {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {Table, TableBody} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import Scrollbar from '../minimal/scrollbar'
import {
  TableHeadCustom,
  TablePaginationCustom,
  getComparator,
  useTable
} from 'src/components/minimal/table'
import {ScrollTableContainer, FlexStack} from '../global/FlexStack'
import BillingRow from 'src/components/billing/BillingRow'
import applyFilter from 'src/utils/applyFilter'
import Billing from 'src/entities/Billing'

const TABLE_HEAD = [
  {id: 'invoiceNumber', label: 'Invoice Number', align: 'left', minWidth: 200},
  {id: 'projectName', label: 'Project Name', align: 'left', minWidth: 150},
  {id: 'propertyName', label: 'Property Name', align: 'left', minWidth: 160},
  {id: 'invoiceDate', label: 'Invoice Date', align: 'left', minWidth: 150},
  {id: 'status', label: 'Status', align: 'left', minWidth: 100},
  {id: 'amount', label: 'Amount', align: 'left', minWidth: 120},
  {id: 'paidAmount', label: 'Amount Paid', align: 'left', minWidth: 150},
  {
    id: 'openBalanceAmount',
    label: 'Open Balance',
    align: 'left',
    minWidth: 150
  },
  {id: 'paidDate', label: 'Date Paid', align: 'left', minWidth: 130},
  {id: '', label: '', align: 'left'}
]

export default observer(function BillingTable() {
  const {billingStore} = useMainStore()

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage
  } = useTable({
    defaultDense: true,
    defaultOrderBy: 'none',
    defaultRowsPerPage: 50
  })

  const onToggleAll = (items: Billing[], isChecked: boolean) => {
    items.forEach(item => {
      if (item.attachments.length) {
        item.setIsSelected(isChecked)
      }
    })
  }

  const dataFiltered = billingStore.filteredByProject
    ? applyFilter({
        inputData: billingStore.filteredByProject,
        comparator: getComparator(order, orderBy)
      })
    : []

  const disabledSelectCell = dataFiltered.every(i => i.attachments.length === 0)
  const selectableData = dataFiltered.filter(r => r.attachments.length > 0)

  useEffect(() => {
    setPage(0)
  }, [billingStore.searchText, billingStore.selectedProjects, setPage])

  return (
    <FlexStack sx={{position: 'relative'}}>
      <FlexStack sx={{position: 'relative'}}>
        <ScrollTableContainer>
          <Scrollbar>
            <Table stickyHeader size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                addSelectCell
                disabledSelectCell={disabledSelectCell}
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={selectableData?.length ?? 0}
                onSort={onSort}
                numSelected={
                  dataFiltered.filter((i: Billing) => i.isSelected === true)
                    ?.length ?? 0
                }
                onSelectAllRows={checked => onToggleAll(dataFiltered, checked)}
              />
              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <BillingRow key={row.id} item={row} />
                  ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </ScrollTableContainer>
      </FlexStack>
      <TablePaginationCustom
        count={dataFiltered.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        dense={dense}
        onChangeDense={onChangeDense}
        sx={{
          '& .MuiTablePagination-root': {borderTop: 'none'},
          '& .MuiFormControlLabel-root': {px: 0}
        }}
      />
    </FlexStack>
  )
})
