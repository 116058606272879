import FilesProject from './FilesProject'
import {FlexCard, FlexCardContent} from 'src/components/global/FlexStack'
import {observer} from 'mobx-react-lite'
import FolderBreadcrumbs from './FolderBreadcrumbs'
import {useMainStore} from 'src/contexts/Main'
import UploadHeader from 'src/components/project/upload/UploadHeader'
import {Box, Stack} from '@mui/material'

export default observer(function UploadCard() {
  const {projectStore} = useMainStore()

  return (
    <FlexCard>
      <FlexCardContent
        sx={{
          ':last-child': {
            pb: 1
          }
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          display={'flex'}
        >
          <Stack>
            <FolderBreadcrumbs
              path={projectStore.currentdocFolder}
              onChoose={p => projectStore.setSelectedDocFolder(p)}
            ></FolderBreadcrumbs>
          </Stack>
          <Box>
            <UploadHeader />
          </Box>
        </Stack>
        <FilesProject />
      </FlexCardContent>
    </FlexCard>
  )
})
