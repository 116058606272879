import {
  Box,
  Card,
  CardContent,
  List,
  Stack,
  styled,
  TableContainer
} from '@mui/material'

export const FlexStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1
}))

export const FlexCard = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1
}))

export const FlexCardContent = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1
}))

export const ParentScrollStack = styled(FlexStack)(() => ({
  position: 'relative'
}))

export const ScrollTableContainer = styled(TableContainer)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  overflow: 'auto',
  flexGrow: 1
}))

export const ScrollBox = styled(Box)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  overflow: 'auto'
}))

export const ScrollList = styled(List)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  overflow: 'auto'
}))
