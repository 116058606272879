import {observer} from 'mobx-react-lite'
import {Card, CardContent, Stack} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import {useCallback, useEffect, useState} from 'react'
import {MyFile} from 'src/entities/file'
import {useTable} from 'src/components/minimal/table'
import FileListView from '../upload/filesView/FileListView'
import EmptyIcon from 'src/images/illustration_empty_mail.svg'
import EmptyContent from 'src/components/minimal/empty-content/EmptyContent'

export default observer(function ContractsTab() {
  const [loading, setLoading] = useState(true)

  const {
    projectStore: {
      selectedProject,
      contractFiles,
      getContractFiles,
      downloadContractFile
    }
  } = useMainStore()

  const loadFiles = useCallback(async () => {
    if (selectedProject?.id) {
      setLoading(true)
      await getContractFiles()
      setLoading(false)
    }
  }, [selectedProject?.id, getContractFiles])

  useEffect(() => {
    loadFiles()
  }, [loadFiles])

  const table = useTable({
    defaultOrderBy: 'name',
    defaultRowsPerPage: 50
  })

  const data = [...(contractFiles ?? ([] as MyFile[]))]

  return (
    <Card sx={{width: '100%', height: '100%'}}>
      <CardContent sx={{height: '100%'}}>
        <Stack spacing={1} height="100%">
          {!contractFiles?.length ? (
            <EmptyContent
              title={(loading ? 'Loading ' : 'No') + ' Files'}
              img={EmptyIcon}
            />
          ) : (
            <FileListView
              table={table}
              tableData={data}
              dataFiltered={data}
              onClickDownload={row =>
                downloadContractFile(row.path + row.name, row.name)
              }
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  )
})
