import {useCallback, useEffect, useState} from 'react'
import {Stack} from '@mui/system'
import {Upload} from '../../minimal/upload'
import {
  getCurrentRoot,
  getFiles,
  MyFile,
  MyFolder,
  ROOT_NAME,
  updateRoot
} from 'src/entities/file'
import FolderBreadcrumbs from './FolderBreadcrumbs'
import UploadProgress from './UploadProgress'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import config from 'src/config'

interface UploadAreaProps {
  curretFolder: string
  uploadMC?: boolean
  onMCUpload?: (attachments?: string[]) => void
  disableSave?: boolean
  isLoading?: boolean
}

const initfolder: MyFolder = {
  path: '',
  name: ROOT_NAME,
  children: []
}

export default observer(function UploadArea({
  curretFolder,
  uploadMC = false,
  onMCUpload,
  disableSave,
  isLoading
}: UploadAreaProps) {
  const {upload} = useMainStore().projectStore

  const [isSaving, setIsSaving] = useState(false)
  const [root, setRoot] = useState<MyFolder>({...initfolder})
  const [currentPath, setCurrentPath] = useState<string>(ROOT_NAME + '/')
  const [openUploadMessage, setOpenUploadMessage] = useState<boolean>(false)
  const [progress, setProgress] = useState<{
    filesCount: number
    filesSent: number
  }>({filesCount: 0, filesSent: 0})

  const onDropMultiFile = useCallback(
    (acceptedFiles: File[]) => {
      setRoot(p => ({...updateRoot(p, currentPath, acceptedFiles, [], 'add')}))
    },
    [currentPath]
  )

  const onRemoveFile = (inputFile: MyFile | MyFolder) => {
    setRoot(p => ({...updateRoot(p, currentPath, [], [inputFile], 'remove')}))
  }

  const onRemoveAllFiles = () => {
    setRoot({...initfolder, children: []})
    setCurrentPath(ROOT_NAME + '/')
  }

  const onSave = async () => {
    setIsSaving(true)
    if (onMCUpload) {
      let attachments: string[] = []
      if (root.children.length) {
        const fileData = getFiles(root, curretFolder).files
        const results = await Promise.all(
          fileData.map(async f => {
            const r = await upload(f, uploadMC)
            return r
          })
        )

        attachments = results.filter(x => x !== undefined) as string[]
      }
      onMCUpload(attachments)
    }
  }

  const onUpload = () => {
    setOpenUploadMessage(true)
    const fileData = getFiles(root, curretFolder).files
    setProgress({filesSent: 0, filesCount: fileData.length})
    fileData.map(async f => {
      const r = await upload(f, uploadMC)
      if (r) {
        setProgress(p => ({...p, filesSent: p.filesSent + 1}))
      }
    })
  }

  useEffect(() => {
    if (progress.filesSent && progress.filesSent === progress.filesCount) {
      setTimeout(() => {
        setProgress(p => ({...p, filesSent: p.filesSent + 1}))
      }, 2000)
    }
  }, [progress, uploadMC])

  useEffect(() => {
    setRoot({...initfolder, children: []})
    setCurrentPath(ROOT_NAME + '/')
    setOpenUploadMessage(false)
    setProgress({filesCount: 0, filesSent: 0})
  }, [])

  const files = getCurrentRoot(currentPath, root).children

  return (
    <Stack>
      {!openUploadMessage ? (
        <>
          <FolderBreadcrumbs
            path={currentPath}
            onChoose={p => setCurrentPath(p)}
          ></FolderBreadcrumbs>
          <Upload
            accept={config.acceptFile}
            multiple
            thumbnail={false}
            files={files}
            onDrop={onDropMultiFile}
            onRemove={onRemoveFile}
            onRemoveAll={onRemoveAllFiles}
            onUpload={uploadMC ? undefined : onUpload}
            onSave={uploadMC ? onSave : undefined}
            onClickFolder={folder => {
              setCurrentPath(folder.path + folder.name + '/')
            }}
            disableSave={disableSave || isSaving}
            isLoading={isLoading || isSaving}
          />
        </>
      ) : (
        <Stack>
          <UploadProgress
            filesCount={progress.filesCount}
            filesSent={progress.filesSent}
          ></UploadProgress>
        </Stack>
      )}
    </Stack>
  )
})
