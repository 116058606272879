import React from 'react'
import {Typography} from '@mui/material'

export default function DocumentPage() {
  return (
    <>
      <Typography variant="h3">Welcome to your Document page</Typography>
    </>
  )
}
