import {observer} from 'mobx-react-lite'
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material'
import Label from 'src/components/minimal/label/Label'
import {useMainStore} from 'src/contexts/Main'
import {ProjectStatus} from 'src/entities/project/Project'
import {getFormattedDate} from 'src/utils/formatTime'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {ProjectTabEnum} from './ProjectTabs'

const ProjectHeader = observer(function ProjectHeader() {
  const {projectStore, projectsStore} = useMainStore()
  const navigate = useNavigate()

  const onChange = (event: SelectChangeEvent<number | null>) => {
    const value = Number(event.target.value)
    navigate(`/project/${value}/${ProjectTabEnum[ProjectTabEnum.Items]}`)
  }

  useEffect(() => {
    projectsStore.getProjects()
  }, [projectsStore])

  return (
    <Card>
      <CardContent>
        <Stack
          px={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            minWidth="50%"
          >
            <Stack>
              <Typography variant="body2">Project Name</Typography>
              <Typography variant="h6">
                {projectStore.selectedProject?.toString()}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="body2">Due Date</Typography>
              <Typography variant="body1">
                {projectStore.selectedProject?.dueDate &&
                  getFormattedDate(projectStore.selectedProject?.dueDate)}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="body2">Project Status</Typography>
              <Label variant="soft" color="primary">
                {projectStore.selectedProject &&
                  ProjectStatus[projectStore.selectedProject.status]}
              </Label>
            </Stack>
          </Stack>
          <Stack direction="row" gap={4}>
            {!projectStore.instructions.length && (
              <Button
                variant="contained"
                color={'primary'}
                onClick={() => projectStore.setNewInstruction(true)}
              >
                New Instruction
              </Button>
            )}
            <FormControl sx={{minWidth: 150}}>
              <InputLabel size="small">
                <Typography variant="button" color="primary">
                  Select Projects
                </Typography>
              </InputLabel>
              <Select<number | null>
                MenuProps={{
                  style: {
                    maxHeight: 400
                  }
                }}
                size="small"
                color="primary"
                input={<OutlinedInput label="Select Projects" />}
                value={projectStore.selectedProject?.id || ''}
                sx={{
                  '.MuiSelect-icon': {
                    color: theme => theme.palette.primary.main
                  }
                }}
                onChange={onChange}
              >
                {projectsStore.projects?.map(p => (
                  <MenuItem key={p.id} id={'p' + p.id} value={p.id}>
                    <ListItemText primary={p.toString()} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
})

export default ProjectHeader
