import {useCallback, useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {Tab, Tabs} from '@mui/material'
import {useMainStore} from 'src/contexts/Main'
import DeliverableTab from 'src/components/project/deliverable/DeliverableTab'
import ContractsTab from 'src/components/project/contracts/ContractsTab'
import UploadTab from 'src/components/project/upload/UploadTab'
import {FlexStack} from '../global/FlexStack'
import InstructionTab from 'src/components/project/instruction/InstructionTab'
import {useNavigate, useParams} from 'react-router-dom'

export enum ProjectTabEnum {
  Contracts = 0,
  Instructions,
  Uploads,
  Items,
  'engagement-docs' = Contracts,
  'instructions' = Instructions,
  'uploads' = Uploads,
  'status' = Items
}

export default observer(function ProjectTabs() {
  const {
    newInstruction,
    // getInstructions,
    selectedProject,
    instructions
  } = useMainStore().projectStore
  const {selectedTab} = useParams()
  const navigate = useNavigate()

  // const loadData = useCallback(async () => {
  //   await getInstructions()
  // }, [getInstructions])

  const changeNavigation = useCallback(
    (tabValue: ProjectTabEnum) => {
      navigate(`/project/${selectedProject?.id}/${ProjectTabEnum[tabValue]}`)
    },
    [navigate, selectedProject?.id]
  )

  useEffect(() => {
    if (newInstruction) changeNavigation(ProjectTabEnum.Instructions)
  }, [newInstruction, changeNavigation])

  const tab =
    (selectedTab &&
      (ProjectTabEnum[
        selectedTab as unknown as ProjectTabEnum
      ] as unknown as ProjectTabEnum)) ||
    ProjectTabEnum.Contracts

  const addInstruction = instructions?.length || newInstruction

  return (
    <FlexStack>
      <Tabs
        value={tab}
        onChange={(e, newValue) => changeNavigation(newValue)}
        sx={{pl: 4}}
      >
        {/* <Tab label="Engagement Docs" value={ProjectTabEnum.Contracts} /> */}
        {addInstruction && (
          <Tab label="Instructions" value={ProjectTabEnum.Instructions} />
        )}
        <Tab label="Uploads" value={ProjectTabEnum.Uploads} />
        <Tab label="Status" value={ProjectTabEnum.Items} />
      </Tabs>
      {tab === ProjectTabEnum.Items && <DeliverableTab />}
      {tab === ProjectTabEnum.Uploads && <UploadTab />}
      {tab === ProjectTabEnum.Contracts && <ContractsTab />}
      {tab === ProjectTabEnum.Instructions && addInstruction && (
        <InstructionTab />
      )}
    </FlexStack>
  )
})
