import {useState} from 'react'
import {CardActions, Stack, TextField, Typography} from '@mui/material'
import Editor from '../../minimal/editor'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/contexts/Main'
import {LoadingButton} from '@mui/lab'
import WarninigModal from 'src/components/global/WarningModal'

interface UploadLinkProps {
  onSubmit: VoidFunction
}

export default observer(function UploadLink({onSubmit}: UploadLinkProps) {
  const {projectStore} = useMainStore()
  const [siteLinkComment, setSiteLinkComment] = useState(
    projectStore.selectedProject?.siteLinkComment || ''
  )
  const [siteLink, setSiteLink] = useState(
    projectStore.selectedProject?.siteLink || ''
  )
  const [loading, setLoading] = useState(false)
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false)
  const disableLink = !!projectStore.selectedProject?.siteLink
  const disableLinkComment = !!projectStore.selectedProject?.siteLinkComment

  const onUpdateLink = async () => {
    setLoading(true)
    await projectStore.updateLink(
      disableLink ? null : siteLink,
      disableLinkComment ? null : siteLinkComment
    )
    onSubmit()
  }

  return (
    <>
      <Stack spacing={3}>
        <Typography variant="h6">
          Enter Dropbox link, War Room Link, or other source link.
        </Typography>
        <TextField
          placeholder="www.sitelink.com "
          value={siteLink}
          onChange={e => setSiteLink(e.target.value)}
          disabled={disableLink}
        />
        <Editor
          placeholder="Enter downloading notes here"
          simple
          id="simple-editor"
          value={siteLinkComment}
          onChange={value => setSiteLinkComment(value)}
          readOnly={disableLinkComment}
        />
        <CardActions sx={{justifyContent: 'end'}}>
          <LoadingButton
            variant="contained"
            disabled={!siteLink || (disableLink && disableLinkComment)}
            onClick={() => setOpenWarningDialog(true)}
            loading={loading}
          >
            Submit
          </LoadingButton>
        </CardActions>
      </Stack>
      <WarninigModal
        open={openWarningDialog}
        title="Are you sure? "
        text="After clicking submit, the link or notes are locked for editing"
        okText="Save"
        onClose={(result: boolean) => {
          setOpenWarningDialog(false)
          result && onUpdateLink()
        }}
      ></WarninigModal>
    </>
  )
})
