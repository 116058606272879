import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {
  PublicClientApplication,
  InteractionRequiredAuthError
} from '@azure/msal-browser'
import config, {aadConfig, msalConfig} from 'src/config'
import User from 'src/entities/User'

export enum CustomErrors {
  Typeerror
}

export default class LoginStore {
  readonly msalInstance: PublicClientApplication

  isMsalAuth = false

  isAuth = false

  isLoggingIn = false

  loginError = ''

  loginMessage = ''

  currentUser?: User = undefined

  errorStatus?: number = undefined

  constructor() {
    makeAutoObservable(this)
    this.msalInstance = new PublicClientApplication(msalConfig)

    reaction(
      () => this.isMsalAuth,
      async isMsalAuth => {
        if (isMsalAuth && !!(await this.getAccessToken())) {
          this.getCurrentUser()
          runInAction(() => {
            this.isAuth = true
            this.isLoggingIn = false
          })
        }
      }
    )
  }

  init() {
    const account = this.msalInstance.getAllAccounts()[0]
    runInAction(() => {
      this.loginMessage = ''
      this.isMsalAuth = this.msalInstance && !!account
    })
  }

  setErrorStatus = (value?: number) => {
    this.errorStatus = value
  }

  setLoginMessage(value: string) {
    this.loginMessage = value
  }

  requestState = JSON.stringify({
    redirectUrl:
      typeof window !== 'undefined' ? `${window.location.origin}/msal.html` : ''
  })

  isInteractionRequired = (error: Error): boolean => {
    if (!error.message || error.message.length <= 0) return false

    return (
      error.message.indexOf('consent_required') > -1 ||
      error.message.indexOf('interaction_required') > -1 ||
      error.message.indexOf('login_required') > -1
    )
  }

  async login() {
    runInAction(() => {
      this.isLoggingIn = true
      this.loginError = ''
      this.loginMessage = ''
    })

    try {
      // Login via popup
      await this.msalInstance.loginPopup({
        scopes: [msalConfig.auth.clientId],
        prompt: 'login',
        state: this.requestState
      })
      runInAction(() => {
        this.isAuth = true
        this.isLoggingIn = false
        this.isMsalAuth = true
        this.loginMessage = ''
      })
      // eslint-disable-next-line
    } catch (err: any) {
      runInAction(() => {
        this.isLoggingIn = false
        this.isAuth = false
        this.isMsalAuth = false
        this.loginError = err.errorMessage
      })
    }
  }

  logout = () => {
    this.msalInstance.logoutPopup()

    runInAction(() => {
      this.isAuth = false
      this.isMsalAuth = false
      this.currentUser = undefined
      this.loginMessage = ''
    })
  }

  async getAccessToken(scopes: string[] = [msalConfig.auth.clientId]) {
    const account = this.msalInstance.getAllAccounts()[0]
    const tokenrequest = {
      scopes: scopes,
      state: this.requestState,
      account: account ?? undefined
    }

    try {
      const silentResult = await this.msalInstance.acquireTokenSilent(
        tokenrequest
      )
      return silentResult.accessToken
      // eslint-disable-next-line
    } catch (error: any) {
      if (
        error instanceof InteractionRequiredAuthError ||
        this.isInteractionRequired(error)
      ) {
        this.msalInstance
          .acquireTokenPopup(tokenrequest)
          .then(accessTokenResponse => accessTokenResponse.accessToken)
          .catch(() => {
            this.tokenError(error)
          })
      } else {
        this.tokenError(error)
      }
      return ''
    }
  }

  tokenError = (err: Error) => {
    runInAction(() => {
      this.isAuth = false
      this.isMsalAuth = false
      this.isLoggingIn = false
    })
  }

  getCurrentUser = () => {
    this.fetchWithUser(`${config.apiUrl}/user/current`, false, [])
      .then(response => response !== null && response.json())
      .then((user: User) => {
        runInAction(() => {
          this.currentUser = user
          this.isAuth = true
          this.isLoggingIn = false
          this.isMsalAuth = true
          this.loginMessage = ''
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isAuth = false
          this.isMsalAuth = false
          this.isLoggingIn = false
          this.loginMessage =
            'User does not exist in the system, please contact your administrator'
        })
      })
  }

  fetchWithUser = async (
    endpoint: RequestInfo,
    jsonRes = true,
    skipErrors: number[] | undefined = undefined,
    options: RequestInit = {},
    scopes: string[] = [aadConfig.readScope]
    // eslint-disable-next-line
  ): Promise<any> => {
    this.setErrorStatus(undefined)
    const accessToken = await this.getAccessToken(scopes)
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`
    }
    const mergedOpts = {...options, headers}

    return fetch(endpoint, mergedOpts)
      .then(response => {
        if (!response.ok) {
          if (
            !skipErrors ||
            (skipErrors.length && !skipErrors.includes(response.status))
          )
            this.setErrorStatus(response.status)

          return Promise.reject(response)
        }
        if (jsonRes && response.status === 200) return response.json()
        return response
      })
      .catch(err => {
        const msg = err.toString()
        if (msg.toLowerCase() === 'typeerror: failed to fetch')
          this.setErrorStatus(CustomErrors.Typeerror)

        if (skipErrors) throw err
      })
  }
}
