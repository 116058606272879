import {observer} from 'mobx-react-lite'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import {TableHeadCustom} from 'src/components/minimal/table'
import Paper from '@mui/material/Paper'
import {Rent, mergeRowsByNote} from '../../../entities/AbstractReport'

interface AbstractRentProps {
  rent: Rent
}

const TABLE_HEAD = [
  {id: 'increaseDate', label: 'Increase Date', align: 'left'},
  {id: 'annual', label: 'Annual', align: 'left'},
  {id: 'monthly', label: 'Monthly', align: 'left'},
  {id: 'Annual PSF', label: 'Annual PSF', align: 'left'}
]

export default observer(function AbstractRent({rent}: AbstractRentProps) {
  const mergedData = mergeRowsByNote(rent.details)

  return (
    <TableContainer component={Paper} sx={{boxShadow: 3}}>
      <Table size="small">
        <TableHeadCustom headLabel={TABLE_HEAD} />
        <TableBody>
          {rent.details.map((d, i) => (
            <TableRow>
              <TableCell width={'30%'}>{d.increaseDate}</TableCell>
              {d.note ? (
                <TableCell colSpan={4} rowSpan={mergedData[i]?.rowSpan}>
                  {!mergedData[i].spanNote && d.note}
                </TableCell>
              ) : (
                <>
                  <TableCell>{d.annual}</TableCell>
                  <TableCell>{d.monthly}</TableCell>
                  <TableCell> {d.psf}</TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
})
