import Billing from 'src/entities/Billing'
import downloadBlob from 'src/utils/downloadFile'
import config from '../config'
import {makeAutoObservable, runInAction} from 'mobx'
import LoginStore from 'src/store/LoginStore'

export default class BillingStore {
  billings?: Billing[]
  selectedProjects: number[] = []
  searchText = ''

  setSelectedProjects(selected: number[]) {
    this.selectedProjects = selected
  }

  constructor(readonly loginStore: LoginStore) {
    makeAutoObservable(this)
  }

  setSearchText = (value: string) => {
    this.searchText = value
  }

  get TotalBalance() {
    return this.billings?.reduce(
      (partialSum, a) => partialSum + (a.openBalanceAmount ?? 0),
      0
    )
  }

  get filteredByProject(): Billing[] | undefined {
    const search = this.searchText?.toLocaleLowerCase()
    return this.selectedProjects.length || this.searchText
      ? this.billings?.filter(
          i =>
            (!this.selectedProjects.length ||
              this.selectedProjects.includes(i.project?.id || -1)) &&
            (!this.searchText ||
              i.invoiceNumber?.toLocaleLowerCase()?.includes(search) ||
              i.projectName?.toLocaleLowerCase()?.includes(search))
        )
      : this.billings
  }

  get selectedItems() {
    return this.filteredByProject?.filter(i => i.isSelected === true) ?? []
  }

  async getBillings() {
    return this.loginStore
      .fetchWithUser(`${config.apiUrl}/invoices`)
      .then((data?: Billing[]) => {
        runInAction(() => {
          this.billings = data
            ?.map(i => new Billing(i))
            .sort(
              (a, b) =>
                (b.project?.createdAt?.getTime() ?? 0) -
                (a.project?.createdAt?.getTime() ?? 0)
            )
        })
      })
  }

  async downloadAttachments(billing: Billing) {
    billing.setIsDownloading(true)
    for (let i = 0; i < billing.attachments.length; i++) {
      const attachment = billing.attachments[i]
      const path = attachment.file.path.trim()
      try {
        const response = await this.loginStore.fetchWithUser(
          `${
            config.apiUrl
          }/invoices/downloadInvoiceFile?path=${encodeURIComponent(path)}`,
          false
        )
        const blob = await response.blob()
        downloadBlob(path, blob)
      } finally {
        billing.setIsDownloading(false)
      }
    }
  }
}
