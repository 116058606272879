import {useEffect, useState} from 'react'
import {
  FormControl,
  Box,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  Chip,
  ListItemText,
  OutlinedInput,
  menuItemClasses,
  selectClasses
} from '@mui/material'
import {SelectChangeEvent} from '@mui/material/Select'
import {useMainStore} from 'src/contexts/Main'
import {observer} from 'mobx-react-lite'

interface SelectProjectsProps {
  selected?: number[]
  onSelect?: (selected: number[]) => void
  isSmall?: boolean
}

export default observer(function SelectProjects({
  selected,
  onSelect,
  isSmall = false
}: SelectProjectsProps) {
  const {projectsStore} = useMainStore()

  useEffect(() => {
    projectsStore.getProjects()
  }, [projectsStore])

  const [projects, setProjects] = useState<number[]>(selected ?? [])

  const onChange = (event: SelectChangeEvent<number[]>) => {
    const value = event.target.value
    let selected = [] as number[]

    if (value[value.length - 1] === 'all') {
      selected =
        projects.length === projectsStore.projects.length
          ? []
          : projectsStore.projects.map(x => x.id ?? 0)
    } else selected = value as number[]

    setProjects(selected)
    onSelect?.(selected)
  }

  const isAllSelected =
    projects.length > 0 && projects.length === projectsStore.projects.length

  return (
    <FormControl
      size={isSmall ? 'small' : undefined}
      sx={{minWidth: 200, maxWidth: '50%'}}
    >
      <InputLabel>Select Projects</InputLabel>
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          style: {
            maxHeight: 400
          }
        }}
        sx={
          projects.length
            ? {
                [`&.${selectClasses.select}`]: isSmall
                  ? {pt: 1.1, pb: 1}
                  : {py: 1.4}
              }
            : undefined
        }
        autoWidth={true}
        multiple
        value={projects}
        onChange={onChange}
        input={<OutlinedInput label="Select Projects" />}
        renderValue={selected => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              m: 0,
              p: 0,
              gap: 0.5,
              maxHeight: 100,
              overflow: 'auto',
              '::-webkit-scrollbar': {display: 'none'}
            }}
          >
            {selected.map(value => (
              <Chip
                size={isSmall ? 'small' : undefined}
                key={value}
                label={projectsStore.projects
                  ?.find(x => x.id === value)
                  ?.toString()}
              />
            ))}
          </Box>
        )}
      >
        <MenuItem value="all">
          <Checkbox
            checked={isAllSelected}
            indeterminate={
              projects.length > 0 &&
              projects.length < projectsStore.projects.length
            }
          />
          <ListItemText primary="Select All" />
        </MenuItem>
        {projectsStore.projects?.map(p => (
          <MenuItem
            key={p.id}
            value={p.id}
            sx={{
              [`&.${menuItemClasses.selected}`]: {
                backgroundColor: 'transparent'
              }
            }}
          >
            <Checkbox checked={!!p.id && projects.indexOf(p.id) > -1} />
            <ListItemText primary={p.toString()} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})
