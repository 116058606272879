import {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  IconButton,
  Divider,
  CircularProgress,
  Box
} from '@mui/material'
import Editor from 'src/components/minimal/editor'
import Iconify from 'src/components/minimal/iconify'
import Markdown from 'src/components/minimal/markdown/Markdown'
import {MCIssue, MCItemStatus} from 'src/entities/Item'
import {useMainStore} from 'src/contexts/Main'
import {getFormattedDate} from 'src/utils/formatTime'
import UploadArea from 'src/components/project/upload/UploadArea'
import MCResponses from 'src/components/project/deliverable/MCResponses'

function isQuillEmpty(value: string) {
  return value.replace(/<(.|\n)*?>/g, '').trim().length === 0
}

interface MCResponseDialogProps {
  mc?: MCIssue
  isGeneral?: boolean
  onClose: VoidFunction
}

function MCResponseDialog({mc, isGeneral, onClose}: MCResponseDialogProps) {
  const {
    selectedProject,
    getClientResponses,
    getGeneralClientResponses,
    sendClientResponse,
    sendGeneralClientResponse
  } = useMainStore().projectStore
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)

  const onMCUpload = (attachments?: string[]) => {
    setIsSaving(true)
    const filtered = attachments?.filter(a => a.length > 0)
    const msg = isQuillEmpty(content) ? '' : content

    if (!filtered?.length && !msg.length) {
      setIsSaving(false)
      onClose()
      return
    }

    if (mc?.id) {
      sendClientResponse(mc.id, msg, filtered).finally(() => {
        setIsSaving(false)
        onClose()
      })
    } else if (isGeneral) {
      sendGeneralClientResponse(msg, filtered).finally(() => {
        setIsSaving(false)
        onClose()
      })
    }
  }

  useEffect(() => {
    if (mc?.id) getClientResponses(mc).finally(() => setLoading(false))
    else if (isGeneral)
      getGeneralClientResponses().finally(() => setLoading(false))
  }, [mc, isGeneral, getClientResponses, getGeneralClientResponses])

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      // sx={{height: '100%'}}
      open
      onClose={onClose}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          {mc?.item && (
            <Stack direction="row" gap={1} alignItems={'center'}>
              <Typography variant="h6">{`${mc.item.tenant} `}</Typography>
              <Typography
                variant="subtitle1"
                color={theme => theme.palette.grey[600]}
              >
                {`${mc.item.lpid}`}
              </Typography>
            </Stack>
          )}
          {isGeneral && <Typography variant="h6">General Response</Typography>}
          <IconButton size="small" onClick={onClose}>
            <Iconify icon="eva:close-fill" width={18} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Stack height="50vh" alignItems={'center'} justifyContent={'center'}>
            <CircularProgress size={60} />
          </Stack>
        ) : (
          <Stack spacing={2}>
            {mc?.status !== MCItemStatus.Resolved && (
              <>
                <Editor
                  simple
                  id="simple-editor"
                  value={content}
                  placeholder="Insert response here"
                  onChange={value =>
                    setContent(value.replaceAll('<li>', '\n<li>'))
                  }
                  sx={{minHeight: 100, height: '100%'}}
                />
                <UploadArea
                  uploadMC={true}
                  curretFolder={''}
                  disableSave={isSaving || isQuillEmpty(content)}
                  isLoading={isSaving}
                  onMCUpload={onMCUpload}
                ></UploadArea>
              </>
            )}
            {(!isGeneral ||
              (selectedProject?.generalResponses?.length ?? 0) > 0) && (
              <>
                <Box
                  bgcolor={theme => theme.palette.grey[400]}
                  height={'1px'}
                />
                <Typography variant="subtitle1">Issue Log</Typography>
              </>
            )}
            <MCResponses
              clientResponses={
                isGeneral
                  ? selectedProject?.generalResponses
                  : mc?.clientResponses
              }
            />
            {mc?.issue && (
              <Stack spacing={1}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack spacing={1} maxWidth={'85%'}>
                    <Markdown children={mc.issue} />
                  </Stack>
                  <Typography
                    variant="body2"
                    color={theme => theme.palette.grey[600]}
                  >
                    {getFormattedDate(mc.createdAt)}
                  </Typography>
                </Stack>
                <Divider />
              </Stack>
            )}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default observer(MCResponseDialog)
