import Item, {ItemStatus} from './Item'

export default class Report extends Item {
  constructor(report?: Report) {
    super(report)
    this.isSelected = report?.isSelected || false
  }

  toggleSelection() {
    this.isSelected = !this.isSelected
  }

  getColorByStatus() {
    return this.status === ItemStatus.InProgress
      ? 'success'
      : this.status === ItemStatus.MC
      ? 'error'
      : this.status === ItemStatus.Completed
      ? 'info'
      : 'default'
  }
}

export enum ReportType {
  PropertyReport,
  RentReport,
  PropertyRentRollReport
}
