export default function downloadBlob(fileName: string, blob: Blob): void {
  const link = window.document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  link.click()
  window.URL.revokeObjectURL(link.href)
}

export async function downloadFile(response: Response, constFileName?: string) {
  const data = await response.blob()
  let filename = ''
  if (!constFileName) {
    const disposition = response.headers.get('Content-Disposition') || ''
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)
    if (matches !== null && matches[1])
      filename = matches[1].replace(/['"]/g, '')
  } else filename = constFileName
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}
