import {forwardRef} from 'react'
import {useTheme} from '@mui/material/styles'
import {
  Badge,
  Avatar,
  BadgeProps,
  AvatarProps,
  AvatarGroupProps
} from '@mui/material'

// ----------------------------------------------------------------------

const getCharAtName = (name: string) => name && name.charAt(0).toUpperCase()

// const getColorByName = (name: string) => {
//   if (['A', 'N', 'H', 'L', 'Q'].includes(getCharAtName(name))) return 'primary'
//   if (['F', 'G', 'T', 'I', 'J'].includes(getCharAtName(name))) return 'info'
//   if (['K', 'D', 'Y', 'B', 'O'].includes(getCharAtName(name))) return 'success'
//   if (['P', 'E', 'R', 'S', 'U'].includes(getCharAtName(name))) return 'warning'
//   if (['V', 'W', 'X', 'M', 'Z'].includes(getCharAtName(name))) return 'error'
//   return 'default'
// }

export interface CustomAvatarProps extends AvatarProps {
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
  firstName?: string
  lastName?: string
  BadgeProps?: BadgeProps
}

export interface CustomAvatarGroupProps extends AvatarGroupProps {
  size?: 'tiny' | 'small' | 'medium' | 'large'
  compact?: boolean
}
// ----------------------------------------------------------------------

const CustomAvatar = forwardRef<HTMLDivElement, CustomAvatarProps>(
  (
    {color, firstName = '', lastName = '', BadgeProps, children, sx, ...other},
    ref
  ) => {
    const theme = useTheme()

    const charAtFistName = getCharAtName(firstName)
    const charAtLastName = getCharAtName(lastName)
    // const colorByName = getColorByName(name)
    // const colr = color || colorByName

    const renderContent = (
      // colr === 'default' ? (
      //   <Avatar ref={ref} sx={sx} {...other}>
      //     {name && charAtName}
      //     {children}
      //   </Avatar>
      // ) : (
      <Avatar
        ref={ref}
        sx={{
          color: theme.palette.primary.darker, //theme.palette[colr]?.contrastText,
          backgroundColor: theme.palette.grey[100], // theme.palette[colr]?.main,
          fontWeight: theme.typography.fontWeightMedium,
          ...sx
        }}
        {...other}
      >
        {firstName && charAtFistName}
        {lastName && charAtLastName}
        {children}
      </Avatar>
    )
    // )

    return BadgeProps ? (
      <Badge
        overlap="circular"
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        {...BadgeProps}
      >
        {renderContent}
      </Badge>
    ) : (
      renderContent
    )
  }
)

export default CustomAvatar
